:root {
    --color-tex-cont: black;
    --backgroundFondo: #FFF;
    --background: #ffffff;
    --color-title: rgb(0, 0, 0);
    --color-border-cel: #ffffff;
    --background-cel-relleno: #FFF;
    --background-btn: #20c6c7;
    --background-btn-hover: #104c4d;
    --color-text-btn: #ffffff;
    --background-question: #ffffff;
    --color-title-header: #19b394;
    --color-subtitle-header: #909090;
    --text-align-question: "left";
    --text-weight-question: "none";
    --text-decoration-question: "none";
    --text-align-title-header: 'left';
    --text-decoration-title-header: 'none';
    --text-weight-title-header: 'none';
    --text-align-subtitle-header: 'left';
    --text-decoration-subtitle-header: 'none';
    --text-weight-subtitle-header: 'none';


    --background-one: '#fff';
    --text-color-one-inv: '#000';
    --text-color-one-log: "#000";

    --text-align-one-inv: 'left';
    --text-decoration-one-inv: 'none';
    --text-weight-one-inv: 'none';
    --text-align-one-log: 'left';
    --text-decoration-one-log: 'none';
    --text-weight-one-log: 'none';

    --background-three: '#fff';
    --text-color-three-inv: '#000';
    --text-color-three-log: "#000";

    --text-align-three-inv: 'left';
    --text-decoration-three-inv: 'none';
    --text-weight-three-inv: 'none';
    --text-align-three-log: 'left';
    --text-decoration-three-log: 'none';
    --text-weight-three-log: 'none';
    --display-title:'';

    --background-navigation: '#e5e5e5';
    --background-navigation-current: '#20c6c6';
    --background-navigation-passed:  '#168b8b'; 
    --fontsize-navigation-passed: '14px';
    --fontcolor-navigation-passed: '#ffffff';
    --fontcolor-inactive-navigation-passed: "#000000";

    --pregunta-opcion-fondo: '#19b394';
    --pregunta-opcion-texto: '#ffffff';


	--color-circulo-animacion-fin: "#3bc2ac";
    --color-check-animacion-fin: "#ffffff";
    --color-texto-animacion-fin: "#000000";
    --color-transiciones: "#3bc2ac";

    --image-header-center:"center";
    --text-title-page:"#000000";
    --text-subtitle-page:"#000000";
}

.sd-title.sd-container-modern__title {
    background-color: var(--background-header) !important;
}

.sd-comment {
    height: calc(18 * var(--base-unit, 8px));
    min-width: calc(6 * var(--base-unit, 8px));
    min-height: calc(6 * var(--base-unit, 8px));
    max-width: 100%;

    appearance: none;
    position: static;
    width: 100%;
    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
       
    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.sd-text {
    appearance: none;
    position: static;
    width: 100%;
    height: calc(6 * var(--base-unit, 8px));
    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: calc(2 * var(--base-unit, 8px));
    color: var(--color-tex-cont) !important;

    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;

    background-color: var(--background-cel-relleno) !important;
}

.jodit-wysiwyg {
    color: var(--color-tex-cont);
}

.sv-string-editor {
    color: var(--color-title);
}


.sd-element__numEn {
    line-height: calc(2 * var(--base-unit, 8px));
    padding: calc(3 * var(--base-unit, 8px)) calc(1 * var(--base-unit, 8px)) calc(0.375 * var(--base-unit, 8px));
    float: left;
    text-align: right;
    box-sizing: border-box;
    white-space: nowrap;
    flex-shrink: 0;
    color: var(--color-title);
}

.sd-element__num {
    margin-left: 0 !important;
}

.svc-question__adorner {
    border: solid;
    border-color: var(--color-border-cel);
}

.radioColor {
    color: var(--color-tex-cont);
}

.inputBox {
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.textAreaTheme {
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.contentBoxTheme {
    color: var(--color-title);
}

.sd-question {
    background-color: var(--background-question) !important;
}

.sd-title.sd-container-modern__title > div.sd-header__text > h3 {
    color: var(--color-title-header) !important;
    text-align: var(--text-align-title-header) !important;
    text-decoration: var(--text-decoration-title-header) !important;
    font-weight: var(--text-weight-title-header) !important;
}

.sd-title.sd-container-modern__title > div.sd-header__text > h5 {
    color: var(--color-subtitle-header) !important;
    text-align: var(--text-align-subtitle-header) !important;
    text-decoration: var(--text-decoration-subtitle-header) !important;
    font-weight: var(--text-weight-subtitle-header) !important;
}

.contentBoxTheme1 {
    padding: 30px;
    background: #FFF;
    margin-bottom: 20px;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--background);
    border-width: 3px;
    border-style: solid;
    border-color: var(--color-border-cel);
}


.sv_enviar_btn {
    transition-duration: 0.2s;
    float: none !important;
    width: 15%;
    box-sizing: border-box;
    padding: 9px !important;
    font-weight: bold;
    line-height: 2em;
    border: none;
    min-width: 100px;
    cursor: pointer;
    border-radius: 2px;
    color: var(--color-text-btn);
    background-color: var(--background-btn);
}

.sv_enviar_btn:hover {
    background-color: var(--background-btn-hover);
}

.sd-row {
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cel) !important;
    background-color: var(--background) !important;
}

.sv_row {
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cel) !important;
    background-color: var(--background) !important;
}

.sv-text {
    appearance: none;
    position: static;
    width: 100%;
    height: calc(6 * var(--base-unit, 8px));
    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: calc(2 * var(--base-unit, 8px));
    color: var(--color-tex-cont) !important;

    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;

    background-color: var(--background-cel-relleno) !important;
}

.sv_main {
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
}

.sd_main {
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
}

.sd-body {
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
    background-color: var(--backgroundFondo) !important;
}

.sd-root-modern * {
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;

}

.sd-page {
    background-color: var(--backgroundFondo) !important;
}

.sd-page > div.sd-row {
    background-color: #ffffff !important;
}

.sv-page {
    background-color: var(--backgroundFondo) !important;
}

.sv_q_text_root {
    color: var(--color-tex-cont) !important;
    background-color: var(--background-cel-relleno) !important;
}

.sv_q_dropdown_control {
    color: var(--color-tex-cont) !important;
    background-color: var(--background-cel-relleno) !important;
}

.sv_select_wrapper {
    color: var(--color-tex-cont) !important;
}

.sv_q_num {
    color: var(--color-title) !important;
}

.sv_complete_btn {
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}

.sv_complete_btn:hover {
    background-color: var(--background-btn-hover) !important;
}

.sd-btn {
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}

.sd-btn:hover {
    background: var(--background-btn-hover) !important;color: var(--color-text-btn) !important;
}

.sv-btn {
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}

.sv-btn:hover {
    background: var(--background-btn-hover) !important;
    color: var(--color-text-btn) !important;
}

.sd-navigation__complete-btn {
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}

.sd-navigation__complete-btn:hover {
    background-color: var(--background-btn-hover) !important;
}

.sv_main .sv_container .sv_header h3 {
    font-size: 1.5em !important;
}

.contentBoxTheme .svc-question__adorner {
    border: 0;
}

.contentBoxTheme .sv_enviar_btn {
    color: #FFF;
    border-radius: 5px;
    background: #00a3fe;
    background: -moz-linear-gradient(top, #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top, #00a3fe 0%, #0084f9 100%);
    background: linear-gradient(to bottom, #00a3fe 0%, #0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a3fe', endColorstr='#0084f9', GradientType=0);
}

.contentEncabezado {
    background-color: var(--backgroundFondo) !important;
    color: var(--color-title);
}

.svc-creator .svc-tabbed-menu-item {
    height: auto !important;
}

.spg-dropdown {
    height: 48px !important;
}

.sd-root-modern {
    background-color: #fff !important;
}

.svc-flex-row {
    background-color: #fff !important;
}

.spg-panel__content {
    background-color: #fff !important;
}

.sl-question__header .sv-string-viewer {
    color: #fff
}

.relative-pos {
    position: relative;
}


.sd-action-bar .sd-btn {
    border: 0 !important;
    padding: 5px 26px !important;
    min-width:110px;
    color: var(--color-text-btn) !important;
    font-size:13px !important;
}

.sd-action-bar .sd-btn:hover {
    border: 0 !important
}

#pageSelector .sv-action-bar-item__title:hover {
    color: #FFF !important;
}

.svc-test-tab__content-actions button.sv-action-bar-item {
    background: #3f4c6b;
}

.svc-logic-paneldynamic select {
    color: #000 !important;
    border: solid 1px #CCC !important;
}

.svc-logic-paneldynamic .svc-logic-operator {
    color: #000 !important;
    border: solid 1px #CCC !important;
}

.sl-table__cell .sv-action-bar-item__icon {
    display: block !important;
}

.svc-creator__content-holder .svc-flex-column {
    margin: 20px;
}
.sd-question__title {
    text-align: var(--text-align-question) !important;
    font-weight: var(--text-weight-question) !important;
    text-decoration: var(--text-decoration-question) !important;
}

.svc-toolbox--compact .sv-action-bar-item svg {
    filter: var(--foreground-light, #90909f) !important;
}

.sv-dots__item .sv-action-bar-item__icon svg {
    filter: var(--foreground-light, #90909f) !important;
}
.one-text-inv{    
    color: var(--text-color-one-inv) !important;
    text-align: var(--text-align-one-inv) !important;
    font-weight: var(--text-weight-one-inv) !important;
    text-decoration: var(--text-decoration-one-inv) !important;
}
/*.one-text-log{    
    color: var(--text-color-one-log) !important;
    text-align: var(--text-align-one-log) !important;
    font-weight: var(--text-weight-one-log) !important;
    text-decoration: var(--text-decoration-one-log) !important;
}

.one-text-inv #inicio-guest{    
    color: var(--text-color-one-inv) !important;
    text-align: var(--text-align-one-inv) !important;
    font-weight: var(--text-weight-one-inv) !important;
    text-decoration: var(--text-decoration-one-inv) !important;
}
.one-text-log #inicio-logeado{    
    color: var(--text-color-one-log) !important;
    text-align: var(--text-align-one-log) !important;
    font-weight: var(--text-weight-one-log) !important;
    text-decoration: var(--text-decoration-one-log) !important;
}*/

.inicio-guest textarea
{    
    color: var(--text-color-one-inv) !important;
    text-align: var(--text-align-one-inv) !important;
    font-weight: var(--text-weight-one-inv) !important;
    text-decoration: var(--text-decoration-one-inv) !important;
}
.inicio-logeado textarea{    
    color: var(--text-color-one-log) !important;
    text-align: var(--text-align-one-log) !important;
    font-weight: var(--text-weight-one-log) !important;
    text-decoration: var(--text-decoration-one-log) !important;
}

.three-theme-backgroud{
    background-color: var(--background-three) !important;
}
.three-text-inv{    
    color: var(--text-color-three-inv) !important;
    text-align: var(--text-align-three-inv) !important;
    font-weight: var(--text-weight-three-inv) !important;
    text-decoration: var(--text-decoration-three-inv) !important;
}
.three-text-log{    
    color: var(--text-color-three-log) !important;
    text-align: var(--text-align-three-log) !important;
    font-weight: var(--text-weight-three-log) !important;
    text-decoration: var(--text-decoration-three-log) !important;
}

.three-text-inv #inicio-guest{    
    color: var(--text-color-three-inv) !important;
    text-align: var(--text-align-three-inv) !important;
    font-weight: var(--text-weight-three-inv) !important;
    text-decoration: var(--text-decoration-three-inv) !important;
}
.three-text-log #inicio-logeado{    
    color: var(--text-color-three-log) !important;
    text-align: var(--text-align-three-log) !important;
    font-weight: var(--text-weight-three-log) !important;
    text-decoration: var(--text-decoration-three-log) !important;
}



.sd-body{background-color: #fff;}
.sd-title{display: var(--display-title) !important;}

/*Personalización*/

.styleSurvey .one-text-log{line-height:20px;}
.styleSurvey .client-survey {/*height: 100vh !important;*/background:#20c6c6 !important;}
.contentPageRight .client-survey{height:calc(100% - 280px) !important;}
/*.styleSurvey .contentPageRight .client-survey .sd-page{height: calc(100vh - 590px);}*/
.styleSurvey .contentPageRight .container{margin-top:0 !important;margin-bottom:0 !important;}

.styleSurvey .sd-action-bar{justify-content: center !important;margin:20px 26px;}
.styleSurvey .container{
    width: 100% !important;
    max-width: 610px !important;
    background: #FFF;
    padding: 20px 30px !important;
    box-shadow: rgb(21 22 23 / 20%) 0px 1px 5px;
    border-radius: 10px;
    height: calc(100% - 40px) !important;
    margin: 20px auto !important;
    position:relative;
}

.styleSurvey .sd-header__text h3{font-weight: bold !important;}
.styleSurvey .client-survey{background-color: var(--backgroundFondo) !important;}



/*.styleSurvey .svc-logic-question-value, div:only-child > .sd-element--with-frame:not(.sd-element--collapsed) {padding: 0px !important;}*/
.styleSurvey .svc-logic-question-value, div:only-child > .sd-element--with-frame:not(.sd-element--collapsed){box-shadow:none !important;}

.styleSurvey .sd-page{padding:0 !important;}
.styleSurvey .sd-element:not(.sd-element--collapsed){padding-top:0 !important;}
.styleSurvey .sd-question__header--location-top  .sv-string-viewer{font-weight:bold !important;}
.styleSurvey .sd-input{height: auto !important;padding: 5px 10px !important;border: solid 1px #EAEAEA !important;box-shadow: none !important;background:#FFF;}
.styleSurvey .sd-element__num{color:#000 !important;font-weight:bold !important;}

.styleSurvey .sd-input:focus{box-shadow: 0 0 0 1px #20c6c6,inset 0px 1px 1px var(--border-inside, rgba(0, 0, 0, 0.16)) !important;}

.text-barrating{display:inline-block;vertical-align: middle;font-weight: bold;padding: 0 5px;}
.styleSurvey .sd-title{font-weight:bold !important;}

.styleSurvey .sd-logo img {margin: 0 !important;height: 100% !important;}
.styleSurvey .sd-root-modern{overflow:auto;position:relative;height:100%;overflow-x:hidden;}

/*.styleSurvey .sd-root-modern::-webkit-scrollbar {
    background:transparent;
    width:5px;
  }
  .styleSurvey .sd-root-modern::-webkit-scrollbar-track {
    background:transparent;
  }
  .styleSurvey .sd-root-modern::-webkit-scrollbar-thumb {
    background:transparent;
}
*/

.styleSurvey .sd-body{padding-bottom:0 !important;}

.styleSurvey .sd-description{font-weight:normal !important;line-height:18px !important;}
.styleSurvey .sd-logo{margin:20px 0;}
.styleSurvey .sd-element--complex.sd-element--with-frame>.sd-element__header--location-top:after{width:100% !important;left:0 !important;}
/*.styleSurvey .sd-action-bar{position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);}*/

.br-theme-vertical .br-widget a{display:block;margin:5px auto !important;width:26px !important;}
.br-theme-css-numeros .br-widget a{width:30px !important;}
 .br-theme-vertical .br-widget{height: auto;max-width: 230px;}
 .br-theme-vertical .text-barrating-init{margin-top:0;top:0;left:10%;}
 .br-theme-vertical .text-barrating-end{margin-bottom:0;bottom:0;left:10%;right:initial;}
 .br-theme-vertical .br-theme-css-manos.br-widget a:after{height:34px !important;}
 .br-theme-vertical .br-theme-css-estrellas .br-widget a:after{margin:auto;}
 .br-theme-css-estrellas{text-align:center;}
 .br-theme-css-numeros{text-align:center;}
 .br-theme-css-manos{text-align:center;}
 .br-theme-css-caras{text-align:center;}

.text-barrating{position:absolute;bottom:0;margin-top:43px;display: block;}
.text-barrating-init{left:0;}
.text-barrating-end{right:0;}
.text-barrating{margin:10px 0;}
.br-widget{/*transform:scale(1);*/height:80px;/*max-width:315px;*/margin:auto;position:relative;display:inline-block;}
.styleSurvey .one-text-log{
    display: flex;
    align-items: center;
    justify-content: center;}
.br-theme-css-manos .br-widget a{width:36px !important;}
/*.styleSurvey .sd-page{height: calc(100vh - 330px);overflow-y:auto;overflow-x:hidden;}*/
.styleSurvey .sd-page .sd-row{min-height:initial !important;}

.styleSurvey .sd-rating__item{width:100% !important;display:block !important;min-width:initial !important;margin-bottom:10px !important;box-shadow:none !important;border:solid 1px #EAEAEA !important;
border-radius: 5px !important;cursor:pointer !important;box-sizing:border-box !important;padding:5px !important;}
.styleSurvey .sd-scrollable-container.sd-rating{display:block !important;min-width: initial !important;min-height: initial !important;width:100% !important;}
.styleSurvey .sd-rating fieldset{display:block !important;margin-top:10px !important;}
.styleSurvey .sd-rating__item--allowhover:hover, .sd-rating__item:focus-within{box-shadow:none !important;}

 .br-theme-vertical .text-barrating{left:-50px;transform: translateX(-50%);padding:10px !important;}

.styleSurvey .sd-root-modern{overflow-x:hidden;overflow-y:scroll;}
.styleSurvey .sd-root-modern::-webkit-scrollbar {width:5px;}
.styleSurvey .sd-root-modern::-webkit-scrollbar * {background:transparent;}

/*responsive*/

@media screen and (max-width:640px) {
.styleSurvey .container{margin: auto !important;height:100% !important;padding:20px 10px !important;}
.styleSurvey .sd-description .sv-string-viewer{font-size:13px !important;}
.styleSurvey .sd-description {line-height:18px !important;}
.styleSurvey .sd-logo{text-align:center;}
/*.styleSurvey .br-theme-css-estrellas .br-widget a{display: block;width: 26px;margin: 5px auto;height: 26px;}*/
.styleSurvey .sd-page{padding:0 !important;min-width:initial !important;max-width:initial !important;}
.styleSurvey .sd-element--with-frame{padding:0 !important;min-width:initial !important;max-width:initial !important;white-space:normal !important;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li{padding-bottom: 5px !important;padding-top: 5px !important;}
.styleSurvey .sd-progress-buttons__page-title{font-size:12px !important;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li{padding-left:5px !important;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li:first-child{padding-left:0 !important;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li .sd-progress-buttons__page-title:after{transform: translate(-9px, -50%) !important;border-left-width: 14px;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li:after{transform: translate(-7px, -50%) !important;border-left-width: 14px;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li:last-child:after{display:none !important;}
.styleSurvey .styleNav3 .sd-progress-buttons__list li:last-child .sd-progress-buttons__page-title:after{display:none !important;}
.styleSurvey .sd-progress-buttons__list-container{margin:20px 0;margin-bottom:0;padding-bottom:0 !important;}
.styleSurvey .sd-progress-buttons__list li{margin-bottom:0 !important;padding-bottom:0 !important;}
.br-theme-scale-10.br-theme-css-manos.br-theme-horizontal .br-widget a{width:7% !important;}
 .br-theme-scale-10.br-theme-css-numeros.br-theme-horizontal .br-widget a{width:22px !important;height:28px !important;}
/*.styleSurvey .br-widget a{width:8%;}*/
/*.sd-root-modern--mobile{height: calc(100% - 53px);display: block;overflow-y: auto;overflow-x: hidden;}*/
.sd-body.sd-body--responsive{padding-bottom:0;}
.styleSurvey .sd-root-modern{overflow-x:hidden;overflow-y:scroll;width: calc(100% + 5px);}
.styleSurvey .sd-root-modern::-webkit-scrollbar {width:5px;}
.styleSurvey .sd-root-modern::-webkit-scrollbar * {background:transparent;}
.styleSurvey .sd-root-modern::-webkit-scrollbar-thumb {background:transparent !important;}
}



@media screen and (max-width:370px) {
/*.styleSurvey .sd-row{width:auto !important;margin:auto;}    */
.br-theme-scale-10.br-theme-css-numeros.br-theme-horizontal .br-widget a{width:18px !important;height: 25px !important;font-size:12px !important;padding-left:0;padding-right:0;}
.br-theme-css-caras .br-widget a:after{width:20px !important;}
.br-theme-css-estrellas .br-widget a{width:20px !important;}
}
/*
@media all and (orientation:landscape){    

}
.styleSurvey .sd-rating__item .sv-string-viewer{font-size:13px !important;}

/*@media (orientation: landscape) {
.sd-progress-buttons__container-center{display:none;}
 }
*/

.styleSurvey .one-theme-backgroud{background-color: var(--background-one) !important;}
/*.styleSurvey .sd-item__decorator{
border-color:#168b8b !important;
}
/*
.styleSurvey .sd-item__decorator{
    box-shadow: 0 0 0 2px #b1d1d1 !important;
    background: #168b8b !important;
    border: solid 4px #FFF !important;
}*/


/*estilos navegacion encuesta*/
/*Style 1*/
.styleNav1 .sd-progress-buttons__list li {
    border-top: 2px solid var(--background-navigation) !important;
}

.styleNav1 .sd-progress-buttons__list .sd-progress-buttons__list-element--current {
    border-top: 2px solid var(--background-navigation-current) !important;
}

.styleNav1 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed {
    border-top: 2px solid var(--background-navigation-passed) !important;
}

/*Style 2*/
.styleNav2 .sd-progress-buttons__list li {
    border-top: 10px solid var(--background-navigation) !important;
    margin: 10px 5px;
    padding: 0 !important;
    border-radius: 5px;
}

.styleNav2 .sd-progress-buttons__list .sd-progress-buttons__list-element--current {
    border-top: 10px solid var(--background-navigation-current) !important;
}

.styleNav2 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed {
    border-top: 10px solid var(--background-navigation-passed) !important;
}

.styleNav2 .sd-progress-buttons__page-title {
    display: none;
}

/*Style 3*/
.styleNav3 .sd-progress-buttons__list {
    background: var(--background-navigation) !important;
    overflow: hidden;
}

.styleNav3 .sd-progress-buttons__list li {
    margin: 0;
    position: relative;
    border: 0 !important;
    padding-left: 20px;
}

.styleNav3 .sd-progress-buttons__list li:first-child {
    padding-left: 10px !important;
}


.styleNav3 .sd-progress-buttons__list-element--current {
    background: var(--background-navigation-current) !important;
}

.styleNav3 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title {
    color: #FFF !important;
}

.styleNav3 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed {
    background: var(--background-navigation-passed) !important;
    color: white;
    border: 0;
}

.styleNav3 .sd-progress-buttons__list li:after {
    position: absolute;
    top: 50%;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 33px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
    z-index: 1;
    transform: translate(0px, -50%);
    border-left-color: #FFF;
    margin: 0;
}

.styleNav3 .sd-progress-buttons__list li .sd-progress-buttons__page-title:after {
    position: absolute;
    top: 50%;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 33px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
    z-index: 2;
    transform: translate(-2px, -50%);
    border-left-color: var(--background-navigation);
    margin: 0;
}

.styleNav3 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title:after {
    border-left-color: var(--background-navigation-current) !important;

}

.styleNav3 .sd-progress-buttons__list-element--passed .sd-progress-buttons__page-title:after {
    border-left-color: var(--background-navigation-passed) !important;
}

/*Style 4*/
.styleNav4 .sd-progress-buttons__list li {
    position: relative;
    margin: 10px 0;
}

.styleNav4 .sd-progress-buttons__list li::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 0;
    background: #FFF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -13px;
    border: solid 2px var(--background-navigation) !important;
    z-index: 1;
}

.styleNav4 .sd-progress-buttons__list li:first-child::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    top: 0;
    background: #FFF;
    position: absolute;
    left: 0;
    margin-top: -2px;
}

.styleNav4 .sd-progress-buttons__list li:last-child::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    top: 0;
    background: #FFF;
    position: absolute;
    right: 0;
    margin-top: -2px;
}


.styleNav4 .sd-progress-buttons__list-element--current::before {
    background: var(--background-navigation-current) !important;
    border-color: #FFF !important;
}

.styleNav4 .sd-progress-buttons__list .sd-progress-buttons__list-element--current {
    border-top: 2px solid var(--background-navigation-current) !important;
}

.styleNav4 .sd-progress-buttons__list li.sd-progress-buttons__list-element--current::before {
    border: solid 2px var(--background-navigation-current) !important;
}


.styleNav4 .sd-progress-buttons__list-element--passed::before {
    background: var(--background-navigation-passed) !important;
    border-color: #FFF !important;
}

.styleNav4 .sd-progress-buttons__list li.sd-progress-buttons__list-element--passed::before {
    border: solid 2px var(--background-navigation-passed) !important;
}

.styleNav4 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed {
    border-top: 2px solid var(--background-navigation-passed) !important;
}


.styleSurvey .svd-simulator-main .sd-btn{
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}
.styleSurvey .svd-simulator-main .sd-btn:hover {
    background: var(--background-btn-hover) !important;
    color: var(--color-text-btn) !important;
    padding: 7px 28px !important; 
}

/*.styleSurvey .svd-simulator-main:not(.svd-simulator-main--frame){    height: calc(100% - 141px)}*/
.styleSurvey .svd-simulator-main .sd-page{height:100%;}
.styleSurvey  .svd-simulator-content.container{margin-top:20px !important;}
/*.styleSurvey .svd-simulator-content .sd-page {height: calc(100vh - 510px);}*/
.styleSurvey .svd-simulator .container{padding:10px !important;box-shadow:none !important;}
.styleSurvey .svd-simulator #sv-nav-next{display:none !important;}
.sv-action-bar-item.sv-dots__item{filter:none !important;}
/*mobile simulator*/
/*.styleSurvey .svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content{height: calc(100% - 150px) !important;}*/
/*.styleSurvey .svd-simulator-iphone5 .sd-page {height: calc(100vh - 475px) !important;}*/
.styleSurvey .sd-item{padding:5px !important;}
.styleSurvey .sd-item__decorator{width:14px !important;height:14px !important;margin-top:5px;}
.styleSurvey .sd-question__erbox{margin:0 !important;border-radius:0 !important;padding:5px 10px !important;margin-bottom:10px !important;background: #fff4f4;
    color: #d60000;width: 100% !important;box-sizing: border-box;}
.styleSurvey .sd-question__erbox .sv-string-viewer{font-size:12px !important;}
.styleSurvey .sd-question__description .sv-string-viewer{font-weight:normal !important;font-size:13px !important;}

.styleSurvey .sd-body__navigation .sv-action{margin:0px;display:inline-block;}
.styleSurvey .sv-action{margin:0 10px !important;}
.styleSurvey .sv-action--hidden{display:none !important;}


.styleNav1 .sd-progress-buttons__page-title{font-size: var(--fontsize-navigation-passed) !important;color: var(--fontcolor-inactive-navigation-passed) !important;}
.styleNav1 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title {
    color: var(--fontcolor-navigation-passed) !important;
}
.styleNav2 .sd-progress-buttons__page-title{font-size: var(--fontsize-navigation-passed) !important;color: var(--fontcolor-inactive-navigation-passed) !important;}
.styleNav2 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title {
    color: var(--fontcolor-navigation-passed) !important;
}
.styleNav3 .sd-progress-buttons__page-title{font-size: var(--fontsize-navigation-passed) !important;color: var(--fontcolor-inactive-navigation-passed) !important;}
.styleNav3 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title {
    color: var(--fontcolor-navigation-passed) !important;
}
.styleNav4 .sd-progress-buttons__page-title{font-size: var(--fontsize-navigation-passed) !important;color: var(--fontcolor-inactive-navigation-passed) !important;}
.styleNav4 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title {
    color: var(--fontcolor-navigation-passed) !important;
}
.styleSurvey .sd-rating__item--selected{background-color:var(--pregunta-opcion-fondo) !important;color:var(--pregunta-opcion-texto) !important}


.animate .bx-check{color: var(--color-check-animacion-fin) !important}
.animate{background:var(--color-circulo-animacion-fin) !important}
.animateMask{background:var(--color-circulo-animacion-fin) !important}
#span-fin-text{color: var(--color-texto-animacion-fin) !important}

.styleSurvey .sd-input:focus {
    border-color: var(--color-transiciones) !important;
    box-shadow: 0 0 0 0px var(--color-transiciones),inset 0px 1px 1px var(--border-inside, rgba(0, 0, 0, 0.16)) !important;
}
.sd-item--checked .sd-item__decorator{background: var(--color-transiciones) !important;}
.styleSurvey .sd-logo {text-align: var(--image-header-center) !important;}

/**/
.styleSurvey .sd-matrix__responsive-title{display: none !important;}
/*.styleSurvey .sd-matrix .sd-table{overflow:auto;transform: scale(0.8);}*/
/*.styleSurvey .sd-element__num{text-align: left;width:23px;padding-top:5px;}*/
.sd-question--table > .sd-question__content:before{width:10px !important;}
.sd-matrix__cell:first-child, .sd-matrix tr > td:first-child {left:1px !important;}
.styleSurvey .sd-matrix fieldset {overflow:initial;}

.styleSurvey .sd-question--table>.sd-question__content{width:initial !important;}

.styleSurvey .sd-matrix .sv-string-viewer{font-size:12px !important;}
.styleSurvey .sd-matrix{border: solid 15px transparent;box-sizing: border-box;}

.sd-body--empty{text-align: center;}
.sd-body--empty span{font-weight:bold;}

.sd-question--table>.sd-question__content:after{display:none !important;}

/*.styleSurvey .sd-matrix__table{min-width:400px !important;}*/


.styleSurvey .sd-matrix thead {
    display: table-header-group !important;
  }
  
 .styleSurvey .sd-matrix tr {
    display: table-row !important;
  }
  
.styleSurvey .sd-matrix th, td {
    display: table-cell !important;
  }

.styleSurvey .sd-speedtotext-in{padding-right: 25px !important;}

.styleSurvey .sd-page__title{color:var(--text-title-page) !important;}
.styleSurvey .sd-page__description{color:var(--text-subtitle-page) !important;}
.loading-fin .chase-dot:before{background: var(--color-circulo-animacion-fin) !important;}

/*.idiomaes::after{color: var(--color-text-btn) !important;}*/
.flagSelected {    background-color: var(--background-btn) !important;color: var(--color-text-btn) !important; border-color: var(--background-btn) !important;}
.flagSelected::after{    color: var(--color-text-btn) !important;}
.btnStartIdioma{background-color: var(--background-btn) !important; color: var(--color-text-btn) !important;}
