*, ::before, ::after {
    box-sizing: unset;
}
body{font-family: 'Ubuntu', sans-serif;}
/*Login*/
.main{margin-top:60px;}
.main::before{content:"";display: block;position: fixed;
width:100%;
height: 100%;
top:0;left:0;
z-index:-1;
background: hsla(255, 11%, 93%, 1);
background: linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
background: -moz-linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
background: -webkit-linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ECEBEF", endColorstr="#F1EEEB", GradientType=1 );
}
.container{width:730px;margin:auto;overflow:hidden;}
.login{overflow: hidden;border-radius:10px;}
.emailtxt{color:#FFF;font-size:14px;padding:10px;}
.txtcontent{color:#FFF;margin:45px 0;line-height:20px;}
.left-content{text-align:center;width:273px;padding:50px;float:left;
    height:344px;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );

}
.left-content h1{font-size:24px;color:#FFF;font-weight: normal;margin:40px 0;}
.btn-login{background:url(../images/login/windows-icon.jpg) no-repeat left 22px center #FFF;color:#535252;font-size:14px;text-align:center;padding:16px;font-weight:bold;border-radius:5px;padding-left:50px;cursor: pointer;}
.btn-login:hover{background:url(../images/login/windows-icon.jpg) no-repeat left 22px center #edeff1;}
.right-content{float:right;height: 445px;background: #FFF;width:357px;text-align:center;background:url(../images/login/bg-login.jpg) no-repeat center center #FFF;}
.copyright{color:#8f8f8f;text-align:center;padding:20px;font-size:14px;margin-top:10px;}

/*responsive*/
@media only screen and (max-width:768px){    
    .main{margin-top:20px;}
    .container{width: auto;margin:0 20px;}    
    .left-content{float:none;width:auto;height: auto;padding: 20px 30px;}
    .txtcontent{margin:30px 0;}
    .right-content{float:none;width:auto;height:300px;background-size: 75%;}
    .left-content h1{margin:20px 0;}
    .btn-login{margin-bottom:20px;}
}