body{font-family: 'Ubuntu', sans-serif !important;line-height:1 !important;}
.contentPageLeft  *, *::before, *::after {
    box-sizing: unset !important;
}
.navbar-header *, *::before, *::after {
    box-sizing: border-box !important;
}
/*Login*/
.main{margin-top:60px;}
.main::before{content:"";display: block;position: fixed;
width:100%;
height: 100%;
top:0;left:0;
z-index:-1;
background: hsla(255, 11%, 93%, 1);
background: linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
background: -moz-linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
background: -webkit-linear-gradient(90deg, hsla(255, 11%, 93%, 1) 0%, hsla(30, 18%, 93%, 1) 50%, hsla(97, 24%, 93%, 1) 100%);
filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#ECEBEF", endColorstr="#F1EEEB", GradientType=1 );
}
.container{width:830px;margin:auto;overflow:hidden;}
.login{overflow: hidden;border-radius:10px;}
.emailtxt{/*background:rgba(0,0,0,0.2);*/color:#FFF;font-size:14px;padding:10px;}
.txtcontent{color:#FFF;margin:45px 0;line-height:22px;padding-bottom: 15px;}
.txtContent{line-height: 18px;}
.left-content{text-align:center;width:273px;padding:50px;
    height:344px;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );

}
.left-content h1{font-size:24px;color:#FFF;font-weight: normal;margin:40px 0;}
.btn-login{background:url(../images/general/windows-icon.jpg) no-repeat left 10px center #FFF;color:#535252;font-size:14px;text-align:center;padding:14px;font-weight:bold;border-radius:5px;padding-left:42px;cursor: pointer;width:190px;margin:auto;}
.btn-login:hover{background:url(../images/general/windows-icon.jpg) no-repeat left 10px center #edeff1;}
.right-content{float:right;height: 445px;background: #FFF;width:457px;text-align:center;background:url(../images/general/bg-login.jpg) no-repeat center center #FFF;}
.copyright{color:#8f8f8f;text-align:center;padding:20px;font-size:14px;margin-top:10px;}

/*content*/
.wrapper{font-size:13px;height: 100%;overflow: hidden;}
.wrapper::before{content:"";display: block;position: fixed;background:#fff;width:100%;height:100%;z-index:-1;}
.topContent{
height:150px;
position: relative;
background: #FFF;
overflow:hidden;
}
.btnaddForm{color:#FFF;font-size:13px;text-align:center;padding:12px 18px;background:url(../images/general/add_ic.png) no-repeat center right 5px #0a78c4;border-radius:4px;padding-right:40px;width:120px;margin:20px auto;}
.styleForm{margin-top:30px;}
.stepContainer{overflow:hidden;text-align: center;text-align: center;position: absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:70%;font-size:0;}
.step{width:25%;box-sizing: border-box;display:inline-block;vertical-align:top;padding:0 25px;position: relative;font-size:14px;}
.step::after{
content: "";
display: block;
height: 5px;
background: #f3f3f3;
position: absolute;
top: 0;
width: 100%;
margin-top: 13px;
left:0;
z-index:1;
}

.step:first-child::after{
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}
.step:last-child::after{
border-top-right-radius: 4px;
}


.stepNum{font-size:14px;padding:8px;border-radius:50%;display: inline-block;width:14px;height: 14px;margin-bottom: 10px;position: relative;z-index:2;color:#CCC;background:#FFF;border:solid 2px #f5f5f5;font-weight:bold;}
.stepActive .stepTxt{color:dodgerblue;}
.stepActive .stepNum{color:#FFF;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
    /*border:0;*/
}
.stepActive:after{
 background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.stepTxt{font-weight:500;font-size:12px;}
.headerContainer{
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );

}
.header{height:53px;border-top:solid 1px #dbdbdb;}
.header ul{list-style-type:none;margin:0;overflow:hidden;float:left;margin-top:0px;}
.header li{float:left;color:#000;border-right:solid 1px #FFF;border-color:rgba(255,255,255,0.6);padding:0px 16px;color:#FFF;line-height:53px;cursor:pointer;transition: all .2s ease-in;}
.header li:hover{background:#0084f9;}
.header li:last-child{border:0;}
.header li span{margin-right:15px;}
.header li img{vertical-align:middle;}
.toolList{list-style-type:none;padding:0;margin:0;overflow:hidden;margin-top:0px;margin-left:15px;border-bottom:solid 1px rgba(0, 0, 0, 0.08);}
.toolList:last-child{border:0;}
.toolList li{display: block;padding:10px;color:#000;cursor: pointer;transition: all 0.25s ease;border-radius:5px;position:relative;}
.toolList li:hover{background: #FFF;}
.toolList li span{margin-right:10px;position:absolute;top:0;left:0;margin-top:9px;}
.toolList li img{vertical-align:middle;/*position:absolute;top: 0;left: 0;margin-top: 8px;margin-left:0px;*/}
.contentPageRight{overflow:hidden;padding:20px;overflow:auto;position: absolute;width:calc(100% - 190px);right:0;box-sizing: border-box;height:100%;height:calc(100% - 174px);scrollbar-color: #CCCCCC #f5f5f5; /* thumb and track color */
   scrollbar-width: thin;}
.contentPageRightTheme{overflow:hidden;padding:30px;overflow:auto;position: absolute;width:calc(100%);right:0;box-sizing: border-box;height:100%;height:calc(100% - 174px);height:100%;overflow: auto;}
.imageDefault{text-align:center;position: absolute;top:50% !important;left:50% !important;transform: translate(-50%, -50%) !important;width:525px;}
.imageDefault img{width:200px;}
.txtDefaultContainer{width:325px;float:left;text-align: left;font-size:14px;color:#575757;}
.txtDefault{padding:50px 0;font-size:32px;color:#0084f9;}
.txtDefault span{display:block;color:#666;}
.btn_crear_formulario{
    margin-top:25px;
    display:inline-block;
    text-align: center;
    color:#FFF !important;
    border:0 !important;
    padding:16px 28px !important;
    border-radius:3px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.tools{width:160px;text-align:center;padding-left:13px !important;padding-right: 46px !important;}
.tools span{margin-right:23px;}
.selected{color:#000 !important;background:#F3F3F3 !important;color:#000 !important;}
.rightList{float:right !important;}

.view_ic{margin:0 12px;}
.btn_{margin:0 !important;padding:0;}

.contentBox{padding:40px;/*background: #FFF;*/margin-bottom:20px;border-radius: 5px;height: calc(100vh - 200px);overflow: hidden;/*max-width:600px;*/}
.titleBox{font-size:1.4em;margin-bottom:10px;}
.inputBox{background:#f9f9f9;border:0;border:solid 1px #eee;border-top:solid 1px #DFDFDF;font-size:1em;padding:14px;border-radius:2px;width:100%;box-sizing: border-box;}
.textArea{background:#f9f9f9;border:0;border:solid 1px #eee;border-top:solid 1px #DFDFDF;font-size:1em;padding:14px;border-radius:2px;width:100%;min-height:150px; box-sizing: border-box;}
.radioGroup{margin-top:30px;}
.radioGroup div{margin-bottom:16px !important;font-size:1.2em !important;color:#666 !important;}
.radioGroup div:last-child{margin-bottom:0;}
.mobileOptions{display:none;border-left:solid 1px #FFF;border-color:rgba(255,255,255,0.6)}

.contentData{border:solid 1px #ccc;border-radius: 6px;padding:34px 50px;background: #FFF;width:304px;margin:40px auto;}
.contentData label{margin-bottom:10px;padding:10px 0;width:155px;display:inline-block;color:#666;}
.contentData div{margin-bottom:6px;overflow:hidden;}
.contentData div span{width:145px;display:inline-block;overflow:hidden;vertical-align:middle;}
.contentData input[type="text"]{width:100%;box-sizing:border-box;border:0;border-bottom:solid 1px #ccc;padding:6px 12px;font-size:13px;font-family: 'Ubuntu', sans-serif;color:#666;background:#F3F3F3;border-radius:3px;}
.conbtentData select{width:100%;border:0;border-bottom:solid 1px #ccc;padding:6px 6px;font-size:13px;}
.btnForm{
color:#FFF !important;
border:0 !important;
padding:12px 10px !important;
border-radius:3px;
font-weight: bold;
cursor: pointer;
box-sizing: border-box;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
display:block;
width:100%;
}

.sidebarMenu{margin-left:30px;margin-top:5px;}
.sidebarMenu ul{margin-left:20px;}
.sidebarMenu li{padding:10px 0;overflow: hidden;margin-bottom:5px;}
.sidebarMenu li:hover{background: none;}
.sidebarMenu ul li span{/*margin-left:-7px;*/margin-right:5px;margin-top:-3px;margin-left:4px;position:absolute;top:0;left:0;margin-top:5px;}

.primaryMenu{background:url(../images/general/expandMore.svg) no-repeat  center right #FFF;padding:6px 14px;border-radius:4px;background-size:10px;}

.mobilePhone{padding:56px 23px;width:300px;margin:auto;}
.mobilePhone::before{
content: "";
position: absolute;
z-index: -1;
display: block;
background: url(../images/general/mobile-phone-mockup.png) no-repeat center center;
background-size: cover;
width: 345px;
height: 700px;
left: 50%;
transform: translateX(-50%);
top: 39px;

}
.inner{width:300px;height:612px;overflow-y:auto;margin:auto;}

.landscapeMobile{width:700px;height: 200px;}
.landscapeMobile::before{transform:translateX(-50%) rotate(-90deg);top:0;margin-top:-113px;}
.landscapeMobile .inner{width:612px;height:300px;}


.tablet{padding:40px 23px;width:450px;margin:auto;}
.tablet .inner{width:450px;height:640px;}
.tablet::before{
content: "";
position: absolute;
z-index: -1;
display: block;
background: url(../images/general/tablet-mockup.png) no-repeat center center;
background-size: cover;
width: 506px;
height: 700px;
left: 50%;
transform: translateX(-50%);
top: 39px;

}

.landscapeTablet{width:700px;height:440px;}
.landscapeTablet::before{transform:translateX(-50%) rotate(-90deg);top:0;margin-top:-62px;}
.landscapeTablet .inner{width:648px;height:440px;}

.laptop{padding:40px 23px;width:910px;height:572px;margin:auto;}
.laptop .inner{width:779px;height:481px;}
.laptop::before{
content: "";
position: absolute;
z-index: -1;
display: block;
background: url(../images/general/laptop-mockup.png) no-repeat center center;
background-size: cover;
width: 960px;
height: 605px;
left: 50%;
transform: translateX(-50%);
top: 35px;

}

.landscapeLaptop{width:700px;height:440px;}
.landscapeLaptop::before{transform:translateX(-50%) rotate(-90deg);top:0;margin-top:-62px;}
.landscapeLaptop .inner{width:648px;height:440px;}


.themeList{list-style-type:none;padding:0;margin:0;overflow:hidden;margin-top:20px;margin-left:15px;}
.themeList li{display: block;padding:10px;color:#666;cursor: pointer;border-radius:6px;}
.themeList li:hover{background: #FFF;}
.themeList li span{display:block;border:solid 1px #CCC;background: #FFF;border-radius:5px;padding: 5px;}
.themeList input[type="color"]{width:32px;border:0;}
.themeList li img{vertical-align: middle;}


/*responsive*/

@media only screen and (min-width:961px) and (max-width:1305px){
    .rightList{display:none;}
    .mobileOptions{display:block;}
}


@media only screen and (max-width:960px){
    .main{margin-top:20px;}
    .container{width: auto;margin:0 20px;}
    .left-content{float:none;width:auto;height: auto;padding: 20px 30px;}
    .right-content{float:none;width:auto;height:300px;background-size: 75%;}
    .left-content h1{margin:20px 0;}
    .btn-login{margin-bottom:20px;}
    .stepContainer{width:100%;}
    .contentPageRight{width:calc(100% - 60px);padding:30px 2px;}
    .contentPageRightTheme{width:calc(100% - 60px);padding:30px 2px;height:100%;overflow: auto;}
    .titleBox{font-size:100%;}
    .radioGroup div{font-size:100%;}
    .radioGroup{margin-top:15px;}
    .contentBox{padding:15px;}
    .header li{padding:0 22px !important;}
    .header li span:nth-child(2n) {display:none;}
    .header li span{margin-right:0;}
    .tools{width:auto;}
    .toolList{margin-left:5px;}
    .rightList{float:none !important;display:block;}
    .header li:last-child{border-right:solid 1px #FFF;}
    .step{padding:0 10px;}
    .contentData{width:auto;margin:20px;margin-top:0;padding:34px;}
    .contentData label{width:100%;}
    .contentData div span{width:100%;}
    .contentBoxTheme{padding:15px;}
    .radioGroupTheme div{font-size:100%;}
    .radioGroupTheme{margin-top:15px;}
    .scrollTabContent{height:auto !important;}
    .themeList2{height:auto !important;}
    .headerFixed .contentBox{margin-top:10px !important;}
    .footer .col-md-6{padding:0 !important;}
}
/*scroll*/
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    background: #FFF;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(214, 214, 214, 1);
    -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

#layout-wrapper::before {
    content: "";
    display: block;
    position: fixed;
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    z-index: -1;
}

body {
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;
    min-width:340px;
}
#page-topbar{
    position: relative;
    background-color: #fff !important;
    z-index:10020000000 !important;
}
#root{
    font-size: 13px;
}
/*.footer{
    height: 25px !important;
}*/
body[data-layout="horizontal"] .page-content{
    margin-top: 30px !important;
    padding: 0px !important;
}
.main-content{
    width: calc(100% - 260px);
    margin-left: 230px !important;
}
.main-content-survey {
    width: calc(100% - 20px) !important;
    margin-left: 20px !important;
    transition:all 0.2s ease-in;
}

/*cambios jc*/
.navbar-brand-box img{filter:invert(71%) sepia(42%) saturate(12%) hue-rotate(6deg) brightness(40%) contrast(8%);vertical-align: middle;width:31px;height:31px;}
/*.navbar-brand-box{background:#666;}*/
.header-profile-user{vertical-align: middle;}
.header-item span{color:#666;}
.header-item{height:56px;}
.header-item .mdi-chevron-down{color:#333;}
.bx-fullscreen{color:#666 !important;}
.open-feed.inactiveBell svg{fill:#666 !important;}
.open-feed{width:16px !important;position: relative;left: 0;top:17px;}
.dropdown-divider{display:none !important;}
.dropdown-item span{vertical-align:middle;font-size:13px;}
.sidebarMenu{margin-left:0;}
body[data-layout="horizontal"] .page-content{margin-top:8px !important;/*margin-right:20px;*/}
/*.toolList{margin-top:14px;}*/
.sidebarMenu li{padding-top:0;}
.dropdown-item{padding:6px 5px !important;padding-left:23px !important;box-sizing: border-box;color:#505962;}
.sidebarMenu li{padding:0;}
.sidebarMenu ul{margin-left:10px;padding-left: 0px;}
.primaryMenu{/*margin-bottom:5px;*/padding:10px 0px;padding-left:25px !important;}
.dropdown-item:hover, .dropdown-item:focus{background:#e5f0f5;}
/*.page-content{display:flex;}*/
#page-header-search-dropdown{display:none;}
.main-content-survey{padding: 0 !important;    width: calc(100% - 215px);    position: absolute;    overflow: hidden;    height: calc(100% - 107px);}
.header li{padding:0 10px;}
.header li span{margin-right:5px;}

.contentLeftTools{width: 190px;float: left;position: fixed;box-sizing: border-box;height: calc(100% - 194px);overflow: auto;margin:0;padding:0 10px;}

.contentRightForm{padding:20px;overflow:auto;position:absolute;width:calc(100% - 190px);right:0;box-sizing:border-box;height: calc(100% - 174px);}
.tools{width:auto;padding-left:28px !important;padding-right:36px !important;}
.tools span{margin-right:23px !important}
.page-content{padding:0;}
.topContent{height:128px;}

#layout-wrapper-formulario #page-topbar{left:0;}
#layout-wrapper-formulario .navbar-brand-box{width:auto;}
#layout-wrapper-formulario .topContent{background:#f3f3f3;}
#layout-wrapper-formulario .contentRightForm{background:#F3F3F3;}
#layout-wrapper-formulario .contentLeftTools{background:#F3F3F3;}

.step::after{background:#FFF;}
.stepNum{border-color:#FFF;}
/*.open-feed{padding:22px 0;}*/
.contentRightForm{height:calc(100% - 100px);}
.tepTxt{font-size:12px;}
.stepActive .stepTxt{color:dodgerblue;}
.stepActive .stepNum{color:#FFF;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
    /*border:0;*/
}
.stepActive:after{
 background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

/*table*/
.tg  {border-collapse:collapse;border-spacing:0;width:100%;}
.tg thead{color:#FFF;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.tg td{border:solid 1px #F3F3F3;font-family:Arial, sans-serif;font-size:13px;overflow:hidden;padding:6px 8px;word-break:normal;line-height:20px;color:#333;}
.tg th{border:0px;font-family:Arial, sans-serif;font-size:13px;font-weight:normal;overflow:hidden;padding:10px 8px;word-break:normal;}
.tg .tg-0lax{text-align:left;vertical-align:top}

.options{list-style-type:none;padding:0;margin:0;}
.options li{float:left;margin-right:5px;}
.options img{vertical-align: middle;}

.filter_grid input[type="text"]{width:calc(100% - 60px);border:solid 1px #F3F3F3;padding:5px;float:left;box-sizing:border-box;border-radius:5px;margin-right:3px;color:#333;}
.btn_grid{cursor:pointer;padding:5px;width:24px;height:27px;box-sizing:border-box;float:left;background:#FFF;border:solid 1px #F3F3F3;border-radius:5px;margin-right:3px;}
.btn_grid img{width:100%;}
.bgForm2{background:#fbfafa;}

.formContainer{overflow:hidden;margin-top:0px;}
.formContent{overflow:hidden;/*background:#FFF;*/padding:20px;padding-left:0;padding-right:0;border-radius:5px;margin-bottom:0px;padding-bottom: 0px;}

.title{font-size:16px;text-transform: uppercase;color:#495057;font-weight:bold;padding:10px 0;margin-bottom:20px;position:relative;}
.btnNewForm{
text-transform: none;
color:#FFF;
font-size:13px;
padding:10px 12px;
border-radius:5px;
font-weight:500;
background-repeat:no-repeat;
background-position:center left;
/*padding-left:30px;*/
display:inline-block;
margin-top:10px;
cursor: pointer;
transition: all 0.5s ease;
    transform: translate(0, 0);
    font-weight: 500;
}

.btnNewForm:hover{
   /* background: #20c6c6 !important;
    color: #FFF !important;*/
    transition: all 0.35s ease;

}

.page-title-box{display:block !important;padding-bottom:5px !important;}
.page-title-box h4{float:left;}
.page-title-right{float:right;}
.breadcrumb{display:block;}

/*.row > *{padding-left:0 !important;}*/
.row{margin-right:0;padding-right: 0;}
.main-content-survey .col-12 {flex: 0 0 auto;width: calc(100% - 20px);}
.breadcrumb-item{float:left;}
.main-content-survey .container-fluid{margin-top:20px;padding-left:0;}

/*correcion estilo*/
.k-stepper .k-step-done .k-step-indicator{
border-color:#00a3fe;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.k-stepper .k-step-current .k-step-indicator{
    border-color:#00a3fe;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.k-progressbar .k-state-selected{
    border-color:#00a3fe;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );

}

.k-stepper .k-step-indicator{color:#CCC;}

.k-stepper .k-step:hover .k-step-indicator, .k-stepper .k-step.k-step-hover .k-step-indicator{background-color:#FFF;}

.k-stepper .k-step-label .k-step-text {
max-width:initial;
flex-grow: 1;
white-space:initial;
text-overflow:initial;
overflow:initial;
font-size:13px;
color:#FFF !important;
}

.k-stepper .k-step-list{color:#b8c2cc;}

/*ocultar header*/
.headerFixed .navbar-header{position:fixed;background: #FFF;/*box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);*/box-shadow:none;display:none;}
.headerFixed .navbar-header .d-flex:nth-child(2n){display:none !important;}
.headerFixed .contentPageLeft{margin-top:70px;width:70px !important;font-size:0;top:72px;transition: all 0.2s ease-in;transform:translateX(-70px);}
.headerFixed .main-content-survey-form{margin-left:85px;}
.showLeftControls .contentPageLeft{transform:none;}
/*botones*/

.k-button-solid-primary{
    border: solid 2px #20c6c6;
    color: #20c6c6;
    border-radius:5px;
    background: #FFF;
    transition: all 0.5s ease;
    transform: translate(0, 0);
    font-weight:500;
}
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
background: #20c6c6 !important;
color: #FFF;
transition: all 0.35s ease;
border: solid 2px #20c6c6 !important;
}

.k-form .btnNewForm{
    border:0 !important;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
padding:10px;width:100%;}
.k-form-buttons{margin-top:1em;}


/*grid*/
.k-grid-header .k-column-title{font-weight:normal !important;}
.k-column-title{font-weight:normal !important;}
.k-pager-numbers{cursor:pointer;}
.k-pager-numbers .k-link.k-state-selected{
    color:#FFF !important;
    border-radius:5px;
    border-color:#00a3fe !important;
    background:#a3b2b9 !important;
    transition: all 0.25s ease;
}
.k-grid-header .k-header{
    color:#FFF !important;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

/*new form*/
.k-form .k-form-fieldset{
    background: #FFF;
    border: solid 1px #ccc !important;
    border-radius: 6px;
    padding: 34px 50px !important;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.k-form .k-form-legend, .k-form-inline .k-form-legend{
padding-bottom:14px;
font-weight: bold;
color:#666;
}

/*step container*/
.k-stepper{padding:8px 0;
    padding-left:80px;margin-top:-20px;
    background:#FFF;
    box-shadow: 0px 0px 4px 0px #d7d7d7;
    height:71px;
}
.k-stepper .k-step-current .k-step-label{color:#1e90ff;font-weight:600;}
.sv-action-bar-item--active .sv-action-bar-item__icon use {
    fill: var(--primary, #1e90ff) !important;
  }

.svc-btn .svc-text{color:#0088fa !important;}
.svc-btn{border:solid 1.5px #0481f7;line-height:normal !important;height:auto !important;outline-color: #d6dde3;}
.sd-title.sd-container-modern__title{border-color:#1e90ff !important;max-width: calc(84 * var(--base-unit, 8px));margin:auto;}

/*bar icons*/
.svc-toolbox__item-banner .sv-svg-icon use {
    fill: var(--primary, #20c6c6) !important;
  }

  .svc-btn:hover {
    box-shadow: 0 0 0 2px var(--primary, #20c6c6) !important;
  }

  .svc-btn:hover{box-shadow:none !important;}

  .svc-top-bar{
    color:#FFF;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

.svc-top-bar .svc-text{color:#FFF;}
/*.svc-tabbed-menu-item--selected .svc-text{color:#666;}*/
.svc-side-bar__container-header{
    box-shadow:none !important;padding:3px 2px !important;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

.svc-text--normal{font-size:13px !important;}

/*.main-content-survey-form{height: 100% !important;}*/

.svc-creator .svc-page .svc-question__content--selected:not(.svc-question__content--dragged), .svc-creator .svc-page .svc-hovered > .svc-question__content--selected:not(.svc-question__content--dragged) {
    box-shadow: 0 0 0 2px var(--secondary, #20c6c6) !important;
  }

  .svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected, .svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected, .svc-hovered.svc-page__content--selected {
    box-shadow: 0 0 0 2px var(--secondary, #20c6c6) !important;
    background: var(--secondary-back-light, rgba(20, 169, 255, 0.1)) !important;
  }

  .sv-action-bar-item--secondary .sv-action-bar-item__icon use {
    fill: var(--secondary, #20c6c6) !important;
  }

  .svc-page__content:not(.svc-page__content--new):focus, .svc-hovered.svc-page__content:not(.svc-page__content--new) {
    background: var(--secondary-back-light, rgba(30,144,255,0.1)) !important;
  }

  .k-form .k-label{margin-bottom:10px;}

  /*.svc-tabbed-menu-item--selected{box-shadow:none !important;}*/
  .sv-action-bar-item__icon use {
    fill: var(--foreground-light, #fff) !important;
  }

  #icon-undo .sv-action-bar-item .sv-action-bar-item__icon use, #icon-redo .sv-action-bar-item .sv-action-bar-item__icon use {
    opacity: 1 !important;
  }

  .sv-action-bar-item:hover, .sv-action-bar-item:focus-visible{background: none !important;}
  .sv-action-bar-item:hover, .sv-action-bar-item:focus{background: none !important;}
    .svc-side-bar__container-actions .sv-action-bar-item:hover{background-color: #168b8b !important;}

/*.bottomControls{overflow:hidden;text-align: center;position:fixed;bottom:0;z-index:2;width:100%;margin-bottom:75px;padding-left:190px;box-sizing: border-box;}*/
.btnControl{display:inline-block;padding:10px 14px;text-align: center;color:#FFF;background:#016ba7;border-radius:5px;margin:0 5px;cursor: pointer;}

body[data-layout="horizontal"] .footer {left: initial !important;}


/*.container-base-survey{height: calc(100vh - 300px) !important;}*/

.k-step-list-horizontal ~ .k-progressbar{height:5px;top:15px !important;}
.svc-tabbed-menu-item-container{border-right:solid 1px #FFF;border-color:rgba(255,255,255,0.6);}
.sv-action-bar-separator{background:rgba(255,255,255,0.6) !important;}
.sv-action-bar-item--active .sv-action-bar-item__icon use {fill: var(--primary, #FFFFFF) !important;  }
.svc-flex-column.svc-side-bar__wrapper{border-color:rgba(255,255,255,0.6);}

.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .sv-svg-icon use, .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
    fill: var(--primary, #1e90ff) !important;
  }

.svc-toolbox  .sv-action-bar-item__icon use {fill: var(--foreground-light, #90909f) !important;  }

.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus, .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover{border-radius:5px !important;}

.sv-button-group__item--selected{color: var(--primary, #20c6c6) !important;}

.spg-checkbox--checked .spg-checkbox__svg{fill: var(--primary, #1e90ff) !important;}
.svc-creator .svc-page .svc-hovered > .svc-question__content{box-shadow: 0 0 0 2px var(--secondary-light, rgba(30,144,255)) !important;}

.svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) .svc-string-editor__border{background-color: var(--primary-light, rgba(30,144,255));}
.svc-string-editor:focus-within .svc-string-editor__border{border:2px solid var(--primary, #20c6c6) !important;}

.sd-btn--action{background-color: var(--primary, #20c6c7) !important;border: 2px solid var(--primary, #20c6c7) !important;}
.sv-list__item--selected {background-color: var(--primary, #20c6c6) !important;}

.sd-input:focus {
    box-shadow: 0 0 0 2px var(--primary, #1e90ff),inset 0px 1px 2px var(--border-inside, rgba(0, 0, 0, 0.16)) !important;
  }

.sd-item--checked .sd-item__decorator {background: #20c6c6 !important;}
.sd-item__control:focus + .sd-item__decorator, .sd-item--allowhover:hover .sd-item__decorator {
box-shadow: 0 0 0 2px var(--primary, #20c6c6) !important;outline: none;}



/*.svc-tabbed-menu-item:hover{
    background-color: var(--background-dim, #0084f9) !important;
    box-shadow: inset 0px -1px 0px var(--border, #d6d6d6);
    outline: none;
    }*/

    /*.svc-tabbed-menu-item--selected:hover{background:#F3F3F3 !important;color:#666 !important;}*/

    .svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) .svc-string-editor__border{background-color: var(--primary-light, rgba(30,144,255,0.1)) !important;}

    /*.svc-side-bar__container-header{box-shadow:none !important;}*/

    .sidebarMenuMin{background:#FFF;height:100vh;}
    .navbar-header{padding:0 calc(24px / 4) 0 0;}

.spg-panel__title{font-size:calc(2 * var(--base-unit, 7px)) !important;}
.spg-question__title{font-size:calc(2 * var(--base-unit, 7px)) !important;}
.spg-checkbox__caption{font-size:calc(2 * var(--base-unit, 7px)) !important;}
/*.spg-input{font-size:calc(2 * var(--base-unit, 7px)) !important;}*/
/*.sv-button-group__item{font-size:calc(2 * var(--base-unit, 7px)) !important;}*/

/*.sd-header__text h3{color:#016ba7 !important;}*/
/*.sv-action-bar-item__title{color:#FFF !important;}*/
/*.spg-panel__content{width:calc(100% - 70px) !important;}*/
.svc-page-navigator-item--selected .svc-page-navigator-item__dot{border-color:#016ba7 !important;}
.svc-page-navigator-item-content:not(.svc-page-navigator-item--disabled) .svc-page-navigator-item__banner .svc-page-navigator-item__dot{background-color:#016ba7 !important;}
/*.spg-comment{font-size:calc(2 * var(--base-unit, 7px)) !important;}*/
.spg-input:focus {border-color:#0084f9 !important;}
.spg-comment:focus {border-color:#0084f9 !important;}

/*.container-base-survey{height:100vh !important;}*/
/*.footer-survey{position:fixed ;background:none !important;height:10px !important;}*/
/*.bottomControls{
    overflow:hidden;text-align: center;position:fixed;bottom:0;z-index:2;width:100%;padding-left:190px;box-sizing: border-box;
    margin-bottom:52px !important;}
*/
.justify-content-end{margin-right:12px !important;}

.btn-primary{
border:0 !important;
background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.font-size-16 {font-size: 13px !important;}

.k-grid tr.k-state-selected > td {background-color:rgb(0 155 243 / 25%);}
.contentBox{width: calc(100% - 70px);margin: auto;margin-left: 0px;}

.bottomControls{
    /*overflow:hidden;*/text-align: center;position:fixed;bottom:0;z-index:2;width:100%;box-sizing: border-box;
    left:50%;transform: translateX(-50%);
    padding-left:0 !important;margin-bottom: 37px !important;
}

.svc-side-bar__container-content{background:none !important;}
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order{color:#FFF;}

.btnControl{
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}


.chase-dot:before{
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );

}

.homeMenu{padding:10px 0px;border-radius:0px;border-bottom:solid 1px rgba(0, 0, 0, 0.08);color:#333;padding-left:25px;}
/*.shareContainer{text-align: center;}*/
.shareContent-publish{text-align: center;width:350px;color:#666;margin-bottom:20px;overflow:hidden;margin-left: auto;margin-right:auto;vertical-align:top;}
        .publicarEncuesta p{font-size:14px;margin-bottom:10px;}
        .cstTitle{padding:15px;/*border-bottom:solid 1px #E1E1E1;*/color:#000;font-size:14px;font-weight:bold;border:0;}
        .cstTitle2{font-size:14px;font-weight:bold;padding:10px 0px;display:block;margin:10px 0;margin-top:0;}
        .btnPublish{color:#FFF;padding:16px 18px;border-radius: 5px;display: inline-block;width:174px;cursor: pointer;font-weight: bold;margin-top:20px;
        background: #00a3fe;
        background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
        background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
        background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
        }
        .imagePublish{margin:10px auto;display:block;}
        .dateSelect{padding:20px;margin:10px;border:solid 1px #ccc;border-radius: 5px;}
        .cstLink{color:#168b8b !important;font-weight:bold;box-shadow:0px 0px 5px rgb(0 0 0 / 15%);border:solid 2px transparent;padding:10px 20px;border-radius:5px;display:inline-block;margin-bottom:20px;}
        .cstLink:hover{border: solid 2px #20c6c6;}
        .publicLink{background:#FFF;padding:10px 16px;border-radius:5px;display:block;overflow:hidden;box-sizing: border-box;color:#000;font-weight:bold;text-align:center;margin:20px auto;text-decoration: underline;overflow-wrap: break-word;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
        border: solid 2px transparent;
        max-width:400px;
        }
        .publicLink:hover{text-decoration:underline;color:#000;}

@media only screen and (max-width:960px){
.contentPageLeft{padding:0;width:85px;}
.toolList{margin-left:14px;margin-right:14px;}
.main-content-survey{width: calc(100% - 35px);padding:0px 2px;margin-left:20px !important;}
.container-fluid{padding:0 !important;}
.main-content-survey .col-12{padding:0;}
}

.sidebarMenuMin{
    padding: 0;
    /*width: 85px;*/
    /*font-size: 0px;*/
}
/*.sidebarMenuMin .toolList {
    margin-left: 14px;
    margin-right: 14px;
}*/
/*.main-content-survey-form{
    height: 100% !important;
    overflow: auto;
    top: 86px;
    margin-left:190px;
}*/
.container-base-survey{height: calc(100vh - 300px) !important;}
.footer-survey{
    position:fixed ;
    background:none !important;
    height:10px !important;
    width:100%;
}

/*theme*/
.titleTheme{margin-bottom:20px;font-size:14px;border-bottom:solid 1px #DFDFDF;padding:9px 0;padding-bottom:20px;color:#495057;position: relative;text-transform: uppercase;font-weight:600;}
.titleTheme span{color:#999;margin-left:10px;}
.theme-1{
    background: #6db3f2;
background: -moz-linear-gradient(top,  #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
background: -webkit-linear-gradient(top,  #6db3f2 0%,#54a3ee 50%,#3690f0 51%,#1e69de 100%);
background: linear-gradient(to bottom,  #6db3f2 0%,#54a3ee 50%,#3690f0 51%,#1e69de 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6db3f2', endColorstr='#1e69de',GradientType=0 );

    color:#FFF;padding:10px 14px;border-radius:5px;}
.theme-2{
   background: #606c88;
background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%);
background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%);
background: linear-gradient(to bottom,  #606c88 0%,#3f4c6b 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 );

    color:#FFF;padding:10px 14px;border-radius:5px;}
.theme-3{
   background: #36c595;
background: -moz-linear-gradient(top,  #36c595 0%, #4ec531 100%, #cbebff 100%, #f0f9ff 100%);
background: -webkit-linear-gradient(top,  #36c595 0%,#4ec531 100%,#cbebff 100%,#f0f9ff 100%);
background: linear-gradient(to bottom,  #36c595 0%,#4ec531 100%,#cbebff 100%,#f0f9ff 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36c595', endColorstr='#f0f9ff',GradientType=0 );


    color:#FFF;padding:10px 14px;border-radius:5px;}
.theme-4{
  background: #b8c6df;
background: -moz-linear-gradient(top,  #b8c6df 0%, #6d88b7 100%);
background: -webkit-linear-gradient(top,  #b8c6df 0%,#6d88b7 100%);
background: linear-gradient(to bottom,  #b8c6df 0%,#6d88b7 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b8c6df', endColorstr='#6d88b7',GradientType=0 );


    color:#FFF;padding:10px 14px;border-radius:5px;}
.theme-5{
    background: #45484d;
background: -moz-linear-gradient(top,  #45484d 0%, #000000 100%);
background: -webkit-linear-gradient(top,  #45484d 0%,#000000 100%);
background: linear-gradient(to bottom,  #45484d 0%,#000000 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 );

    color:#FFF;padding:10px 14px;border-radius:5px;}
.addNewTheme{border:2px dotted #ccc;padding:40px 50px;background:url(../images/general/ic_addTheme.png) no-repeat center right 40px;font-size:14px;font-weight:500;cursor: pointer;background-size:40px;}
.editTheme{border:2px dotted #ccc;padding:40px 50px;background:url(../images/general/ic_editIcon.png) no-repeat center right 40px;font-size:14px;font-weight:500;cursor: pointer;background-size:40px;}
.chooseTheme{list-style-type:none;padding:0;margin:0;overflow:hidden;}
.chooseTheme li{float:left;width:calc(50% - 20px);margin-right:10px;}
.imageTheme{text-align: center;margin-bottom:40px;padding: 20px;}

.control-group{display: inline-block;margin-top:15px;margin-bottom:30px;}
.control {font-family: arial;display: block;position: relative; padding-left: 30px;margin-bottom: 5px;padding-top: 3px;cursor: pointer;font-size:12px;float:left;margin-right: 15px;}
.control input {position: absolute;z-index: -1;opacity: 0;}
.control_indicator {position: absolute;top:0px;left: 0;height: 20px;width: 20px;background: #e6e6e6;border: 0px solid #000000;border-radius: undefinedpx;}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {background: #cccccc;}
.control input:checked ~ .control_indicator {background: #1e90ff;}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {background:#1e90ff;}
.control input:disabled ~ .control_indicator {background: #1e90ff;opacity: 0.6;pointer-events: none;}
.control_indicator:after { box-sizing: unset;content: '';position: absolute;display: none;}
.control input:checked ~ .control_indicator:after {display: block;}
.control-radio .control_indicator {border-radius: 50%;}
.control-radio .control_indicator:after {left: 7px;top: 7px;height: 6px;width: 6px;border-radius: 50%;background: #ffffff;transition: background 250ms;}
.control-radio input:disabled ~ .control_indicator:after {background: #7b7b7b;}
.control-radio .control_indicator::before {content: '';display: block;position: absolute;left: 0;top: 0;width: 4.5rem;height: 4.5rem;margin-left: -1.3rem; margin-top: -1.3rem;
background: #2aa1c0;border-radius: 3rem;opacity: 0.6;z-index: 99999;transform: scale(0);}

/*.main-content-survey-form > div{
    margin-top: 0px !important;
}
*/

.svd-simulator-frame .sd-header__text h3{word-break: initial !important;}

.headerFixed .container-base-survey{height:calc(100vh - 153px) !important;}
@media only screen and (max-width:390px){
.sd-progress-buttons__list li{width:50%;}
.k-form{width:100% !important;}
.k-form .k-form-fieldset{padding:34px 30px !important;}
.container-base-survey{width:100% !important;}
.header-item{display:none;}
.svc-toolbox{display:none;}
}

.code-qr-form{
    text-align: center;
}
.margin-button-init{
    margin-bottom: 20px;
}
.k-dialog-titlebar{
    background: linear-gradient(to bottom, #00a3fe 0%,#0084f9 100%);
}
.client-survey{
    background-color: #fff;
    /*height: 100vh;*/
    overflow: auto;
}
/*.sv_main input[type="button"], .sv_main button{
    background: linear-gradient(to bottom, #00a3fe 0%,#0084f9 100%);
}
.sv_main .sv_body{
    background-color: white !important;
    border-top: 2px solid #00a3fe !important;
}
.sv_main .sv_q_text_root:focus{
    border: 1px solid #00a3fe !important;
}*/

.btn {
    display: inline-block !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #fff !important;
    text-align: center !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    -webkit-user-select: none;
    user-select: none !important;
    background-color: transparent !important;
    padding: 0.47rem 0.75rem !important;
    font-size: 0.8125rem !important;
    border-radius: 0.25rem !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.more-menu-formulario button{
    background-color: transparent !important;
    background-image: none !important;
    border-style: none;
}

.grid_formularios{max-height:calc(100vh - 210px);min-height: calc(100vh - 210px);}
.grid_formularios_detalle{max-height:calc(100vh - 205px);min-height: calc(100vh - 170px);padding:10px;box-sizing:border-box;}
.k-pager-numbers .k-link {color:#1e90ff;}
.k-pager-numbers .k-link:hover{
    background-color:#c1c1c1 !important;
    color:#FFF !important;
    }
/*.k-command-cell .k-button:hover, .k-button:active{
 background: #1e90ff !important;
 color: white !important;
 }*/

 .sv_next_btn{padding:7px !important;float:none !important;}
 .sv_prev_btn{padding:7px !important;float:none !important;}
 .sv_complete_btn{padding:7px !important;float:none !important;}
 .sv_main .sv_container .sv_body .sv_nav{text-align:center;}
 .sv_progress-buttons__list{padding:0 !important;}
 /*.btn-light {color: #000 !important;background-color: #eff2f7 !important;border-color: #eff2f7 !important;}*/
 .btn-light {color:#FFF !important;background-color:#81dada !important;border:solid 2px #81dada !important;}
 .btn-light:hover{background: #168b8b !important;transition: all 0.35s ease;color: #FFF !important;}

 .svc-tab-designer--with-page-navigator{padding-right:0 !important;}
 /*.svc-page__content--selected .sv-action-bar-item__title{color:#333 !important;}*/


@media (max-width: 700px){
.sv_main .sv_container .sv_header h3 span {word-break: initial;}
.sv_main .sv_header__text{max-width:initial !important;}
.sv_main .sv_logo{margin:0 !important;text-align: center;}
.sv_logo__image{max-width: 50%;}
.sv_next_btn{width:100%;box-sizing: border-box;}
.sv_prev_btn{width:100%;box-sizing: border-box;}
.sv_complete_btn{width:100%;box-sizing: border-box;}
.sv_progress-buttons__list{padding:0 !important;}
}


@media (max-height:700px) {
.main-content-survey{overflow-y:auto;}
}

@media only screen and (max-width: 960px){
.container {width: auto;margin: auto;}
.sv_main .sv-logo--right{width:100%;text-align: center;}
}

@media only screen and (max-width: 960px){
.svc-tab-designer .sd-root-modern{display:block !important;}
.footer-survey {width: 100%;box-sizing: border-box;}

}
@media only screen and (max-width: 820px){
.main-content-survey-form{margin-left:100px !important;}
.svc-tab-designer{display:block !important;}
}

.contentPageLeft{
width:200px;float:left;box-sizing: border-box;
position: fixed;
background: #FFF;
z-index: 123456789;
margin-top:0px !important;
box-shadow: 0px 0px 4px 0px #d7d7d7;
overflow: auto;
height:calc(100% - 60px);
}
.main-content-survey-form{
    height: 100% !important;
    /*overflow: auto;*/
    top: 86px;
    margin-left:0 !important;}
/*.container-base-survey {height: calc(100vh - 200px) !important;}*/
.footer{padding:12px !important;height:auto !important;/*width: calc(100% - 266px);*/text-align:center;width:100%;background:none;box-sizing: border-box;}
.footer .col-md-6{width:100%;}
/*.bottomControls{padding-left:0 !important;margin-bottom: 37px !important;}*/
/*.footer-survey{width:100%;}*/
/*.sv-popup__container{height:50% !important; bottom: 0 !important;    top: 50% !important;    position: absolute !important;    transform: translate(-50%, -50%);}*/
/*.sv-list__input{display:none !important;}
.sv-list__filter-icon{display:none !important;}*/
.svc-notifier{display:none !important;}
.svc-tab-designer .sv-popup__container{
height:50% !important; bottom: 0 !important;    top: 50% !important;    position: absolute !important;
transform: translate(27px, -174px) !important;
}
.sidebarMenuMin .toolList {/*margin-left: 10px;margin-right: 5px;*/text-align: center;}
.topMenu{position: absolute;text-align: center;cursor: pointer;z-index:5;}
.topMenu img{filter: invert(71%) sepia(42%) saturate(12%) hue-rotate(6deg) brightness(40%) contrast(8%);width:31px;height:33px;vertical-align: middle;padding:20px 20px;padding-bottom:18px;}
/*.k-stepper{margin-left:103px;margin-top:-20px;}*/
/*.sd-header__text h3{font-size:14px !important;line-height: 18px !important;color:#016ba7 !important;}
.sd-header__text h5{font-size:14px !important;}
.sd-page__title{font-size:14px !important;}
.sd-page__description{font-size:14px !important;}
.sd-element__title span {font-size: 14px !important;display: block;margin-left: 0;width: auto;padding-left: 0;}*/
.svc-page .sd-page__title{margin:0 !important;}
.svc-tabbed-menu{height:45px !important;}
.svc-tabbed-menu-item{line-height: 46px !important;}
/*.svc-side-bar__container-header{box-shadow:none !important;padding:2px !important;}*/
.svc-toolbar-wrapper .sv-action-bar-item{font-size:14px !important;color:#FFF !important;
filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
-webkit-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
-moz-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
}

.svc-side-bar .sv-action-bar-item{font-size:14px !important;color:#FFF !important;
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
    -webkit-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
    -moz-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(43deg) brightness(111%) contrast(100%);
}

.sv-action-bar-item.sv-action-bar-item--pressed{filter:none !important;color:initial !important;}
/*.sv-popup__container {transform: translate(27px, -174px) !important;}*/
.sv-list{font-size:13px !important;}
/*.spg-comment{font-size:14px !important;}*/
.svc-question__content{padding:10px !important;}
.sd-input{font-size:13px !important;}
.sd-question__header--location-top{padding-bottom:5px !important;}
.sv-list__input{font-size:13px !important;height:35px !important;}
.sv-list__filter-icon{top:5px !important;}
.svc-logo-image-placeholder{font-size:20px !important;}
/*.svc-logic-question-value, div:only-child>.sd-element--with-frame:not(.sd-element--collapsed){padding:15px !important;}*/
.svc-test-tab__content-actions .sv-action-bar{background: none !important;color:#000;border:0 !important;}
#languageSelector .sv-action-bar-item__title{color:#FFF !important;}
.sv-action-bar-item__title{/*color:#000 !important;*/font-weight:500 !important;font-size:13px !important;}
.sv-list__input:focus {border-color:#1e90ff  !important;}
.svc-footer-bar .svc-toolbar-wrapper .sv-action-bar{background: none !important;border:0 !important;}
.svc-footer-bar .sv-action-bar-item{filter: brightness(0.5);}
.svc-side-bar__container{width:190px !important;}
.spg-input {height: 35px !important;padding: 10px !important;font-size:13px !important;}
.sv-button-group__item{height: 35px !important;padding: 10px !important;font-size:13px !important;}
.spg-comment{font-size:13px !important;padding: 10px !important;}
.spg-table__cell{vertical-align:middle;}
.spg-panel__content .sv-action-bar-item{filter:brightness(0.5);}
.backgroundActive{background:#FFF;/*box-shadow: 0px 0px 4px 0px #d7d7d7;*/}
.sd-btn{/*color:#000 !important;*/border-color:#1e90ff !important;font-size:13px !important;}
.sd-navigation__complete-btn{color:#FFF !important;padding:10px 24px !important;}
.sd-body__navigation{text-align:center;}
.showLeftControls .main-content-survey{margin-left:215px !important;transition: all 0.2s ease-in;width:calc(100% - 215px) !important;}
/*.svc-test-tab__content-actions .sv-action-bar{background: none !important;}*/
@media only screen and (max-width: 820px){
.svc-tab-designer .sd-container-modern.sd-container-modern--static{min-width:100%;}
.sv-action-bar-item__title {/*color: #000 !important;*/font-weight: 500 !important;}
}
@media only screen and (min-width: 820px){
.sv-action-bar-item__title {color: #FFF !important;}
.svc-question__content-actions .sv-action-bar-item__title{color:#000 !important;}
}
.k-grid-column-menu{color:#fff !important;}
.k-grid-header .k-grid-filter:hover, .k-grid-header .k-header-column-menu:hover{background: #1e90ff !important;}

/*.margin-left-image{margin-left: 6px;margin-right: 8px;}*/
/*.headerFixed .contentPageLeft{top:72px;}*/
/*.headerFixed .navbar-header{display: none;}*/
@media only screen and (max-width: 400px){
    .shareContent-publish{width:100%;/*height: calc(100vh - 210px);*/overflow: auto;box-sizing: border-box;margin-right:0;}
}

@media only screen and (max-width: 960px){
.homeMenu{text-align:center;}
.primaryMenu{background-size:8px;background-position:right 15px;}
.contentPageLeft{width:70px !important;font-size:0 !important;}
.contentPageLeft .toolList{margin-right:0px !important;margin-left:0px !important;}
.container-fluid .row{margin:0 !important;}
.page-title-box h4{float:none !important;overflow: hidden;margin-bottom: 10px !important;}
.page-title-right{float:none !important;}
.footer{width:100%;}
.flex-row {flex-direction: column !important;}
.justify-content-end{justify-content: left !important;}
.d-none{display:inline-block !important;}
#page-header-search-dropdown{display:none !important;}
.contentBox{width: calc(100% - 115px);/*margin-left: 92px;*/box-sizing: border-box;height: calc(100vh - 188px);overflow: auto;}
.contentBoxTheme{padding:15px;}
.imageTheme{margin-bottom:10px;}
.imageTheme img{width:100%;max-width:446px;}
.control{margin-bottom:36px;}
.titleTheme{font-weight: bold;}
.control-group{margin-bottom:15px;}
.editTheme{background-size:40px;padding: 26px 16px;padding-right:30%;background-position:center right 10px;}
.addNewTheme{background-size:40px;padding: 26px 16px;padding-right:30%;background-position:center right 10px;}
.contentBox-publicar .k-form{width: auto !important;max-width: inherit !important;margin-left: 0 !important;}
.svc-designer__placeholder-container {position: absolute;top: 35%;left: 50%;transform: translateX(-50%);}
/*.k-form{width:100% !important;margin-left:0px;height:auto;overflow: auto;box-sizing: border-box;}*/
.sv-popup__button--cancel{display:none;}
.sv-popup--overlay .sv-popup__container{box-shadow: none !important;}
.k-label{display:block !important;}
.k-window{width:auto !important;left: 50% !important;transform: translateX(-50%);height: calc(100% - 150px) !important;max-height: 600px;box-shadow:0 0 35px 0px rgba(0,0,0,0.2) !important;top:10px !important;}
.headerFixed .contentBox-publicar{height:calc(100vh - 188px);overflow:auto;margin-top:10px !important;}
.bottomControls{box-shadow: 1px -6px 6px 0px rgba(143, 143, 143, 0.1);}
.btnControl{margin-top:10px;}
.resizeIcon{padding:0;}
.formIcon{margin-left:0 !important;}
.main-content-survey{margin-left:15px !important;width:calc(100% - 40px) !important;}
.showLeftControls .main-content-survey{margin-left:85px !important;width:calc(100% - 105px) !important;}
.contentPageLeft .toolList li span{position:relative;}
.contentPageLeft .sidebarMenu ul{margin-left:0 !important;}
.contentPageLeft .dropdown-item{padding: 6px 0 !important;box-sizing: border-box !important;text-align: center;}
.contentPageLeft .homeMenu{padding-left:8px !important;}
.contentPageLeft .primaryMenu{padding-left:16px !important;}
.contentPageLeft .sidebarIcon{width:16px;}
}

.k-window{transform:translateX(-50%);left:calc(50% + 100px) !important;box-shadow:0 0 35px 0px rgba(0,0,0,0.2) !important;height:auto;top: 100px !important; max-height: calc(100% - 130px);z-index: 123456789 !important; max-width: calc(100% - 40px);}
#pageSelector .sv-action-bar-item__title {/*color: #000 !important;*/font-weight: 500 !important;}
.accesoDenegado{text-align: center;}
.accesoDenegado .txtDefaultContainer{float:none;width:auto;text-align: center;}
.accesoDenegado img{float:none;width:125px;}
.accesoDenegado .btnForm{width:150px;margin:auto;}
.accesoDenegado .txtDefault span{display: inline-block;}
.sd-item__control-label{font-size:14px !important;}
.k-input-md .k-input-button{/*background: #1e90ff !important;color: white !important;*/}
.sv-ranking-item__index{width:15px !important;height:15px !important;}
.k-dialog-wrapper .k-dialog{position: absolute !important; top: 10px;height:auto !important;/*height: calc(100% - 120px) !important;*/max-height: 710px;width:500px;}
/*.shareContainer{width:300px;}*/
.shareContainer p{padding:10px;margin-bottom:0;}
.k-input.k-invalid{border-color:#ced4da;}
.form-control:focus{box-shadow: 0 0 0 0.25rem rgb(32 198 198 / 5%);border-color:#b8d1d1;}


@media only screen and (max-width:630px){
.imageDefault{width:300px;text-align: center;top:0;transform:translate(-50%, 0);margin-top:20px;}
.txtDefaultContainer{width:100%;float:none;text-align: center;}
.accesoDenegado .txtDefault{font-size:24px;}
.accesoDenegado img{width:100px;}
}

.navbar-header .dropdown .dropdown-menu{left:inherit !important;right: 0px !important;}
.k-window-content .text-muted{/*height:20px;*/border-radius:0;overflow: hidden;}

.auto-window .k-dialog{height: auto !important;}

@media (max-width: 770px){
    .k-pager-info {display: none !important;}
    .k-pager-sizes{display: none !important;}
}
/*.sd-question--table > .sd-question__content {margin: 0px !important;width: 100% !important;min-width: 0px !important;}*/

/*theme*/
.createTheme{
    text-transform: none;
    float:right;
    background: #00a3fe;
    border: none;
    margin-right: 2px;
    margin-left: 2px;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
    color:#FFF;font-size:13px;padding:6px 14px;border-radius:5px;
        margin-top:-5px;
        cursor: pointer;
    }
    .themeList2{list-style-type: none;padding:0;margin:0;overflow:hidden;background:#FFF;border-radius:5px;}
    .themeList2 ul{padding:0px;}
    .themeList2 li{overflow: hidden;border:solid 1px transparent;padding:12px 15px;border-radius:5px;margin-bottom:10px;background:#FFF;}
    .themeList2 li:hover{background:#F9F9F9;}
    .themeList2 li:last-child{border-bottom:0;}
    .activeTheme {background: #e7f6ff !important;border:solid 1px #54a3ee !important;box-sizing: border-box;}
    .imageThemeList{float:left;margin-right:20px;}
    .txtEditTheme{padding:0;font-size:13px;color:#666;}
    .txtEditTheme a{color:#666;}
    .txtEditTheme a:hover{text-decoration:underline !important;}

    .styleContentPageRight{height:100%;overflow: auto;}
    .styleDiv{width:200px;overflow:hidden;float:left;margin-right:20px;}
    .styleDivContentEncabezado{overflow: hidden;}
    .btnThemeCancel{background: #FFF;color:#666;}

    .themeList{list-style-type:none;padding:0;margin:0;overflow:hidden;margin-top:20px;}

    .overflow--auto{overflow: auto;}
.themeList hr{
border: 0;
height: 0;
border-top: 1px solid rgba(0, 0, 0, 0.1);
border-bottom: 1px solid rgba(255, 255, 255, 0.3);}
.themeList li{display: block;color:#666;border-radius:6px;margin-bottom:10px;font-weight:bold;}
.themeList li span{display:block;border:solid 1px #CCC;background: #FFF;border-radius:5px;padding: 5px;margin-top:10px;font-weight: normal;}
.themeList input[type="color"]{width:32px;border:0;vertical-align:middle;margin-right:5px;}
.themeList li img{vertical-align: middle;}

.contentBoxTheme{padding:30px;/*background: #FFF;*/margin-bottom:20px;/*border-radius: 5px;*/box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);max-width:890px;margin-left:auto;margin-right:auto;}
.titleTheme1{margin-bottom:20px;font-size:14px;border-bottom:solid 1px #E1E1E1;padding-bottom:19px;color:#495057;font-weight:600;text-transform:uppercase;position:relative;}
.titleTheme1 span{color:#999;margin-left:10px;}
.divContentListTheme{width:200px;overflow:hidden;float:left;margin-right:20px;}
.contentBoxTheme .contentEncabezado{background:#F9F9F9;padding:20px;}

.themeListTheme{list-style-type:none;padding:0;margin:0;overflow:hidden;margin-top:20px;color: #000;}
.themeListTheme hr{
border: 0;
height: 0;
/*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
border-bottom: 1px solid rgba(255, 255, 255, 0.3);}
.themeListTheme li{display: block;border-radius:6px;margin-bottom:10px;font-weight:bold;}
.themeListTheme li span{display:block;border:solid 1px #CCC;background: #FFF;border-radius:5px;padding: 0px;margin-top:10px;font-weight: normal;}
.themeListTheme input[type="color"]{width:32px;border:0;vertical-align:middle;margin-right:5px;}
.themeListTheme li img{vertical-align: middle;}

.textTools li{display:inline-block;padding:5px 6px;background: #FFF;border:solid 1px #CCC;cursor:pointer;}
.textTools li div{cursor:pointer;}
.textTools li img{width:12px;}
.overFlowHidden{
    overflow: hidden;
}
.encabezado{
    border: dotted 2px #CCC;
    font-size: 14px;
    text-align: center;
    color: #000;
    border-radius:5px;
}
.contentBoxTheme .encabezado{border:0;}
.contentEncabezado{margin-bottom:20px;max-width:740px;margin-left:auto;margin-right:auto;border-radius:5px;}

.contentBoxTheme{padding:30px;background: #FFF;margin-bottom:20px;/*border-radius: 5px;*/box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);max-width:820px;margin-left:auto;margin-right:auto;}
.radioGroupTheme{margin-top:30px;}
.radioGroupTheme div{margin-bottom:16px;font-size:1.2em;padding-right: 2px;}
.radioGroupTheme div:last-child{margin-bottom:1px;}
.textAreaTheme{background:#f9f9f9;border:0;border:solid 1px #eee;border-top:solid 1px #DFDFDF;font-size:1em;padding:14px;border-radius:2px;width:100%;min-height:150px; box-sizing: border-box;}
.inputBoxTheme{background:#f9f9f9;border:0;border:solid 1px #eee;border-top:solid 1px #DFDFDF;font-size:1em;padding:14px;border-radius:2px;width:100%;box-sizing: border-box;}

.themeListTheme input[type="color"] {
    width: 32px;
    border: 0;
    vertical-align: middle;
    margin-right: 5px;
  }
:root{

    --color-tex-cont: black;
    --background: #FFF;
    --background-cel: #ffffff;
    --color-title: black;
    --color-border-cel: black;
    --background-cel-relleno:#FFF;
    --background-btn:#FFF;
    --background-btn-hover: black;
    --color-text-btn: black;
}

.radioColor{
    color: var(--color-tex-cont);
}
.inputBox{
    color:var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}
.textAreaTheme{
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.contentBoxTheme{
    background-color: white !important;
}
.contentBoxTheme1{
    padding:30px;
    background: #FFF;
    margin-bottom:20px;/*border-radius: 5px;*/
    box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);
    max-width:820px;
    margin-left:auto;
    margin-right:auto;
    background-color: var(--background-cel);
    border-width: 3px;
    border-style: solid;
    border-color: var(--color-border-cel);
}

.cont-btn-enviar{
    text-align:right;
}
/*.sv_enviar_btn{
    transition-duration: 0.2s;
    float:none !important;
    width:15%;
    box-sizing: border-box;
    padding: 9px !important;
    font-weight: bold;
    line-height: 2em;
    border: none;
    min-width: 100px;
    cursor: pointer;
    border-radius: 2px;
    color: var(--color-text-btn);
    background-color: var(--background-btn);
}
.sv_enviar_btn:hover{
    background-color: var(--background-btn-hover);
}*/
.titleTema{
    /*color: #00a3fe; */
    color:#495057;
    border: 0;
    background: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}
.contentBoxEnc{
    height: 62vh;
}
.imageDefaultEnc{

    width: 250;
    height: 150px;
    text-align:center;position: absolute;top:50%;left:52%;transform: translate(-50%, -50%);
    margin-top: 80px;
    margin-left: 180px;
}

.combo-regla{
    width: 150px !important;
    height:38px;
}

.reglas-backgroud-trans{
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    right: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.reglas-backgroud{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.jodit-status-bar-link{display: none !important;}
.positionrelative{position: relative;}
.modal-reglas{/*max-width: 300px;*//*float: right;*/}
.reglas-container{position: absolute;z-index: 2;left: 50%;transform: translate(-50%);}
.btnThemeCancel{background: #FFF;color:#666;}
.tabSettings{position: relative;}
.tabTitle{font-size: 1.4em;margin-bottom:20px;}
.tabList{list-style-type:none;padding:0;margin:0;font-size:14px;padding-bottom:20px;margin-bottom:20px;border-bottom:solid 1px #DFDFDF;}
.tabList li{display:inline-block;margin-right: 10px;color:#666;border-right: solid 1px #DFDFDF;padding-right:10px;}
.tabList li a{color:#666;text-decoration:none;}
.tabList li a:hover{color:#000;}
.tabList li:last-child{border:0;}
.selectedTab{color:#495057 !important;font-weight:600;}
.ruleTab{overflow: hidden;position:relative;font-size:1.2em;border:solid 1px #54a3ee;border-radius:5px;margin-bottom: 10px;background:#FFF;}
.controlsRule{list-style-type: none;padding:0;margin:0;overflow:hidden;position:absolute;right:0;top:50%;transform:translateY(-50%);}
.controlsRule li{float:left;cursor:pointer;margin-right:5px;border-radius:5px;transform: translate(0, 0);padding:4px 0;}
.controlsRule li:hover{background-color:#20c6c6 !important;transition: all 0.35s ease;}
/*.controlsRule li img{filter: brightness(5);}*/
.ruleHeader{position:relative;padding:12px 15px;background:#e7f6ff;}
.ruleContent{padding:10px 0;height: 320px;overflow-y: auto;}
.ruleContentView{padding:10px;height: auto;overflow-y: auto;border-top: solid 1px #20c6c6 !important;}
.ifCondition span input{border-radius:5px;}

.ifCondition{color:#7b7b7b;padding:8px;/*margin-top:8px;*/}
.ifCondition span{color:#000;/*vertical-align:top;*/border-radius:5px;}
.k-combobox{margin-right:5px;}
.if{padding:12px 2px;background:#5e7182;color:#FFF;display:inline-block;width:71px;text-align: center;border-radius:5px;float:left;margin:0px 0;margin-right:10px;font-weight:600;}
.ifRuleBg{background:#FFF;padding:10px 14px;border:solid 1px #FBE9C9;border-radius:5px;margin-top:10px;margin-bottom:10px;margin-left:83px;}
.ifRuleBg span{color:#ff9233;}
.ifContainer{overflow:hidden;}
.then{padding:12px 2px;background:#5e7182;color:#FFF;display:inline-block;width:71px;text-align: center;border-radius:5px;float:left;margin-right:10px;font-size:13px;font-weight:500;}
.thenCondition{color:#7b7b7b;padding:8px;}
.thenCondition span{color:#000;}
.thenRuleBg{background:#FFF;padding:10px 14px;border:solid 1px #CCC;border-radius:5px;margin-top:10px;margin-bottom:10px;margin-left:83px;}
.thenRuleBg span{color:#ff9233;}
.thenContainer{overflow:hidden;margin-top:5px;}
.addRule{font-size:13px;border-radius:5px;padding:10px 14px;color:#FFF;position:absolute;right:0;margin-top:-10px;cursor:pointer;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.ifCondition select{padding:6px 10px;border-radius:5px;border:solid 1px #DFDFDF;}
/*.ifCondition input[type="text"]{padding:7px 10px;border-radius:5px;border:solid 1px #DFDFDF;}*/
.addIc{float:left;cursor:pointer;border:solid 1px #DFDfDF;border-radius:5px;padding:18px;margin-right:3px;margin-left: 5px;height: 18px;overflow:hidden;position:relative;}
.addIc img{position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);}
.removeIc{float:left;cursor:pointer;border:solid 1px #DFDfDF;border-radius:5px;padding:18px;margin-left: 5px;height: 18px;overflow: hidden;position:relative;}
.removeIc img{position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);}
.ruleControls{display:inline-block;vertical-align:top;}
.size-m{width:187px;}
.textareaRule{width:100%;padding:12px;box-sizing:border-box;border:solid 1px #DFDFDF;border-radius:5px;min-height:90px;font-family: 'Ubuntu', sans-serif;background:#f9f9f9;}
.radioContainer{overflow:hidden;margin-bottom:20px;font-size:1.2em;}
.checkContainer{overflow:hidden;margin:10px 0;margin-bottom:20px;color:#7b7b7b;}
.checkContainer input[type="checkbox"]{margin-right:8px;}
.labelSplash{font-size:1.2em;padding:20px 0;}
.inputFullwidth{width:100%;padding:14px;box-sizing:border-box;border:solid 1px #DFDFDF;border-radius:5px;margin-bottom:20px;background: #f9f9f9;}
.popUpContainer{position:absolute;top:50%;background: #FFF;width:738px;left:50%;top:50%;transform: translate(-50%, -50%);z-index:2;border-radius:10px;}
.popUpBg{position:fixed;background:rgba(0,0,0,0.6);width:100%;height:100%;z-index:25;top:0;left:0;}
.popContainer{padding:10px 20px;}
.popContainer hr{height: 1px;background-color:#DFDFDF;border: none;}

.ruleTxt{font-size:14px;margin-bottom:11px;font-weight:500;}
.btnCancel{
background:#168b8b;border:solid 1px #168b8b;
color:#333;font-size:13px;padding:10px;border-radius:5px;text-align:center;
cursor: pointer;
}
.btnCancel:hover{background:#168b8b !important;}
.btnAdd{
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
color:#FFF;font-size:13px;padding:8px 10px;border-radius:5px;text-align:center;min-width: 90px;
cursor: pointer;
}
.btnPopup{overflow:hidden;border-top:solid 1px #DFDFDF;padding:10px 0px;}
.btnPopup ul{list-style-type:none;padding:0;margin:0;overflow:hidden;float:right;}
.btnPopup li{display:inline-block;margin:0 2px;}

.header{display:none;}

@media only screen and (max-width:738px){
.popUpContainer{width:90%;}
.contentBox{width:90%;box-sizing: border-box;}
.ifCondition select{width:100%;margin-bottom:6px;}
/*.ifCondition input[type="text"]{width:100%;box-sizing: border-box;}*/
.responsiveContent{width:85px !important;float:left;margin-right:10px;}
.ruleControls{margin-top:6px;}
.tabList{font-size:14px;}
/*.addRule{bottom: 0;position: fixed;margin: 20px;z-index:5;}*/
.reglas-container .contentBox{height:auto !important;}
.contentSidebarRight .contentRight{
    position: absolute;
    right: 0px;
    top: 187px;
    display: block !important;
}
}

@media only screen and (max-width:738px){
.k-stepper .k-step-label .k-step-text{font-size:10px;}
}


@keyframes s-ripple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0.01;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
    0% {
    transform: scale(0);
    }
    30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-radio input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}

/*responsive*/



@media only screen and (min-width:961px) and (max-width:1305px){
    .rightList{display:none;}
    .mobileOptions{display:block;}
}


@media only screen and (max-width:960px){
.main{margin-top:20px;}
.container{width: auto;margin:0 20px;}
.left-content{float:none;width:auto;height: auto;padding: 20px 30px;}
.txtcontent{margin:30px 0;}
.right-content{float:none;width:auto;height:300px;background-size: 75%;}
.left-content h1{margin:20px 0;}
.btn-login{margin-bottom:20px;}
.stepContainer{width:100%;}
.contentPageLeft{width:60px;font-size:0;}
.contentPageRight{width:calc(100% - 60px);padding:30px 2px;}
.titleBox{font-size:100%;}
.radioGroup div{font-size:100%;}
.radioGroup{margin-top:15px;}
.contentBox{padding:15px;}
.header li{padding:0 22px !important;}
.header li span:nth-child(2n) {display:none;}
.header li span{margin-right:0;}
.tools{width:auto;}
.toolList{margin-left:5px;}
.rightList{float:none !important;display:block;}
.header li:last-child{border-right:solid 1px #FFF;}
.step{padding:0 10px;}
.contentData{width:auto;margin:20px;margin-top:0;padding:34px;}
.contentData label{width:100%;}
.contentData div span{width:100%;}
}

.combiocond{width: 150px;height: 38px;}
.ifCondition-margin{margin-left: 77px;margin-top:10px;}
.heigth-controles{height: 38px;}
.input-accion-click{
    padding: 7px 10px;
    border-radius: 5px;
    border: solid 1px #DFDFDF;
    margin-left: 5px;
    height:38px;
    vertical-align: middle;
    width:220px;
}
.margin-reglas input{margin-bottom: 5px;}
.margin-reglas span{margin-bottom: 5px;margin-top:5px;}
.jodit-react-container{margin-bottom: 5px;background:#FFF;}
.regla-nombre{height: 40px;}
.poppup-survey{width: 100%;height: 100%;position: absolute; z-index: 10; display: none;}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
}

.modal.is-visible {
    visibility: visible;
    opacity: 1;
}

.modal-dialog {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: default;
    width: 300px;
    height: 300px;
    z-index: 10;
    overflow: hidden;
}

.modal-dialog > * {
    padding: 1rem;
}



.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-header .close-modal {
    font-size: 1.5rem;
}

.close-modal {
    width: 50px;
}

.modal p + p {
    margin-top: 1rem;
}
#modal_content{height: 185px;overflow-y: auto;}
.modal-content{width: auto !important;}
.modal-header{color: #fff;}

.ruleContent .k-input-md .k-input-button{background:inherit !important;color:inherit !important;}
.scrollTabContent{overflow:auto;scrollbar-color:#CCC #FFF;scrollbar-width:thin;height: calc(100% - 105px);}
/*.containerRuleTab{overflow: auto;height: calc(100% - 170px);position: absolute;width: calc(100% - 80px);scrollbar-color:#CCC #FFF;scrollbar-width:thin;}*/
.sl-question__title{
background: #5e7182;
color: #FFF;
width: 71px;
padding: 8px 2px;
border-radius: 5px;
display: block;
text-align: center;
font-size:13px !important;
}
.form-control{box-sizing: border-box;}
.jodit-wysiwyg{min-height: 90px !important;}
.reglas-container .contentBox{height:auto !important;}
.jodit-ui-group{display:block ruby !important;}
.sv-mobile-side-bar .svc-side-bar__container-title{font-size:14px;color:#FFF;padding-left:14px;}
.sv-mobile-side-bar .svc-side-bar__container-close .svc-side-bar__container-close-button{color:#FFF !important;}
.svc-logic-operator.svc-logic-operator--question {
border:solid 1px #FBE9C9 !important;background:#FFFCF7 !important;
border-radius: 5px !important;
}

.svc-logic-operator.svc-logic-operator--action{
background-color: #FFF !important;
border-radius: 5px !important;
}

.svc-logic-operator.svc-logic-operator--operator{
background-color: #FFF !important;
border-radius: 5px !important;
}

/*.svc-logic-paneldynamic .svc-logic-operator{height:25px !important;}*/
.sl-panel .sl-panel__footer button.sl-panel__done-button{
    border-radius:5px;
    background:#FFF !important;
    border:solid 2px #20c6c6;
    color:#20c6c6 !important;
    width: auto !important;
    margin: auto;
    min-width: 200px;
    transform: translate(0, 0);
}
.sl-panel .sl-panel__footer button.sl-panel__done-button span{color:#20c6c6 !important;}
.sl-panel .sl-panel__footer button.sl-panel__done-button:hover{
background:#20c6c6 !important;
transition: all 0.35s ease;
color:#FFF !important;
}
.sl-panel .sl-panel__footer button.sl-panel__done-button:hover span{color:#FFF !important;}



.sv-action-bar-item{
border-radius:5px !important;
}
.sv-action-bar-item:hover{

    background: #00a3fe !important;
}

.svc-logic-operator.svc-logic-operator--conjunction{
    border-radius:5px !important;
    border: solid 1px #FBE9C9 !important;
    background: #FFFCF7 !important;
}

.svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:hover{color:#000 !important;}
.svc-logic-operator.svc-logic-operator:hover{color:#000 !important;}

.svc-link-value-button .svc-question-link__set-button .svc-action-button{font-size:16px !important;font-weight: bold;}
.sl-table__cell .svc-action-button{font-size:14px !important;font-weight: bold;}


.sv-action-bar-item.sl-table__detail-button{
    color:#FFF;
    border-radius: 50% !important;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

.svc-link-value-button.svc-question-link__set-button.svc-action-button{
color: #000 !important;border-radius:5px;font-weight:600 !important;font-size: 13px !important;background:#FFF!important;
border: solid 1px #20c6c6 !important;margin:0;line-height:normal !important;}

/*.svc-logic-operator.svc-logic-operator--operator{min-height:40px;}*/
.svc-logic-operator.svc-logic-operator--operator{min-height:initial;}


@media only screen and (max-width:738px){
.reglas-container{width:100% !important;}
}


/*ESTRELLAs*/
.br-theme-css-estrellas .br-widget a:after{
    background-image: url(../images/stars/star.svg) !important;
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
    /*transition: all 0.2s ease-out;*/
}
.br-theme-css-estrellas .br-widget a.br-active:after{
    background-image: url(../images/stars/starSelected.svg) !important;
    display:block !important;
    content:"" !important;
    opacity: 1;
}
.br-theme-css-estrellas .br-widget a.br-selected:after{
    background-image: url(../images/stars/starSelected.svg) !important;
    display:block !important;
    content:"" !important;
    opacity: 1;
}

.br-theme-css-estrellas .br-widget a[data-rating-value="1"] {
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="2"] {
filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-webkit-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-moz-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="3"] {
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}
.br-theme-css-estrellas .br-widget a[data-rating-value="4"] {
filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-webkit-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-moz-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="5"] {
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="6"] {
filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="7"] {
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}
.br-theme-css-estrellas .br-widget a[data-rating-value="8"] {
filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="9"] {
    filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    -webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    -moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}

.br-theme-css-estrellas .br-widget a[data-rating-value="10"] {
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-estrellas .br-widget{margin:5px 0;}
.br-theme-css-estrellas .br-widget a{display:inline-block;margin:10px 3px;vertical-align: middle;width:22px;}
.br-theme-css-estrellas a[data-rating-value="newitem"]{display: none;}
.br-theme-css-estrellas a[data-rating-value="none"]{display: none;}
.br-theme-css-estrellas a[data-rating-value="other"]{display: none;}

/*NUMEROS*/

.br-theme-css-numeros .br-widget a[data-rating-value="0"] {color:#dd4d4b;border:solid 1px #dd4d4b;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="0"] {background-color:#dd4d4b;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="0"] {background-color:#dd4d4b;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="1"] {color:#dd4d4b;border:solid 1px #dd4d4b;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="1"] {background-color:#dd4d4b;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="1"] {background-color:#dd4d4b;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="2"] {color:#e96e4b;border:solid 1px #e96e4b;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="2"] {background-color:#e96e4b;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="2"] {background-color:#e96e4b;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="3"] {color:#f28b45;border:solid 1px #f28b45;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="3"] {background-color:#f28b45;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="3"] {background-color:#f28b45;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="4"] {color:#fea93e;border:solid 1px #fea93e;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="4"] {background-color:#fea93e;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="4"] {background-color:#fea93e;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="5"] {color:#ffc53d;border:solid 1px #ffc53d;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="5"] {background-color:#ffc53d;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="5"] {background-color:#ffc53d;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="6"] {color:#ffd936;border:solid 1px #ffd936;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="6"] {background-color:#ffd936;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="6"] {background-color:#ffd936;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="7"] {color:#dad14a;border:solid 1px #dad14a;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="7"] {background-color:#dad14a;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="7"] {background-color:#dad14a;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="8"] {color:#c1e266;border:solid 1px #c1e266;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="8"] {background-color:#c1e266;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="8"] {background-color:#c1e266;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="9"] {color:#8cc34b;border:solid 1px #8cc34b;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="9"] {background-color:#8cc34b;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="9"] {background-color:#8cc34b;color:#FFF;}

.br-theme-css-numeros .br-widget a[data-rating-value="10"] {color:#4cb050;border:solid 1px #4cb050;padding:6px 0 !important;}
.br-theme-css-numeros .br-widget a.br-active[data-rating-value="10"] {background-color:#4cb050;color:#FFF;}
.br-theme-css-numeros .br-widget a.br-selected[data-rating-value="10"] {background-color:#4cb050;color:#FFF;}

.br-theme-css-numeros .br-widget{margin:5px 0;}
.br-theme-css-numeros .br-widget a{display:inline-block;margin:10px 3px;vertical-align: middle;/*transition: all 0.2s ease-out;*/width:26px !important;height:30px !important;padding:6px;}
.br-theme-css-numeros a[data-rating-value="newitem"]{display: none;}
.br-theme-css-numeros a[data-rating-value="none"]{display: none;}
.br-theme-css-numeros a[data-rating-value="other"]{display: none;}
/*CARAS*/

.br-theme-css-caras .br-widget{margin:5px 0;}
.br-theme-css-caras.br-theme-scale-5.br-theme-horizontal .br-widget{width:200px;}
.br-theme-css-estrellas.br-theme-scale-5.br-theme-horizontal .br-widget{width:200px;}
.br-theme-css-numeros.br-theme-scale-5.br-theme-horizontal .br-widget{width:200px;}
.br-theme-css-caras .br-widget a{display:inline-block;margin:10px 3px;vertical-align: middle;/*transition: all 2s ease-out;*/background-repeat: no-repeat;}
.br-theme-css-caras a[data-rating-value="newitem"]{display: none;}
.br-theme-css-caras a[data-rating-value="none"]{display: none;}
.br-theme-css-caras a[data-rating-value="other"]{display: none;}
.br-theme-css-caras .br-widget a:after{
    /*background-image: url(../images/caras/cara1.svg) !important;*/
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
    /*transition: all 0.2s ease-out;*/
}
.br-theme-css-caras .br-widget a.br-active:after{

    display:block !important;
    content:"" !important;
    opacity: 1;
}
.br-theme-css-caras .br-widget a.br-selected:after{

    display:block !important;
    content:"" !important;
    opacity: 1;
}

.br-theme-css-caras .br-widget a[data-rating-value="1"] {
background-image: url(../images/caras/cara1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="1"] {
background-image: url(../images/caras/cara1-1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="1"] {
background-image: url(../images/caras/cara1-1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-caras .br-widget a[data-rating-value="2"] {
background-image: url(../images/caras/cara2.svg) !important;
filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-webkit-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-moz-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="2"] {
background-image: url(../images/caras/cara2-2.svg) !important;
filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-webkit-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-moz-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="2"] {
background-image: url(../images/caras/cara2-2.svg) !important;
filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-webkit-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
-moz-filter: invert(64%) sepia(65%) saturate(3526%) hue-rotate(330deg) brightness(95%) contrast(91%);
}

.br-theme-css-caras .br-widget a[data-rating-value="3"] {
background-image: url(../images/caras/cara3.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="3"] {
background-image: url(../images/caras/cara3-3.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="3"] {
background-image: url(../images/caras/cara3-3.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    }

.br-theme-css-caras .br-widget a[data-rating-value="4"] {
background-image: url(../images/caras/cara4.svg) !important;
filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-webkit-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-moz-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="4"] {
background-image: url(../images/caras/cara4-4.svg) !important;
filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-webkit-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-moz-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="4"] {
background-image: url(../images/caras/cara4-4.svg) !important;
filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-webkit-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
-moz-filter: invert(86%) sepia(28%) saturate(3673%) hue-rotate(327deg) brightness(99%) contrast(101%);
    }

.br-theme-css-caras .br-widget a[data-rating-value="5"] {
background-image: url(../images/caras/cara5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="5"] {
background-image: url(../images/caras/cara5-5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="5"] {
background-image: url(../images/caras/cara5-5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-caras .br-widget a[data-rating-value="6"] {
background-image: url(../images/caras/cara6.svg) !important;
filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="6"] {
background-image: url(../images/caras/cara6-6.svg) !important;
filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="6"] {
background-image: url(../images/caras/cara6-6.svg) !important;
filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
-moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
}

.br-theme-css-caras .br-widget a[data-rating-value="7"] {
background-image: url(../images/caras/cara7.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="7"] {
background-image: url(../images/caras/cara7-7.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="7"] {
background-image: url(../images/caras/cara7-7.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-caras .br-widget a[data-rating-value="8"] {
background-image: url(../images/caras/cara8.svg) !important;
filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="8"] {
background-image: url(../images/caras/cara8-8.svg) !important;
filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="8"] {
background-image: url(../images/caras/cara8-8.svg) !important;
filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
-moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
}

.br-theme-css-caras .br-widget a[data-rating-value="9"] {
background-image: url(../images/caras/cara9.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="9"] {
background-image: url(../images/caras/cara9-9.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="9"] {
background-image: url(../images/caras/cara9-9.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}

.br-theme-css-caras .br-widget a[data-rating-value="10"] {
background-image: url(../images/caras/cara10.svg) !important;
filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-caras .br-widget a.br-active[data-rating-value="10"] {
background-image: url(../images/caras/cara10-10.svg) !important;
filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-caras .br-widget a.br-selected[data-rating-value="10"] {
background-image: url(../images/caras/cara10-10.svg) !important;
filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

/*Manos*/
.br-theme-css-manos .br-widget{margin:5px 0;}
.br-theme-css-manos .br-widget a{display:inline-block;margin:10px 3px;vertical-align: middle;/*transition: all 0.2s ease-out;*/background-repeat: no-repeat;width:8% !important;}

.br-theme-css-manos a[data-rating-value="newitem"]{display: none;}
.br-theme-css-manos a[data-rating-value="none"]{display: none;}
.br-theme-css-manos a[data-rating-value="other"]{display: none;}

.br-theme-css-manos .br-widget a:after{
    /*background-image: url(../images/caras/cara1.svg) !important;*/
    background-size: 100% !important;
    display:block !important;
    width: 40px !important;
    height: 38px !important;
    content: "";
    opacity: 1;
    /*transition: all 0.2s ease-out;*/
}
.br-theme-css-manos .br-widget a.br-active:after{

    display:block !important;
    content:"" !important;
    opacity: 1;
}
.br-theme-css-manos .br-widget a.br-selected:after{

    display:block !important;
    content:"" !important;
    opacity: 1;
}

.br-theme-css-manos .br-widget a[data-rating-value="1"] {
background-image: url(../images/manos/manos1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-manos .br-widget a.br-active[data-rating-value="1"] {
background-image: url(../images/manos/manos1-1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-manos .br-widget a.br-selected[data-rating-value="1"] {
background-image: url(../images/manos/manos1-1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}

.br-theme-css-manos .br-widget a[data-rating-value="2"] {
background-image: url(../images/manos/manos2.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}

.br-theme-css-manos .br-widget a.br-active[data-rating-value="2"] {
background-image: url(../images/manos/manos2-2.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}

.br-theme-css-manos .br-widget a.br-selected[data-rating-value="2"] {
background-image: url(../images/manos/manos2-2.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    }

.br-theme-css-manos .br-widget a[data-rating-value="3"] {
background-image: url(../images/manos/manos3.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-manos .br-widget a.br-active[data-rating-value="3"] {
background-image: url(../images/manos/manos3-3.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);

}

.br-theme-css-manos .br-widget a.br-selected[data-rating-value="3"] {
background-image: url(../images/manos/manos3-3.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}


.br-theme-css-manos .br-widget a[data-rating-value="4"] {
background-image: url(../images/manos/manos4.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-manos .br-widget a.br-active[data-rating-value="4"] {
background-image: url(../images/manos/manos4-4.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-manos .br-widget a.br-selected[data-rating-value="4"] {
background-image: url(../images/manos/manos4-4.svg) !important;
filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
-moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
}

.br-theme-css-manos .br-widget a[data-rating-value="5"] {
background-image: url(../images/manos/manos5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-manos .br-widget a.br-active[data-rating-value="5"] {
background-image: url(../images/manos/manos5-5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-manos .br-widget a.br-selected[data-rating-value="5"] {
background-image: url(../images/manos/manos5-5.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.br-theme-css-manos .br-widget a[data-rating-value="6"] {
    background-image: url(../images/manos/manos6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    }

    .br-theme-css-manos .br-widget a.br-active[data-rating-value="6"] {
    background-image: url(../images/manos/manos6-6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    }

    .br-theme-css-manos .br-widget a.br-selected[data-rating-value="6"] {
    background-image: url(../images/manos/manos6-6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    }

    .br-theme-css-manos .br-widget a[data-rating-value="7"] {
    background-image: url(../images/manos/manos7.svg) !important;
    filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    }

    .br-theme-css-manos .br-widget a.br-active[data-rating-value="7"] {
    background-image: url(../images/manos/manos7-7.svg) !important;
    filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    }

    .br-theme-css-manos .br-widget a.br-selected[data-rating-value="7"] {
    background-image: url(../images/manos/manos7-7.svg) !important;
    filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -webkit-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    -moz-filter: invert(100%) sepia(50%) saturate(2800%) hue-rotate(338deg) brightness(91%) contrast(86%);
    }

    .br-theme-css-manos .br-widget a[data-rating-value="8"] {
    background-image: url(../images/manos/manos8.svg) !important;
    filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    }

    .br-theme-css-manos .br-widget a.br-active[data-rating-value="8"] {
    background-image: url(../images/manos/manos8-8.svg) !important;
    filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    }

    .br-theme-css-manos .br-widget a.br-selected[data-rating-value="8"] {
    background-image: url(../images/manos/manos8-8.svg) !important;
    filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    }

    .br-theme-css-manos .br-widget a[data-rating-value="9"] {
    background-image: url(../images/manos/manos9.svg) !important;
    filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    }

    .br-theme-css-manos .br-widget a.br-active[data-rating-value="9"] {
    background-image: url(../images/manos/manos9-9.svg) !important;
    filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    }

    .br-theme-css-manos .br-widget a.br-selected[data-rating-value="9"] {
    background-image: url(../images/manos/manos9-9.svg) !important;
    filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    }

    .br-theme-css-manos .br-widget a[data-rating-value="10"] {
    background-image: url(../images/manos/manos10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    }

    .br-theme-css-manos .br-widget a.br-active[data-rating-value="10"] {
    background-image: url(../images/manos/manos10-10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    }

    .br-theme-css-manos .br-widget a.br-selected[data-rating-value="10"] {
    background-image: url(../images/manos/manos10-10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    }

.br-theme-scale-gray .br-widget a{
filter: invert(75%) sepia(0%) saturate(3310%) hue-rotate(30deg) brightness(84%) contrast(75%) !important;
-webkit-filter: invert(75%) sepia(0%) saturate(3310%) hue-rotate(30deg) brightness(84%) contrast(75%) !important;
-moz-filter: invert(75%) sepia(0%) saturate(3310%) hue-rotate(30deg) brightness(84%) contrast(75%) !important;
color:#999 !important;
border-color:#999 !important;
}

.br-theme-css-numeros.br-theme-scale-gray .br-widget a.br-active{
background-color:#999 !important;
color:#FFF !important;
filter:none !important;
}
.br-theme-css-numeros.br-theme-scale-gray .br-widget a.br-selected{
background-color:#999 !important;
color:#FFF !important;
filter:none !important;
}

.br-theme-css-estrellas.br-theme-scale-5 .br-widget a[data-rating-value="1"]{
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}
.br-theme-css-estrellas.br-theme-scale-5 .br-widget a[data-rating-value="2"]{
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}
.br-theme-css-estrellas.br-theme-scale-5 .br-widget a[data-rating-value="3"]{
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}
.br-theme-css-estrellas.br-theme-scale-5 .br-widget a[data-rating-value="4"]{
    filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    -webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
    -moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}
.br-theme-css-estrellas.br-theme-scale-5 .br-widget a[data-rating-value="5"]{
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-caras.br-theme-scale-5 .br-widget a[data-rating-value="1"]{
background-image: url(../images/caras/cara1.svg) !important;
filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
-moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-active[data-rating-value="1"]{
    background-image: url(../images/caras/cara1-1.svg) !important;
    filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    }
    .br-theme-css-caras.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="1"]{
        background-image: url(../images/caras/cara1-1.svg) !important;
        filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        }
.br-theme-css-caras.br-theme-scale-5 .br-widget a[data-rating-value="2"]{
background-image: url(../images/caras/cara3.svg) !important;
filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
-moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-active[data-rating-value="2"]{
    background-image: url(../images/caras/cara3-3.svg) !important;
    filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    }
    .br-theme-css-caras.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="2"]{
        background-image: url(../images/caras/cara3-3.svg) !important;
        filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        }

.br-theme-css-caras.br-theme-scale-5 .br-widget a[data-rating-value="3"]{
background-image: url(../images/caras/cara6.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-active[data-rating-value="3"]{
background-image: url(../images/caras/cara6-6.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="3"]{
background-image: url(../images/caras/cara6-6.svg) !important;
filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
-moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a[data-rating-value="4"]{
background-image: url(../images/caras/cara7.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-active[data-rating-value="4"]{
background-image: url(../images/caras/cara7-7.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}
.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="4"]{
background-image: url(../images/caras/cara7-7.svg) !important;
filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
}

.br-theme-css-caras.br-theme-scale-5 .br-widget a[data-rating-value="5"]{
background-image: url(../images/caras/cara10.svg) !important;
filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-active[data-rating-value="5"]{
background-image: url(../images/caras/cara10-10.svg) !important;
filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
}

.br-theme-css-caras.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="5"]{
    background-image: url(../images/caras/cara10-10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    }

    .br-theme-css-manos.br-theme-scale-5 .br-widget a[data-rating-value="1"]{
        background-image: url(../images/manos/manos1.svg) !important;
        filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-active[data-rating-value="1"]{
            background-image: url(../images/manos/manos1-1.svg) !important;
            filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
            -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
            -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
            }
            .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="1"]{
                background-image: url(../images/manos/manos1-1.svg) !important;
                filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
                -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
                -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
                }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a[data-rating-value="2"]{
        background-image: url(../images/manos/manos3.svg) !important;
        filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-active[data-rating-value="2"]{
            background-image: url(../images/manos/manos3-3.svg) !important;
            filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
            -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
            -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
            }
            .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="2"]{
                background-image: url(../images/manos/manos3-3.svg) !important;
                filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
                -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
                -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
                }

        .br-theme-css-manos.br-theme-scale-5 .br-widget a[data-rating-value="3"]{
        background-image: url(../images/manos/manos6.svg) !important;
        filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-active[data-rating-value="3"]{
        background-image: url(../images/manos/manos6-6.svg) !important;
        filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="3"]{
        background-image: url(../images/manos/manos6-6.svg) !important;
        filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -webkit-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        -moz-filter: invert(88%) sepia(35%) saturate(1312%) hue-rotate(327deg) brightness(103%) contrast(101%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a[data-rating-value="4"]{
        background-image: url(../images/manos/manos7.svg) !important;
        filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
        -webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
        -moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-active[data-rating-value="4"]{
        background-image: url(../images/manos/manos7-7.svg) !important;
        filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
        }
        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="4"]{
        background-image: url(../images/manos/manos7-7.svg) !important;
        filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
-moz-filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(3716%) hue-rotate(45deg) brightness(98%) contrast(106%);
        }

        .br-theme-css-manos.br-theme-scale-5 .br-widget a[data-rating-value="5"]{
        background-image: url(../images/manos/manos10.svg) !important;
        filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
        }

        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-active[data-rating-value="5"]{
        background-image: url(../images/manos/manos10-10.svg) !important;
        filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
-webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
        }

        .br-theme-css-manos.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="5"]{
            background-image: url(../images/manos/manos10-10.svg) !important;
            filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
            -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
            -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
            }

.br-theme-css-numeros.br-theme-scale-5 .br-widget a[data-rating-value="1"]{color:#e1544d;border:solid 1px #e1544d;filter:none;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-active[data-rating-value="1"]{color:#FFF;background:#e1544d;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="1"]{color:#FFF;background:#e1544d;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a[data-rating-value="2"]{color:#f98d44;border:solid 1px #f98d44;filter:none;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-active[data-rating-value="2"]{color:#FFF;background:#f98d44;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="2"]{color:#FFF;background:#f98d44;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a[data-rating-value="3"]{color:#fec438;border:solid 1px #fec438;filter:none;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-active[data-rating-value="3"]{color:#FFF;background:#fec438;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="3"]{color:#FFF;background:#fec438;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a[data-rating-value="4"]{color:#8cc34b;border:solid 1px #8cc34b;filter:none;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-active[data-rating-value="4"]{color:#FFF;background:#8cc34b;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="4"]{color:#FFF;background:#8cc34b;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a[data-rating-value="5"]{color:#4cb050;border:solid 1px #4cb050;filter:none;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-active[data-rating-value="5"]{color:#FFF;background:#4cb050;}
.br-theme-css-numeros.br-theme-scale-5 .br-widget a.br-selected[data-rating-value="5"]{color:#FFF;background:#4cb050;}
.topMenu img, *::before, *::after {
    box-sizing: unset;
}
.sv-list{
    overflow: auto;
}
.container-base-survey .modal {
    display: block;
}
.btn .btn-danger .close-modal{
    width: 100px !important;
    border-color:transparent !important;
}
/*.btn-danger:hover{border-color:transparent !important;}*/
.poppup-survey .modal-header{background: linear-gradient(to bottom, #00a3fe 0%,#0084f9 100%);}
.btnControlwith{width: 100px !important; border: none !important;}

:root{
    --font-family:"Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Helvetica Neue", Arial, sans-serif;
    --font-size:12px;
    --color-tex-cont: black;
    --background: #FFF;
    --background-cel: #ffffff;
    --color-title: black;
    --color-border-cel: black;
    --background-cel-relleno:#FFF;
    --background-btn:#FFF;
    --background-btn-hover: black;
    --color-text-btn: black;
}

.radioColor{
    color: var(--color-tex-cont);
}
.inputBox{
    color:var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}
.textAreaTheme{
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.contentBoxTheme{
    background-color: var(--background);
    color: var(--color-title);
}

.contentBoxTheme .container{width:auto;padding:0;}
.contentBoxTheme .container input[type="text"]{width:60px;border:0;}

.contentBoxTheme1{
    padding:30px;
    background: #FFF;
    margin-bottom:20px;/*border-radius: 5px;*/
    box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);
    max-width:820px;
    margin-left:auto;
    margin-right:auto;
    background-color: var(--background-cel);
    border-width: 3px;
    border-style: solid;
    border-color: var(--color-border-cel);
}


.sv_enviar_btn{
    transition-duration: 0.2s;
    float:none !important;
    width:15%;
    box-sizing: border-box;
    padding: 9px !important;
    font-weight: bold;
    line-height: 2em;
    border: none;
    min-width: 100px;
    cursor: pointer;
    border-radius: 2px;
    color: var(--color-text-btn) !important;
    background-color: var(--background-btn) !important;
}
.sv_enviar_btn:hover{
    background-color: var(--background-btn-hover) !important;
}
/*.titleTema{
    color: #00a3fe;
}*/
.contentBoxEnc{
    height: 62vh;
}
.imageDefaultEnc{

    width: 250;
    height: 150px;
    text-align:center;position: absolute;top:50%;left:52%;transform: translate(-50%, -50%);
    margin-top: 80px;
    margin-left: 180px;
}

.combo-regla{
    width: 150px;
}

.reglas-backgroud-trans{
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    right: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    top:0;
}

.reglas-backgroud{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.jodit-status-bar-link{display: none !important;}
.positionrelative{position: relative;}
.modal-reglas{width: 300px;float: right;}
.reglas-container{position: absolute;z-index: 2;right: 0;transform: translate(-50%, -50%);width: 100%;top:50%;}



.btnThemeCancel{background: #FFF;color:#666;}
.tabSettings{position: relative;}
.tabSettings p{font-size:13px;color:#666;margin-bottom:20px;}
.tabTitle{font-size: 1.4em;margin-bottom:20px;}
.tabList{list-style-type:none;padding:9px 0;margin:0;font-size:14px;padding-bottom:20px;margin-bottom:20px;border-bottom:solid 1px #DFDFDF;}
.tabList li{display:inline-block;margin-right: 10px;color:#666;border-right: solid 1px #DFDFDF;padding-right:10px;text-transform:uppercase;}
.tabList li a{color:#666;text-decoration:none;}
.tabList li a:hover{color:#000;}
.tabList li:last-child{border:0;}
/*.selectedTab{color:#54a3ee !important;}*/
.ruleTab{overflow: hidden;position:relative;font-size:1.2em;border:solid 1px #54a3ee;border-radius:5px;margin-bottom: 10px;}
.controlsRule{list-style-type: none;padding:0;margin:0;overflow:hidden;position:absolute;right:0;top:50%;transform:translateY(-50%);}
.controlsRule li{float:left;}
.ruleHeader{position:relative;padding:15px;background:#e7f6ff;cursor: pointer;}
.ruleContent{padding:10px 0;height: 320px;overflow-y: auto;}
.ruleContentView{padding:10px;height: auto;overflow-y: auto;font-size:13px;}
.ifCondition{color:#7b7b7b;padding:8px;}
.ifCondition span{color:#000;/*vertical-align:middle;*/}
.if{padding:12px 2px;background:#5e7182;color:#FFF;display:inline-block;width:71px;text-align: center;border-radius:5px;float:left;margin:0px 0;margin-right:10px;}
.ifRuleBg{background:#FFF;padding:10px 14px;border:solid 1px #CCC;border-radius:5px;margin-top:10px;margin-bottom:10px;margin-left:83px;}
.ifRuleBg span{color:#ff9233;}
.ifContainer{overflow:hidden;}
.then{padding:12px 2px;background:#5e7182;color:#FFF;display:inline-block;width:71px;text-align: center;border-radius:5px;float:left;margin-right:10px;}
.thenCondition{color:#7b7b7b;padding:8px;}
.thenCondition span{color:#000;}
.thenRuleBg{background:#FFF;padding:10px 14px;border:solid 1px #CCC;border-radius:5px;margin-top:10px;margin-bottom:0px;margin-left:83px;}
.thenRuleBg span{color:#ff9233;}
.thenContainer{overflow:hidden;margin-top:5px;}
.addRule{font-size:13px;border-radius:5px;padding:6px 14px;color:#FFF;position:absolute;right:0;margin-top:5px;cursor:pointer;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.labelRule{font-size:13px;padding:14px 20px;color:#FFF;margin-bottom:10px;border-top-left-radius:5px;border-top-right-radius:5px;font-weight:bold;
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.ifCondition select{padding:6px 10px;border-radius:5px;border:solid 1px #DFDFDF;}
/*.ifCondition input[type="text"]{padding:7px 10px;border-radius:5px;border:solid 1px #DFDFDF;}*/
.removeIc{float:left;cursor:pointer;border:solid 1px #DFDfDF;border-radius:5px;padding:18px;margin-left: 5px;height: 30px;width: 30px;}
.ruleControls{display:inline-block;}
.size-m{width:187px;}
.textareaRule{width:100%;padding:12px;box-sizing:border-box;border:solid 1px #DFDFDF;border-radius:5px;min-height:90px;font-family: 'Ubuntu', sans-serif;background:#f9f9f9;}
.radioContainer{overflow:hidden;margin-bottom:20px;font-size:1.2em;}
.checkContainer{overflow:hidden;margin:10px 0;margin-bottom:20px;color:#7b7b7b;}
.checkContainer input[type="checkbox"]{margin-right:8px;}
.labelSplash{font-size:1.2em;padding:20px 0;}
.inputFullwidth{width:100%;padding:14px;box-sizing:border-box;border:solid 1px #DFDFDF;border-radius:5px;margin-bottom:20px;background: #f9f9f9;}
.popUpContainer{position:absolute;top:50%;background: #FFF;width:738px;left:50%;top:50%;transform: translate(-50%, -50%);z-index:2;border-radius:10px;}
.popUpBg{position:fixed;background:rgba(0,0,0,0.6);width:100%;height:100%;z-index: 5;top:0;left:0;}
.popContainer{padding:10px 20px;}
.popContainer hr{height: 1px;background-color:#DFDFDF;border: none;}

.ruleTxt{font-size:13px;margin-bottom:11px;font-weight:bold;}
.btnCancel{
background:#81dada;border:solid 2px #81dada;
color:#FFF;font-size:13px;padding:8px 10px;border-radius:5px;text-align:center;
cursor: pointer;min-width:90px;
}
.btnCancel:hover{background:#168b8b !important;transition: all 0.35s ease;color:#FFF;}
.btnAdd{
background: #00a3fe;
background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
color:#FFF;font-size:13px;padding:10px;border-radius:5px;text-align:center;
cursor: pointer;
}
.btnPopup{overflow:hidden;border-top:solid 1px #DFDFDF;padding:10px 0px;}
.btnPopup ul{list-style-type:none;padding:0;margin:0;overflow:hidden;float:right;}
.btnPopup li{display:inline-block;margin:0 2px;}

.header{display:none;}

@media only screen and (max-width:738px){
.popUpContainer{width:90%;}
.contentBox{width:90%;box-sizing: border-box;}
.ifCondition select{width:100%;margin-bottom:6px;}
/*.ifCondition input[type="text"]{width:100%;box-sizing: border-box;}*/
.responsiveContent{width:85px !important;float:left;margin-right:10px;}
.ruleControls{margin-top:6px;}
.tabList{font-size:14px;}
/*.addRule{bottom: 0;position: fixed;margin: 20px;z-index:5;}*/

}


@keyframes s-ripple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0.01;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
    0% {
    transform: scale(0);
    }
    30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-radio input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}

/*responsive*/





.combiocond{width: 150px !important;height: 38px !important;}
.ifCondition-margin{margin-left: 77px;}
.heigth-controles{height: 38px;}
.input-accion-click{
    padding: 7px 10px;
    border-radius: 5px;
    border: solid 1px #DFDFDF;
    margin-left: 5px;
    /*height: 14px;*/
}
.margin-reglas input{margin-bottom: 5px;}
.margin-reglas span{margin-bottom: 5px;}
.jodit-react-container{margin-bottom: 5px;}
.regla-nombre{height: 40px;}
.poppup-survey{width: 100%;height: 100%;position: absolute; z-index: 10; display: none;}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
}

.modal.is-visible {
    visibility: visible;
    opacity: 1;
    display: block !important;
    z-index: 1000000;
}

.modal-dialog {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    border-radius: 5px;
    background: #FFFFFF;
    overflow: hidden;
    cursor: default;
    width: 300px;
    height: 300px;
    z-index: 10;
}

.modal-dialog > * {
    padding: 1rem;
}


.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#000 !important;
}

.modal-header .close-modal {
    font-size: 1.5rem;
}

.close-modal {
    width: 50px;
}

.modal p + p {
    margin-top: 1rem;
}
#modal_content{height: 185px;}
.modal-content{width: auto !important;}
.modal-header{color: #fff;}
:root{

    --color-tex-cont: black;
    --backgroundFondo: #FFF;
    --background: #ffffff;

    --color-title: rgb(0, 0, 0);
    --color-border-cel: #ffffff;
    --background-cel-relleno:#FFF;
    --background-btn:#057eff;
    --background-btn-hover: black;
    --color-text-btn: black;
	--background-question: #ffffff;
    --color-title-header: #19b394;
    --color-subtitle-header: #909090;
    --text-align-question: "left";
    --text-weight-question: "none";
    --text-decoration-question: "none";
}
.sd-title.sd-container-modern__title {
    background-color: var(--background-header) !important;
}
.sd-comment{
    height: calc(18 * var(--base-unit, 8px));
    min-width: calc(6 * var(--base-unit, 8px));
    min-height: calc(6 * var(--base-unit, 8px));
    max-width: 100%;


    appearance: none;
    position: static;
    width: 100%;

    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;

    color: var(--foreground, #161616);
    background-color: var(--background-dim-light, #f9f9f9);
    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;
    color: var( --color-tex-cont);
    background-color: var(--background-cel-relleno);
}
.sd-text {
    appearance: none;
    position: static;
    width: 100%;
    height: calc(6 * var(--base-unit, 8px));
    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: calc(2 * var(--base-unit, 8px));
    color: var( --color-tex-cont) !important;

    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;

    background-color: var(--background-cel-relleno) !important;
}

.jodit-wysiwyg{
    color: var( --color-tex-cont);
}

.sv-string-editor{
    color: var(--color-title);
}


.sd-element__numEn{

    line-height: calc(2 * var(--base-unit, 8px));
    padding: calc(3 * var(--base-unit, 8px)) calc(1 * var(--base-unit, 8px)) calc(0.375 * var(--base-unit, 8px));
    float: left;
    text-align: right;
    box-sizing: border-box;
    white-space: nowrap;
    flex-shrink: 0;

    color: var(--color-title);
}
.sd-element__num{margin-left:0 !important;}
.svc-question__adorner{
    border: solid;
    border-color: var(--color-border-cel);
}
.radioColor{
    color: var(--color-tex-cont);
}
.inputBox{
    color:var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.textAreaTheme{
    color: var(--color-tex-cont);
    background-color: var(--background-cel-relleno);
}

.contentBoxTheme{
    color: var(--color-title);
}
.sd-question {
    background-color: var(--background-question) !important;
}

.sd-title.sd-container-modern__title > div.sd-header__text > h3 {
    color: var(--color-title-header) !important;
}

.sd-title.sd-container-modern__title > div.sd-header__text > h5 {
    color: var(--color-subtitle-header) !important;
}

.contentBoxTheme1{
    padding:30px;
    background: #FFF;
    margin-bottom:20px;/*border-radius: 5px;*/
    /*box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);*/
    max-width:820px;
    margin-left:auto;
    margin-right:auto;
    background-color: var(--background);
    border-width: 3px;
    border-style: solid;
    border-color: var(--color-border-cel);
}


.sv_enviar_btn{
    transition-duration: 0.2s;
    float:none !important;
    width:15%;
    box-sizing: border-box;
    padding: 9px !important;
    font-weight: bold;
    line-height: 2em;
    border: none;
    min-width: 100px;
    cursor: pointer;
    border-radius: 2px;
    color: var(--color-text-btn);
    background-color: var(--background-btn);
}
.sv_enviar_btn:hover{
    background-color: var(--background-btn-hover);
}
.titleTema{
    /*color: #000; */
    border:0;
    background:none;
}
.contentBoxEnc{
    height: 62vh;
}

.imageDefaultEnc{

    width: 250;
    height: 150px;
    text-align:center;position: absolute;top:50%;left:52%;transform: translate(-50%, -50%);
    margin-top: 80px;
    margin-left: 180px;


}
.sd-row{
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cel) !important;
    background-color: var(--background) !important;
}
.sv_row{
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cel) !important;
    background-color: var(--background) !important;
}
.sv-text {
    appearance: none;
    position: static;
    width: 100%;
    height: calc(6 * var(--base-unit, 8px));
    box-sizing: border-box;
    padding: calc(1.5 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
    line-height: calc(3 * var(--base-unit, 8px));
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: calc(2 * var(--base-unit, 8px));
    color: var( --color-tex-cont) !important;

    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 15%);
    border: none;
    border-radius: 3px;
    text-align: left;

    background-color: var(--background-cel-relleno) !important;
}
.sv_main{
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
}
.sd_main{
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
}
.sd-body{
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;
	background-color: var(--backgroundFondo) !important;
}
.sd-root-modern *{
    font-family: var(--font-family) !important;
    font-size: var(--font-size) !important;

}
.sd-page{
    background-color: var(--backgroundFondo) !important;
}
.sd-page > div.sd-row{
    background-color: #ffffff !important;
}
.sv-page{
    background-color: var(--backgroundFondo) !important;
}
.sv_q_text_root{
    color:var(--color-tex-cont) !important;
    background-color: var(--background-cel-relleno) !important;
}
.sv_q_dropdown_control{
    color:var(--color-tex-cont) !important;
    background-color: var(--background-cel-relleno) !important;
}
.sv_select_wrapper{
    color:var(--color-tex-cont) !important;
}
.sv_q_num{
    color: var(--color-title) !important;
}
.sv_complete_btn{
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}
.sv_complete_btn:hover{
    background-color: var(--background-btn-hover) !important;
}
.sd-btn{
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}
.sd-btn:hover{
    background: var(--background-btn-hover) !important;
    color: var(--color-text-btn) !important;
}
.sv-btn{
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}
.sv-btn:hover{
    background: var(--background-btn-hover) !important;
    color: var(--color-text-btn) !important;
}

.sd-navigation__complete-btn{
    background: var(--background-btn) !important;
    color: var(--color-text-btn) !important;
}
.sd-navigation__complete-btn:hover{
    background-color: var(--background-btn-hover) !important;
}

/*.sv_next_btn{
    background:#016ba7 !important;
    color:#FFF !important;
}

.sv_next_btn:hover{
    background-color: var(--background-btn-hover) !important;
}

.sv_header{
    border: solid;
    border-color: var(--color-border-cel) !important;
    background-color: var(--background) !important;
    padding: 1em !important;


}
.sv_header__text{
    max-width: 100% !important;
    width: 100%;
    display: inline-block !important;
    vertical-align: top !important;

}*/
.sv_main .sv_container .sv_header h3{
    font-size: 1.5em !important;
}

.contentBoxTheme .svc-question__adorner{border:0;}
.contentBoxTheme .sv_enviar_btn{
    color:#FFF;
    border-radius:5px;
    background: #00a3fe;
    background: -moz-linear-gradient(top,  #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top,  #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom,  #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}
.contentEncabezado{
    background-color: var(--backgroundFondo) !important;
    color: var(--color-title);
}
fontawesome-stars
.spg-dropdown :nth-child(1) { content:"Frase que quiero poner";}

.svc-creator .svc-tabbed-menu-item{height: auto !important;}
.spg-dropdown{height: 48px !important;}
.headerFixed .contentBox{width: calc(100% - 40px);margin: auto;/*margin-left: 30px;*/padding-top: 10px;padding-bottom: 0px;padding-left:15px;padding-right:15px;}
.reglas-container .contentBox{width:100%;padding-top: 30px;padding-bottom: 10px;margin-left:0;}
/*.showLeftControls .contentBox{width: calc(100% - 100px) !important;margin: auto !important;margin-left: 90px !important;}*/
.headerFixed .contentBox-publicar{
    width: calc(100% - 40px);
    margin: auto;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
}
/*.showLeftControls .contentBox-publicar{width: calc(100% - 130px) !important;margin: auto !important;margin-left: 90px !important;}*/

.sd-root-modern{background-color: #fff !important;}
.svc-flex-row{background-color: #fff !important;}
.spg-panel__content{background-color: #fff !important;}

.themeList2{overflow: auto;height: calc(100% - 67px);scrollbar-color:#CCC #FFF;scrollbar-width:thin;}

.client-survey{scrollbar-color:#CCC #FFF;scrollbar-width:thin;}
.sl-question__header .sv-string-viewer{color:#fff}

.relative-pos{position:relative;border:solid 1px #dfdfdf;width:100%;display:block;padding:5px;border-radius:5px;}


/*THEME*/

.herramientasTema{margin:0px 10px;margin:0;}
.formatList{list-style-type: none;padding:0;margin:0;}
.formatList li{padding:15px;border-bottom:solid 1px #CCC;cursor:pointer;padding-left:40px;}
.formatList li:last-child{border:0;}
.formatMovil{background:url(../images/general/theme/ic-movil.png) no-repeat left center;background-position:10px center;}
.formatTablet{background:url(../images/general/theme/ic-tablet.png) no-repeat left center;background-position:10px center;}
.formatDesktop{background:url(../images/general/theme/ic-desktop.png) no-repeat left center;background-position:10px center;}
.contentPageLeft-theme{
    /*width: 190px;*/
    width:210px;
    float: left;
    overflow: hidden;
    position: fixed;
    box-sizing: border-box;
    height: calc(100% - 204px);
    overflow: auto;
    scrollbar-color: #CCCCCC #F5F5F5; /* thumb and track color */
    scrollbar-width: thin;
    padding-right:10px;
}
.btnGaleriaTemas {
    background: #00a3fe;
    background: -moz-linear-gradient(top, #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top, #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom, #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
    color: #FFF;
    font-size: 13px;
    padding:10px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 12px;
    position:relative;
}

/*.btnGaleriaTemas::after{
content:"";
background:url(../images/grid/search.svg) no-repeat;
width:16px;
height:16px;
display:block;
position:absolute;top:50%;transform:translateY(-50%);
filter:invert(1);
margin-top: 1px;
margin-left: 6px;
}*/

.selector {
    background: #FFF;
    padding:4px 10px;
    border-radius: 5px;
    border:solid 1px #DFDFDF;
}
.selector select {
    width: 100%;
    border: solid 1px #EEE;
}
.contentEncabezado{
margin-bottom: 20px;
max-width: 880px;
margin-left: auto;
margin-right: auto;
}

div.c{
position: relative;
margin-left:6px;
border-radius:5px;
/*background:#3f4c6b;*/
}
.activator{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity:0;
    visibility: 0;
}
div.c h1{
color:#1e2125;
padding:12px 0px;
position: relative;
font-size:13px;
font-weight: normal;
/*margin-top: -14px;*/
/*border-radius:5px;*/
margin:0;
/*background: #FFF;*/
}

@media only screen and (min-width:961px) and (max-width:1305px){
    .rightList{display:none;}
    .mobileOptions{display:block;}
}


@media only screen and (max-width:960px){
.main{margin-top:20px;}
.container{width:auto;margin:0 20px;}
.left-content{float:none;width:auto;height: auto;padding: 20px 30px;}
.txtcontent{margin:30px 0;}
.right-content{float:none;width:auto;height:300px;background-size: 75%;}
.left-content h1{margin:20px 0;}
.btn-login{margin-bottom:20px;}
.stepContainer{width:100%;}
.contentPageLeft{width:60px;font-size:0;}
.contentPageRight{width:calc(100% - 60px);padding:30px 2px;}
.titleBox{font-size:100%;}
.radioGroup div{font-size:100%;}
.radioGroup{margin-top:15px;}
.contentBox{padding:15px;}
.header li{padding:0 22px !important;}
.header li span:nth-child(2n) {display:none;}
.header li span{margin-right:0;}
.tools{width:auto;}
.toolList{margin-left:5px;}
.rightList{float:none !important;display:block;}
.header li:last-child{border-right:solid 1px #FFF;}
.step{padding:0 10px;}
.contentData{width:auto;margin:20px;margin-top:0;padding:34px;}
.contentData label{width:100%;}
.contentData div span{width:100%;}
.svc-creator__content-holder .svc-flex-column{margin:10px !important;}
.svc-question__content .svc-rating-question-controls{display:block;margin:0;padding:0;position:relative;width:auto;top:0;left:0;}
.svc-question__content .sd-rating{margin:0;padding:0;width:auto;}
.contentPageLeft-theme{width:auto;margin:0 !important;float:none;position:relative;}
.shareContent{width:100% !important;margin-bottom:20px;}
.shareContainer{flex-direction:column;}
.contentCenter{left:0 !important;width:auto !important;position:relative;height:auto;}
.contentPanel{padding:0 !important}
.contentRight{position:relative !important;float:none !important;width:100% !important;top:0 !important;}
body[data-layout="horizontal"] .page-content{margin-right:0 !important;}
}



div.c label::before{
    content:"";
    display: inline-block;
    border: 4px solid transparent;
    border-left:5px solid #909090;
    margin-right:5px;
    margin-top:-1px;
}

div.c:hover label::before{
    border-left:5px solid #20c6c6;
}

div.c label{
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-weight:bold;
}
div.c div.p{
    max-height:0px;
    overflow: hidden;
    transition:max-height 0.5s;
    margin-left:14px;
    /*background-color: white;*/
    /*box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;*/
}
div.c div.p p {
    padding:5px 0px;
    margin-bottom:0;
}
div.c input:checked ~ h1 label::before{
    border-left:4px solid transparent;
    border-top:5px solid #20c6c6;
    margin-top:5px;
    margin-right:6px;
}
div.c input:checked ~ h1 ~ div.p{
    max-height:620px;
}
#colorpicker{margin-right:5px;vertical-align:middle;}
.textTools{padding:10px;}
.titleSection{font-weight:bold;margin-bottom:0 !important;color:#000 !important;}
.encabezado-theme {
    border: dotted 2px #CCC;
    padding: 19px;
    font-size: 14px;
    text-align: center;
    color: #999;
    border-radius:5px;
    margin-bottom:10px;
    cursor: pointer;
  }

  .encabezado-theme img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .contentRight {
    width: 190px;
    overflow: hidden;
    float: right;
  }
  .contentRight-preview {
    width: 190px;
    overflow: hidden;
    float: right;
  }

  .contentCenter {
    width: calc(100% - 560px);
    left: 280px;
    margin-top: 0px;
    padding-top: 0px;
    height: calc(100% - 246px);
    background: #FFF;
    border-radius:5px;
    /*box-shadow:0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    border:solid 1px #CCC;*/
  }
  .sizeLarge {
    width: 100%;
    padding: 5px;
  }
  .textTools li {
    display: inline-block;
    padding: 5px 6px;
    background: #FFF;
    border: solid 1px #CCC;
    cursor: pointer;
  }


.sv_main .sv_container .sv_body .sv_p_root .sv_q{
scrollbar-color: #CCCCCC #F5F5F5;
scrollbar-width: thin;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td, .sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix th{vertical-align: top;}

@media only screen and (max-width:738px){
.contentCenter{width: inherit;left: 195px;}
.contentEncabezado{width:90%;}
.contentRight-preview{position: absolute;right: 0px;top:170px}
.contentPageLeft-theme{margin-left:-40px;}
.contentCenter-preview{width: calc(100% - 38px) !important;height: calc(100% - 160px) !important;}
.contentRight{position: absolute;right: 0px;top:120px}
.contentCenter{height: calc(100% - 210px) !important;}
}

@media only screen and (max-width:440px){
.k-step-label{display:none !important;}
.style2 .k-stepper{padding-top:18px;}
}

.showLeftControls .contentCenter-preview {
    left: 90px;
    width: calc(100% - 305px);
}
.contentCenter-preview {
    width:100%;
    margin-top: 0px;
    padding-top: 0px;
    height: calc(100% - 165px);
    padding:0 35px;
}
.sd-action-bar .sd-btn{border:0 !important;padding:10px 40px !important;color: var(--color-text-btn) !important;}
/*.sd-action-bar .sd-btn:hover{border:0 !important}*/

#pageSelector .sv-action-bar-item__title:hover{color:#FFF !important;}
.svc-test-tab__content-actions button.sv-action-bar-item{background:#3f4c6b;}
/*.sv-action-bar-item svg{filter: brightness(0) saturate(100%) invert(99%) sepia(46%) saturate(2%) hue-rotate(18deg) brightness(107%) contrast(101%);}*/
.svc-logic-paneldynamic select{color:#000 !important;border:solid 1px #CCC !important;}
.svc-logic-paneldynamic .svc-logic-operator {color:#000 !important;height:inherit !important;font-size:13px !important;font-weight:normal !important;border:solid 1px #FBE9C9 !important;background:#FFF !important;}
#sq_653 .svc-logic-paneldynamic .svc-logic-operator{border:solid 1px #FFE2E2 !important;background:#FCF7F8 !important;}


.sl-table__cell .sv-action-bar-item__icon{display:block !important;}
.svc-creator__content-holder .svc-flex-column{margin:20px;}
.st-table__cell.st-table__cell--header{vertical-align:middle !important;font-size:14px !important;}
.st-comment{
    vertical-align: middle;
    font-size: 14px !important;
    border: solid 1px #CCC !important;
    padding: 2px 10px !important;
    border-radius: 5px;
}
.sv-string-viewer{font-size:13px;vertical-align: middle !important;}
.svc-side-bar__container-title{color:#FFF !important;}
.sv-action-bar-item--pressed {
background-color: #0d3e5b !important;
opacity: 1 !important;
}
.sv-action-bar .sv-action{margin-top:-1px !important;}
.spg-action-button--danger{background:#20c6c6 !important;}

.sidebarIcon{width:14px;}
/*.svgPosition{margin-left:0 !important;margin-right:22px !important;}*/
.homeMenu span{margin-right:12px;}
.primaryMenu span{margin-right:7px !important;/*margin-left:2px;*/}
.configIcon span{margin-right:7px !important;}
.headerFixed .dropdown-item{padding:9px 5px;border-radius:5px;}

.sd-question__title{
    text-align: var(--text-align-question) !important;
    font-weight: var(--text-weight-question) !important;
    text-decoration: var(--text-decoration-question) !important;
}
.sv-popup{z-index: 10;}
.svc-toolbox--compact .sv-action-bar-item svg{filter: var(--foreground-light, #90909f) !important;}
.sv-dots__item .sv-action-bar-item__icon svg{filter: var(--foreground-light, #90909f) !important;}

/*PREVISUALIACION MOVIL*/

.svd-simulator-content .sd-page{
max-width: 100% !important;
min-width: 100% !important;
padding:0 !important;
}
.svd-simulator-content .sd-body__page{
max-width: 100% !important;
min-width: 100% !important;
padding:0 !important;
}
/*.svd-simulator-content .svc-logic-question-value, div:only-child>.sd-element--with-frame:not(.sd-element--collapsed){
padding:0 !important;
}*/
/*.svd-simulator{padding:20px !important;box-sizing:border-box !important;}*/
.sd-title.sd-container-modern__title{min-width: inherit !important;max-width: inherit !important;}
.svc-logo-image{width:150px;}
.sd-title h3 span{font-size:18px !important;}
.sd-description h5{font-size:14px !important;}
.sd-description{cursor: pointer;}
.sd-body{padding-bottom:50px;}
/*.svc-logic-question-value, div:only-child > .sd-element--with-frame:not(.sd-element--collapsed){padding:10px !important;}*/
.mensajeBienvenida{font-size:14px;margin-bottom:10px;}
.mensajeBienvenida p{margin:0;margin-bottom:10px;}
.mensajeBienvenida p:last-child{margin-bottom:0;}
.titleBienvenida{font-size:14px;font-weight:bold;padding:15px 0;color:#000;position:relative;}

.mensajeFinalizacion{font-size:14px;margin-bottom:20px;margin-bottom:20px;display:none;}
.mensajeFinalizacion p{margin:0;margin-bottom:10px;}
.mensajeFinalizacion p:last-child{margin-bottom:0;}
.titleFinalizacion{font-size:18px;font-weight:500;margin-bottom:10px;}

.tabSelector{overflow:hidden;margin-bottom:40px;}
.tabSelector ul{list-style-type:none;padding:0;margin:0;/*border-top:solid 2px #0199ff;*/display:flex;text-align: center;}
.tabSelector li{padding:10px 26px;border-bottom:solid 1px #DADADA;cursor:pointer;flex-grow:1;font-weight:500;/*background:#F9F9F9;*/color:#666;}
.tabSelector li:hover{text-decoration:underline;}

.activeTab{color:#000 !important;/*border-left:solid 2px #0199ff;border-right:solid 2px #0199ff;border-top:solid 2px #0199ff !important;*//*background:#FFF !important;*/cursor:default !important;text-decoration: none !important;border-bottom:solid 3px #00a3fe !important;}
.selectCustom{
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%) inset;
    color: #555;
    padding: 6px 12px;
    display: block;
    margin-bottom:15px;
    width: 100%;
}
.textareaCustom{
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%) inset;
    color: #555;
    padding: 6px 12px;
    display: block;
    margin-bottom:15px;
    width:100%;
    min-height:150px;
}
.k-step-text{margin-top:0 !important;}

.svc-creator__content-holder{background:#F6F6F6 !important;}
.spg-panel__content{background:#F6F6F6 !important;}
.svc-flex-column{background:#F6F6F6 !important;}
.svc-toolbox__item-title{font-size:13px !important;color:#1e2125 !important;font-weight:500 !important;}
.svc-toolbox__item .sv-svg-icon{width:20px !important;height:20px !important;}
.svc-toolbox{padding:0 !important;}
.ruleHeader{font-size:13px;font-weight:500;}
.btn_back{display:inline-block;text-decoration:none;cursor:pointer;color:#0095ff;margin-right:5px;}
.btn_back:hover{text-decoration:underline;}
/*.scrollHerramientas{margin-top:10px;}*/

.popContainer .ifCondition{padding:0 !important;}

.ifContainer .k-input{border:solid 1px #CCC;background:#FFF;}
.ifContainer .addIc{border:solid 1px #CCC;background:#FFF;}
.ifContainer .removeIc{border:solid 1px #CCC;background:#FFF;}

.thenContainer .k-input{border: solid 1px #CCC;background: #FFF;}
.thenContainer .input-accion-click{border: solid 1px #CCC;background: #FFF;color:#000;}
.thenContainer .addIc{border: solid 1px #CCC;background: #FFF;}
.thenContainer .removeIc{border: solid 1px #CCC;background: #FFF;}
.thenContainer .input-accion-click::placeholder{color:#000;}
.scrollHerramientas .sizeLarge{border: solid 1px #DFDFDF;border-radius: 5px;}
#scrollableDiv-test{max-width:960px;margin:auto;padding:10px;background:#FFF;}
.warpper-theme{display:flex;flex-direction: column;align-items: center;}
.tab{cursor: pointer;padding:11px 20px;margin:0px 2px;flex-grow: 1;color:#666;background:#eeedf0;/*border-bottom:solid 1px #20c6c6;*/border-top-left-radius:5px;border-top-right-radius:5px;flex:1;}
.panels{background:#FFF;min-height:200px;width:100%;overflow:hidden;/*padding:20px;  */}
.panel{display:none;animation: fadein .8s;}
@keyframes fadein {
from {opacity:0;} to {opacity:1;}
}
.panel-title{font-size:1.5em;font-weight:bold;}
.radio{display:none;}
#one:checked ~ .contentPageRight .warpper-theme .panels #one-panel,
#two:checked ~ .contentPageRight .warpper-theme .panels #two-panel,
#three:checked ~ .contentPageRight .warpper-theme .panels #three-panel{display:block}
#four:checked ~ .contentPageRight .warpper-theme .panels #four-panel{display:block}
#one:checked ~ .contentPageRight .warpper-theme .tabs #one-tab,
#two:checked ~ .contentPageRight .warpper-theme .tabs #two-tab,
#three:checked ~ .contentPageRight .warpper-theme .tabs #three-tab{background:#104c4d;color:#FFF;}
#four:checked ~ .contentPageRight .warpper-theme .tabs #four-tab{background:#104c4d;color:#FFF;border-bottom:3px solid #20c6c6;}

#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel{display:block}
#four:checked ~ .panels #four-panel{display:block}
#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab{background:#104c4d;color:#FFF;}
#four:checked ~ .contentPageRight .warpper-theme .tabs #four-tab{background:#104c4d;color:#FFF;border-bottom:3px solid #20c6c6;}

.tabs{display:flex;width:100%;text-align: center;margin-bottom:10px;}
.contentPanel{overflow:hidden;padding:10px 0;/*max-width:750px;*/margin:auto;}
.svc-tabbed-menu-item{width: 150px;text-align: center;}
.sd-question--table .sd-table__cell--header{padding:0px !important;}
.sd-question--table .sd-table thead tr th .svc-matrix-cell{padding:0px !important;}
.sd-question--table .sd-table__cell{padding:0px !important;}
.sd-question--table .sd-matrix__cell:first-child, .sd-matrix tr > td:first-child {vertical-align:middle;}
.sd-question--table .sd-question--table > .sd-question__content{padding:0px !important;}
.sd-question--table .sd-question--table:not(.sd-element--with-frame){margin-bottom: 0 !important;padding-bottom: 0px !important;}
.sd-element__title span{font-size:13px !important;}
#show-detail{display:none;}
.sl-table__cell{padding-bottom:0 !important;}

.svc-flex-column{background:#FFF !important;}
#layout-wrapper::before{background:#FFF !important;}

.k-grid-pager{font-size:13px;}
.k-button{transition:none;}
/**/
.btnControl{
transition: all 0.25s ease;
padding:8px 14px;
min-width:100px;
border-radius:5px;
background:#0084f9;
}
.btnControl:hover{
background: #0078d3;
transition: all 0.25s ease;
}
/*.k-command-cell .k-button:hover, .k-button:active{background:#295095 !important;}*/
.k-grid tbody tr:not(.k-detail-row):hover, .k-grid tbody tr:not(.k-detail-row).k-state-hover{background-color: #e9f5ff;}

.k-form .btnNewForm{
transition: all 0.25s ease;
padding:8px 14px;
border-radius:3px;
background:#0084f9;
}
.k-form .btnNewForm:hover{
background: #0078d3;
transition: all 0.25s ease;
}

.spg-panel__content{padding:0 calc(4 * var(--base-unit, 8px)) calc(5 * var(--base-unit, 8px)) !important;}
.k-icon-button.k-button-md{padding:0 !important;}
.icPrevisualizar{background:url(../images/grid/search.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.icTrack{background:url(../images/grid/ic_track.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.icEdit{background:url(../images/grid/edit.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.icDocument{background:url(../images/grid/document.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.icShare{background:url(../images/grid/share.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.more-menu-formulario{margin-left:8px;}

.main-content-survey .icDocument{background:url(../images/grid/document.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icPrevisualizar{background:url(../images/grid/search.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icTrack{background:url(../images/grid/ic_track.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icEdit{background:url(../images/grid/edit.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icDocument{background:url(../images/grid/document.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icShare{background:url(../images/grid/share.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.main-content-survey .icDelete{background:url(../images/general/iconos/ic_delete.svg) no-repeat !important;background-size:contain !important;padding:8px;}

.k-grid .k-command-cell > .k-button{
   filter: invert(49%) sepia(4%) saturate(15%) hue-rotate(317deg) brightness(94%) contrast(86%);
}

.k-command-cell .k-button:hover{
 filter: invert(39%) sepia(42%) saturate(2725%) hue-rotate(188deg) brightness(94%) contrast(104%);
}

.navbar-brand-box{transition: all 0.25s ease;padding:21px 20px;padding-bottom:18px;cursor:pointer;}
.navbar-brand-box:hover{background:#168b8b;transition: all 0.25s ease;}
/*.showLeftControls .navbar-brand-box{background:#168b8b;}*/

.topMenu{transition: all 0.25s ease;}
.topMenu:hover{background:#f6f6f6;transition: all 0.25s ease;background:#168b8b !important;}
.toolList li img {
 filter: invert(50%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(93%) contrast(93%);
}
.primaryMenu img{filter:none !important;}
.configIcon img{filter: invert(50%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(93%) contrast(93%) !important;}

.dropdown-item:hover, .dropdown-item:focus{background:#f5f6fa;}
.dropdown-item.active{
    border-radius:5px;
    color:#FFF;
    background: #00a3fe;
    background: -moz-linear-gradient(top, #00a3fe 0%, #0084f9 100%);
    background: -webkit-linear-gradient(top, #00a3fe 0%,#0084f9 100%);
    background: linear-gradient(to bottom, #00a3fe 0%,#0084f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a3fe', endColorstr='#0084f9',GradientType=0 );
}

.dropdown-item.active img{filter:invert(1);}
.resizeIcon{width:12px;padding:0 2px;}
.contentPageLeft{padding:0 10px;}
.centerControls{text-align: center;}

.k-grid .k-command-cell{text-align: center;}
/*.sidebarMenuMin .resizeIcon{width:18px;}*/
.k-step-list-horizontal .k-step-indicator + .k-step-label {margin-top: 3px;}
.sidebarMenuMin .homeMenu span{margin-right:0 !important;}
.sidebarMenuMin .sidebarMenu ul li span{margin:0 !important;position:relative;}
.sidebarMenuMin .configIcon span{margin:0 !important;}
.sidebarMenuMin .primaryMenu span{margin:0 !important;}
.sidebarMenuMin .primaryMenu{background-size:8px;background-position:right 15px;}
.sidebarMenuMin .sidebarMenu ul{margin-left:0;}
.sidebarMenuMin .toolList li span{position:relative;}
.sidebarMenuMin .homeMenu{padding-left:0;}
.sidebarMenuMin .primaryMenu{padding-left:0 !important;}
.sidebarMenuMin .sidebarIcon{width:16px !important;}

.logo{line-height: normal;}

.svc-page__content:not(.svc-page__content--new):focus{background:none !important;}
.svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected{background:none !important;}
.svc-hovered.svc-page__content:not(.svc-page__content--new){background:none !important;}

.svc-question__content{box-shadow:0px 0px 5px rgb(0 0 0 / 15%) !important;}
.sv-action-bar-item:hover {background:#F1F1F1 !important;}
.sv-action-bar-item--pressed {background-color:#F1F1F1 !important;opacity: 1 !important;border: solid 1px #e1e1e1 !important;}

.svc-question__content-actions .sv-action-bar-item{  fill: var(--secondary, #20c6c6) !important;}
#icon-switch-inactive_16x16{filter: brightness(0) saturate(100%) invert(51%) sepia(80%) saturate(4207%) hue-rotate(194deg) brightness(103%) contrast(101%);}

/*.svc-question__content .sd-element{padding-bottom: 40px !important;}*/
.svc-question__content{padding-bottom:60px !important;}

.sd-progress-buttons__list li{padding-bottom:16px !important;margin:10px 5px;flex-basis: 100%;padding-top:5px !important;padding-bottom:5px !important;}
/*.sd-progress-buttons__list .sd-progress-buttons__list-element--current{border-color:#f5f5f5 !important;}*/
.sd-progress-buttons__list-element--current .sd-progress-buttons__page-title{color:#000 !important;}
.sd-title.sd-container-modern__title{box-shadow: 0px 2px 0px #0188fb !important;}
.sd-progress-buttons__page-title{white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.sd-progress-buttons__list li{white-space: nowrap;text-overflow: ellipsis;min-width: 0;}
.sd-progress-buttons__list{/*background:#e9f4ff;*/background:#FFF;}
.svc-page{margin-top:0 !important;}
.sd-title.sd-container-modern__title{padding-left:0 !important;padding-right:0 !important;}
.svc-page__add-new-question{margin-top:20px;}
.svc-btn .svc-text{padding:10px;}
/*.svc-side-bar__container-actions .sv-action-bar .sv-action-bar-item:hover{background:none !important;}*/
.svc-toolbar-wrapper .sv-action-bar-item:hover{background:none !important;}
.imageTextList{color:#000;font-weight:bold;font-size:14px;margin-top:5px;margin-bottom:5px;}
.contentBox p{color:#666;line-height: 18px; font-size: 12px;}
.activeTheme .txtEditTheme a{color:#006fe1 !important;}
.btn:disabled {
 background: #f3f3f3 !important;
 opacity: 0.6;
 color: #6c6c6c !important;
 border: solid 1px #ccc;
 text-shadow: 0 0 #f1f1f1;
 border-color:transparent !important;
}
.btn{line-height:normal !important;padding:7px 12px !important;}
.justify-content-between {justify-content:left !important;}

.btnEditProduct .icEdit {
    background-size: 14px !important;
    padding: 8px;
    /*background-position: 0px 8px !important;*/
    filter: opacity(0.5);
}

.btnDeleteProduct .icDelete {
    background:url(../images/general/iconos/ic_delete.svg) no-repeat !important;
    background-size: 14px !important;
    padding: 8px;
    /*background-position: 0px 8px !important;*/
    filter: opacity(0.5);
}

.btnEditProduct .icEdit:hover{filter: invert(43%) sepia(82%) saturate(2288%) hue-rotate(134deg) brightness(98%) contrast(98%);}
.btnDeleteProduct .icDelete:hover{filter: invert(35%) sepia(63%) saturate(6216%) hue-rotate(340deg) brightness(92%) contrast(98%);}

.btnNuevo .k-i-plus{margin-top:-2px;}
.btn:disabled.btnDeleteProduct .icDelete {opacity: 0.5;}
.btn:disabled.btnEditProduct .icEdit {opacity: 0.5;}
.btn-success{border:solid 2px #20c6c6 !important; border-radius: 0.25rem !important;line-height: 18px !important;color:#20c6c6 !important;box-sizing: border-box;}
.btn-success:hover{background: #168b8b !important;transition: all 0.35s ease;color:#FFF !important;}
.btn-success:hover i{filter:invert(1) !important;}
.btn-success .icEdit{filter:invert(63%) sepia(99%) saturate(358%) hue-rotate(128deg) brightness(86%) contrast(94%);}
.btn-danger{background:#20c6c6 !important;border:0 !important;border-radius: 0.25rem !important;line-height: 18px !important;color:#FFF !important;box-sizing: border-box;}
.btn-danger:hover{background:#168b8b !important;transition: all 0.35s ease;color:#FFF !important;}
.btn-danger:hover i{filter:invert(1) !important;}
.btn-danger .icDelete{filter:invert(63%) sepia(99%) saturate(358%) hue-rotate(128deg) brightness(86%) contrast(94%);}
.btnNuevo{line-height: 18px !important;}
.btnNuevo:hover{background-color:#0078d3 !important;}

.form-label{padding-top:10px;font-weight:600;}

.svd-simulator-content .sd-btn{border:solid 1.5px #0481f7 !important; color:#0088fa !important;font-size:13px !important;background:#FFF !important;box-shadow: 0px 1px 2px rgb(0 0 0 / 15%) !important;padding:6px 25px !important;}
.svd-simulator-content .sd-btn:hover{background:#FFF !important;box-shadow: none !important;border:solid 1.5px #0481f7 !important;}
.svc-test-tab__content.svc-creator-tab__content--with-toolbar .sd-body__navigation{justify-content:center;}

.sd-progress-buttons__list .sd-progress-buttons__list-element--passed {border-top: 1.5px solid blue #CCC;}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages{display:none;}

.__toolbarContainer{overflow:hidden;}
.__toolbar{display:flex;flex-wrap:wrap;padding:5px;border:solid 1px #CCC;border-radius:5px;padding-bottom:0;}
.__toolbar:hover{border:solid 1px #20c6c6;}
.__toolbar-item{background:#efefef;border:0;border-radius:3px;cursor:pointer;margin-right:7px;margin-bottom:5px;min-width:30px;padding:5px;}
.__toolbar-item:hover, .__toolbar-item.active {background:#8fd1d1;color:#FFF;}
.__editor {background:#ffffff;border:solid 1px #CCC;color:#000000;margin-top:10px;overflow:auto;padding:10px;border-radius:5px;}
[data-tiny-editor] {height: 200px;}

.tagSelector{padding:5px;border:solid 1px #CCC;border-radius:5px;margin-top:10px;padding-bottom:0;}
.tagSelector ul{list-style-type:none;padding:0;margin:0;overflow:hidden;display:flex;flex-wrap:wrap;}
.tagSelector li{margin-right:5px;margin-bottom:5px;}
.tagSelector div{background:#20c6c6;color:#FFF;padding:5px 8px;border-radius:5px;cursor:pointer;}
.tagSelector div:hover{background:#168b8b;}
/*syle2*/
.style2 #page-topbar{background:#104c4d !important;}
.style2 .header-item span{color:#FFF;}
.style2 .navbar-header i{color:#FFF !important;}
.style2 .navbar-brand-box img{filter:none;}
.style2 .open-feed.inactiveBell svg{fill:#FFF !important;}
.style2 .d-lg-none{display:none !important;}

.style2 .themeList2{padding:10px;}
.style2 .btnNewForm{border:solid 2px #20c6c6;position:relative;color: #FFF;background:#20c6c6;}
.style2 .btnNewForm:hover{border-color:#20c6c6;color:#FFF;background-color:#168b8b;}
/*.style2 .btnNewForm::after{content:"";display:block;position:absolute;top:50%;left:0;transform:translateY(-50%);background-image:url(../images/general/add_ic.png);background-repeat:no-repeat;background-position:center center;width:16px;height:16px;
margin-left:8px;
filter:invert(74%) sepia(82%) saturate(475%) hue-rotate(121deg) brightness(190%) contrast(133%);
}
*/
.style2 .dropdown-item.active{background:#20c6c6 !important;color:#FFF;}
.style2 .k-grid-header .k-header{background:#20c6c6;color: #FFF !important;}
.style2 .k-grid-header .k-column-title{font-weight: 600 !important;}
.style2 .k-grid-column-menu{color:#FFF !important;border-radius:5px;}
.style2 .k-grid-header .k-grid-filter:hover, .k-grid-header .k-header-column-menu:hover{background:#1a9d9e !important;}
.style2 .dropdown-item:hover{background:#e2f9f9;}
.style2 .k-stepper{background:#FFF !important;}

/*
PSA
.style2 .k-grid tbody tr:not(.k-detail-row):hover, .k-grid tbody tr:not(.k-detail-row).k-state-hover {background-color:#eeedfd;}
.style2 .k-grid tbody tr:not(.k-detail-row):hover{background: none !important;}*/
.style2 .k-pager-numbers .k-link.k-state-selected{background:#20c6c6 !important;}
.style2 .k-command-cell .k-button:hover {filter: invert(86%) sepia(11%) saturate(3147%) hue-rotate(128deg) brightness(80%) contrast(94%);}
.style2 .btn-primary{border:solid 2px #20c6c6 !important;color:#FFF !important;background:#20c6c6 !important;transition: all 0.5s ease;transform: translate(0, 0);font-size: 12px !important;}
.style2 .btn-primary:hover{background:#168b8b  !important;transition: all 0.35s ease;color:#FFF !important;}
.style2 .btn-light{font-size: 12px !important;}

.style2 .k-grid tr.k-state-selected > td {background-color:rgba(55, 180, 0, 0.32) !important;}
.style2 .k-grid tr.k-selected > td {background-color:rgba(55, 180, 0, 0.32) !important;}
.style2 .k-dialog-titlebar{background:#20c6c6;}
.style2 .chase-dot:before{background:#20c6c6;}

.style2 .k-form .btnNewForm{color: #FFF;background:#20c6c6;transition: all 0.5s ease;transform: translate(0, 0);}
.style2 .k-form .btnNewForm:hover{background:#168b8b !important;transition: all 0.35s ease;}

.style2 .txtDefault{color:#20c6c6;}
.style2 .imageDefault img{filter: hue-rotate(323deg);}
.style2 .k-stepper .k-step-current .k-step-label{color:#168b8b;}
.style2 .k-stepper .k-step-current .k-step-indicator{background:#168b8b;border:solid 2px #FFF;}
.style2 .k-stepper .k-step-done .k-step-indicator{background:#168b8b;border: solid 2px #FFF;}

.style2 .svc-top-bar{background: #20c6c6 !important;}

.style2 .svc-side-bar__container-header{background: #20c6c6 !important;}


.style2 .btnControl{border: solid 2px #20c6c6;color: #20c6c6;background:#FFF;transition: all 0.5s ease;transform: translate(0, 0);font-weight: 500;}
.style2 .btnControl:hover{background:#20c6c6 !important;color:#FFF;transition: all 0.35s ease;}
.style2 .svc-creator .svc-page:hover{box-shadow: 0 0 0 2px var(--secondary, #FFF) !important;}
.style2 .svc-creator .svc-page .svc-question__content--selected:not(.svc-question__content--dragged){box-shadow: 0 0 0 2px var(--secondary, #FFF) !important;}
.style2 .svc-creator .svc-page .svc-question__content--selected:not(.svc-question__content--dragged){box-shadow: 0 0 0 2px var(--secondary, #FFF) !important;}
.style2 .svc-creator .svc-page .svc-hovered>.svc-question__content--selected:not(.svc-question__content--dragged){box-shadow: 0 0 0 2px var(--secondary, #FFF) !important;}
.style2 .svc-btn{box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);border:solid 2px transparent;}
.style2 .svc-btn:hover{border:solid 2px #20c6c6;}
.style2 .svc-btn .svc-text{color:#168b8b !important;cursor:pointer;}
.style2 .sd-title.sd-container-modern__title {box-shadow: 0px 2px 0px #CCC !important;}
.style2 .sv-button-group__item--selected{color:#20c6c6 !important;}
.style2 .svc-tabbed-menu-item--selected{color:#FFF !important;}
.style2 .svc-tabbed-menu-item--selected:hover{color:#FFF !important;}
.style2 .svc-tabbed-menu-item--selected .svc:hover{color:#FFF !important;}
.style2 .svc-tabbed-menu-item:hover{color:#FFF !important;}
/*.style2 .svc-tabbed-menu-item:hover{background-color:inherit !important;}*/
.style2 .sv-action-bar-item:hover{background-color:#168b8b !important;color:#FFF !important;filter:none !important;}
.style2 .sv-action-bar-item:hover span{color:#FFF !important;}
.style2 .svc-tabbed-menu-item-container .sv-dots__item{background-color:#168b8b !important;border:0 !important;}
.style2 .svc-tabbed-menu-item--selected{background:#F3F3F3 !important;}
.style2 .svc-tabbed-menu-item--selected .svc-text{color:#666 !important;}
.style2 .svc-tabbed-menu-item:hover{background:#168b8b;}

.style2 .k-progressbar .k-state-selected{border-color:#FFF;background:#FFF;}
.style2 .controlsRule li:hover img{filter:brightness(4);}
.style2 .addRule{border: solid 2px #20c6c6;color: #20c6c6;background:#FFF;transition: all 0.5s ease;transform: translate(0, 0);font-weight:500;}
.style2 .addRule:hover{background:#20c6c6 !important;color:#FFF;transition: all 0.35s ease;}
.style2 .if{background:#14494d;}
.style2 .then{background:#14494d;}
.style2 .ruleHeader{background:#FFF;color:#000;font-weight:bold;}
.style2 .ruleTab{border-color:#20c6c6;}
.style2 .labelRule{background:#20c6c6;}
.style2 .btnAdd{background:#20c6c6;transform: translate(0, 0);}
.style2 .btnAdd:hover{background:#168b8b !important;transition: all 0.35s ease;}
.style2 .createTheme{border: solid 2px #20c6c6;color: #20c6c6;background:#FFF;transition: all 0.5s ease;transform: translate(0, 0);}
.style2 .createTheme:hover{background:#20c6c6 !important;color:#FFF;transition: all 0.35s ease;}
.style2 .activeTheme{background:#FFF !important;border-color:#20c6c6 !important;border-width:1px !important;transition: all 0.35s ease;}
.style2 .txtEditTheme a{color:#00a096 !important;}
.style2 .imageThemeList img{filter:hue-rotate(320deg);}
.style2 .themeList2 li{border:solid 1px #DFDFDF;}
.style2 .btnGaleriaTemas{border: solid 2px #20c6c6;color: #20c6c6;background:#FFF;transition: all 0.5s ease;transform: translate(0, 0);font-weight:500;}
.style2 .btnGaleriaTemas:hover{background:#20c6c6 !important;color:#FFF;transition: all 0.35s ease;}
.style2 .btn_back{color:#168b8b;}
.style2 .k-stepper .k-step-indicator::after{display:none !important;}
.style2 .k-stepper .k-step-current .k-step-label .k-step-text{font-weight:600 !important;}
.style2 .k-stepper .k-step-list {color: #b8c2cc;font-weight: 500;}
.style2 .topMenu {background:#104c4d;}
.style2 .topMenu img{filter:none;}
.style2 .svd-simulator-content .sd-btn{border:solid 2px #20c6c6 !important;color:#20c6c6 !important;}
.sidebarMenuMin .dropdown-item{padding:6px 0 !important;}

.navbar-brand-box{display:block;}
.open-feed{top:17px !important}
.contentPageLeft .ocultarMenu{transform: none;}
.contentPageLeft{transition: all 0.2s ease-in;transform: translateX(-200px);}

.sidebarIcon2{width:16px;margin-left:-2px;}
.formIcon{margin-left:-1px;}
.flagIcon img{margin-top:0px !important;}
.sv-dots{width:43px !important;}
.style2 .sv-action-bar-item:hover svg{filter: brightness(10) !important;}
.editBtn{background:url(../images/grid/edit.svg) no-repeat !important;;width:24px;height:24px;filter:invert(1);background-size:16px !important;background-position:center !important;}
.copyBtn{background:url(../images/grid/layers.svg) no-repeat !important;width:24px;height:24px;filter:invert(1);background-size:16px !important;background-position:center !important;}
.delBtn{background:url(../images/grid/delete.svg) no-repeat !important;width:24px;height:24px;filter:invert(1);background-size:16px !important;background-position:center !important;}
.k-selected{background:#20c6c6}
.shareContainer{display:flex;}
.shareContent{flex-grow:1;margin-right:10px;/*border:solid 1px #20c6c6;*/border-radius:5px;text-align:center;width:50%;border:0;}
.toast-success {border: 2px solid #20c5c6 !important;background-color:#20c5c6 !important;}
.toast-error {border: 2px solid #f46a6a !important;background-color: rgba(244, 106, 106, 1) !important;}
.toast{opacity:1 !important;margin:auto !important;}
#toast-container{z-index:9999999999 !important;left:50%;transform:translateX(-50%);right:0;}
/*step bar dark*/
.darkTopBar .k-stepper{background:#104c4d !important;}
.darkTopBar .k-progressbar{background:#126869 !important;}
.darkTopBar .k-stepper .k-step-indicator{border-color:#126869 !important;color:#FFF !important;background-color:#126869 !important;}
.darkTopBar .k-stepper .k-step-current .k-step-label {color:#FFF !important;}
.darkTopBar .k-stepper .k-step-list{color:#ffffff !important;}
.darkTopBar .k-stepper .k-step-done .k-step-indicator {background:#168b8b !important;border: solid 2px #FFF !important;}
.darkTopBar .k-stepper .k-step-current .k-step-indicator{border:solid 2px #FFF !important;color:#FFF !important;}
.darkTopBar .k-step-link a span:hover{color:#FFF !important;}

.loginPage .left-content{width:372px;height:446px;background:#20c6c6 !important;}
.loginPage .right-content{width:460px;}
.loginPage .container{width: 856px !important;max-width: initial !important;}
.loginPage .btn-login{width:280px !important;}
.loginPage .left-content h1{font-size: 24px;color: #FFF;font-weight: normal;margin: 40px 0;margin-bottom: 40px;display:block;padding: 10px;border-radius: 5px;margin-bottom: 0;}
.loginPage .txtcontent{font-weight:500;font-size:14px;}
@media only screen and (max-width: 960px){
.loginPage .container{width: auto !important;margin: auto auto;max-width: 500px !important;}
.loginPage .left-content{width:auto;height:auto;float:none;padding:20px 30px;}
.loginPage .right-content{width:auto;height:300px;background-size:240px;float:none;}
.loginPage .left-content h1{margin-top:20px;}
.loginPage .txtcontent{margin:30px 0;padding-bottom:0;}
.loginPage .btn-login{width:100% !important;max-width: 280px !important;}
}


/*PSA*/
.preview-enc-left{
    float: left;width: 40%;
}
.preview-enc-right{
    float: right;margin-left: 20px;width: 40%;
}
.table-preview-enc{
    display: table;
    width: 100%;
}
.row-preview-enc{
    display: table-row;
    width: auto;
    clear: both;
}
.column-preview-enc{float: left;display: table-column;width: 45%;white-space: pre-wrap;padding-bottom: 15px;}
.title-preview-enc{font-weight: bold;margin-top: 10px;margin-bottom: 10px;background: #104c4d;color: #FFF !important;padding: 14px;border-radius: 5px;}
.k-menu-link-text{color:#000 !important;}
.k-menu-link.k-selected{background:#20c6c6 !important;}
.column-preview-enc-10{width: 10%;float: left;display: table-column;white-space: pre-wrap;/*padding-bottom: 15px;*/}
.client-survey{padding: 10px;/*height:100% !important;*/}
.icFlag{background:#FFF !important;border:solid 1px #333 !important;padding:6px;border-radius:2px;}
.icFlagBlock{background:url(../images/general/iconos/flag-selected.svg) no-repeat !important;background-size:contain !important;padding:8px;}
.warpper-theme-feedback .tabs{width: 550px;}
.warpper-theme-feedback .contentPanel{max-width: 100%;padding-left: 0px;}
.grid_gestion_ticket{height: calc(100vh - 291px);}
.sd-logo__image{width:100%;max-width:110px;}
.br-widget{text-align: center;}
/*rating*/
/*.svd-simulator .br-theme-css-estrellas .br-widget a{float:left;margin-bottom:5px;margin-right:5px;}*/
/*.svd-simulator .br-theme-css-estrellas .br-widget a{
float: none;margin-bottom: 5px;margin-right: 0px;display: block;text-align: center;width: 100% !important;}*/
.svd-simulator .br-theme-css-estrellas .br-widget a{margin-bottom: 5px;
    margin-right: 0;
    display: inline-block;
    width: 10% !important;
}
.svd-simulator .br-widget{overflow:hidden !important;}


.dropdown-item.active, .dropdown-item:active {background-color:#20c6c6 !important;}
.dropdown-item:active img {filter:invert(1);}
.breadcrumb-item a{color:#666 !important;}
.breadcrumb-item a:hover{color:#20c6c6 !important;}

.k-tooltip .k-callout{left:25px !important;color:#104c4d;}
.k-tooltip{background:#104c4d;border-color:#104c4d;}

/*Preview*/

.client-survey{height: 100vh;/*background:#e4f1f1;*/}
.client-survey .row-preview-enc{/*box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);*/margin: 10px 0; display: block;overflow: hidden;padding-bottom:10px;
border-bottom:solid 1px rgba(0, 0, 0, 0.08);
}
.client-survey .column-preview-enc{padding:0px;}
.client-survey .table-preview-enc{width:100%;margin:auto;border:solid 1px rgba(0, 0, 0, 0.08);padding:20px 30px;border-radius:6px;box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);background:#FFF;}
.client-survey .title-preview-enc{text-align:center;margin-bottom:20px;font-size:16px;color:#495057;}
.sd-table__question-wrapper{margin-bottom:20px;}
.client-survey .column-preview-enc{float:left;/*width: 100%;*/display: block; white-space: normal;padding-bottom:0;}
.client-survey .row-preview-enc .column-preview-enc:first-child{font-weight:bold;margin-bottom:8px;color:#000;}
.client-survey .row-preview-enc:last-child{border:0;}

@media only screen and (max-width:650px){
.client-survey .table-preview-enc{width:auto;padding:20px;}
.client-survey{padding:20px;}
}

.textarea-theme{border: 0px !important;margin: 0px !important;padding: 0px !important;}
.textarea-theme:focus{box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08) !important;}
.textarea-theme:focus-within{box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08) !important;}
.herramientas-tema-one{display: none;}
.herramientas-tema-two{display: none;}
.herramientas-tema-three{display: none;}
#one:checked ~ .contentPageLeft-theme .herramientas-tema-one,#two:checked ~ .contentPageLeft-theme .herramientas-tema-two, #three:checked ~ .contentPageLeft-theme .herramientas-tema-three {display: block;}
.psa-tmp{top:50%;right:0;margin-top:0;transform:translateY(-50%) !important;}

.homeMenu img{width:16px !important;}
.configIcon img{width:16px !important;}
.trashIcon{width:14px !important;}
.homeMenu img{margin-left:0 !important;}
/*.flagIcon{margin-top:11px !important;}*/
.text-danger{color:inherit !important;}


.combo-preguntafinal{width: 100%;height: 35px;}
.grid_configuracion{max-height:calc(100vh - 208px);min-height: calc(100vh - 208px);}
.k-window-content-height .k-window-content{min-height: 350px;}
.k-window-content-heightusrloc .k-window-content{min-height: 250px;}

.sizeDateCreate{flex:none !important;width:30% !important;}
.form-control{font-size:14px !important;}
.k-window-title{font-weight:600;font-size:14px;}
input.k-input, textarea.k-textarea{font-size:14px;}
.modal-footer{border:0 !important;}

.toolsTxtList{overflow:hidden;}
.toolsTxtList ul{list-style-type:none;padding:0;margin:0;overflow:hidden;margin-top:5px;}
.toolsTxtList li{float:left;margin-right:4px;margin-bottom:4px;}
.toolsTxtList .k-icon-button.k-button-md{padding:4px !important;}
.toolsTxtList .k-button-solid-base.k-selected{border-color:#104c4d7a;}
.topMargin{margin-top:8px;}
.k-button-md{font-size:12px !important;/*padding:5px 8px;*/}
.gridAlignRight{width: 100%;display: block;text-align:right;}
.spaceRight{margin-right:5px;padding-left:24px !important;}
.spaceRight i{position:absolute;top:0;left:0;margin:6px 4px;}
.exportIcon{padding-left:24px !important;line-height:normal;padding-top:7px !important;padding-bottom:7px !important;}
.exportIcon i{position:absolute;top:0;left:0;margin:6px 4px;}

.main-content-survey{padding-right: 23px !important;}
.btn-primary:focus{box-shadow: none !important;}
.btn:focus{box-shadow: none !important;}
.editCatalogo{border:solid 2px #20c6c6 !important;color: #FFF !important;background: #20c6c6 !important;transition: all 0.5s ease;transform: translate(0, 0);}
.editCatalogo:hover i{filter:invert(0) !important;}
.deleteCatalogo:hover i{filter:invert(0) !important;}

.editListaProducto{border:solid 2px #20c6c6 !important;color: #FFF !important;background: #20c6c6 !important;transition: all 0.5s ease;transform: translate(0, 0);}
.editListaProducto i{filter:invert(0) !important;}
.editListaProducto:hover i{filter:invert(1) !important;}
.deleteListaProducto i{filter:invert(0) !important;}

.editLocalidad{border:solid 2px #20c6c6 !important;color: #FFF !important;background: #20c6c6 !important;transition: all 0.5s ease;transform: translate(0, 0);}
.editLocalidad i{filter:invert(0) !important;}
.deleteLocalidad i{filter:invert(0) !important;}
.editLocalidad:hover i{filter:invert(0) !important;}
.deleteLocalidad:hover i{filter:invert(0) !important;}

.editEstilo{border:solid 2px #20c6c6 !important;color: #FFF !important;background: #20c6c6 !important;transition: all 0.5s ease;transform: translate(0, 0);}
.editEstilo i{filter:invert(0) !important;}
.editEstilo:hover i{filter:invert(0) !important;}
.deleteEstilo i{filter:invert(0) !important;}
.deleteEstilo:hover i{filter:invert(0) !important;}

.form-label{font-size:13px;}
.k-window-content .text-muted {min-height:34px;}
.spaceTop{margin-top:10px;}
.k-dialog-buttongroup{padding:10px 123px;border-top:0;}

.form-control:focus{box-shadow:none !important;border-color:#909090 !important;}
.k-filtercell .k-icon-button.k-button-md{padding:4px !important;}
.k-button-solid-primary:focus{box-shadow:none !important;}
.k-window-content .shareContainer{display:block;}
.k-window-content .shareContent{width:auto;margin-right:0;margin-bottom:10px;border:0;}
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
fill: var(--primary, #20c6c6) !important;}
.sv-list__item:hover use{fill: var(--primary, #20c6c6) !important;}
.svc-page-navigator-item--selected .svc-page-navigator-item__dot{border-color:#20c6c6 !important;}
.svc-question__adornar .svc-hovered{border:1px solid #20c6c6 !important;}
.svc-hovered{border:1px solid #20c6c6 !important;}
.svc-question__adornar{border:1px solid #20c6c6 !important;}
.svc-logic-operator.svc-logic-operator--error {box-shadow:inset 0 0 0 1px #333 !important;}
.sd-btn{background:#F00 !important;}

.btnIcon{padding-left:25px !important;position:relative;}
.btnIcon i{position:absolute;top:50%;left:0;transform:translateY(-50%);margin-top:0 !important;margin-left:6px;}

/*.sd-action-bar .sd-btn:hover{background: #20c6c6 !important; color: #FFF !important;transition: all 0.35s ease;}*/
.svd-simulator-content .sd-action-bar{margin-top:30px;}

/*estilos navegacion encuesta*/
.styleNav2 .sd-progress-buttons__list li{border-top:10px solid #e5e5e5 !important;margin:10px 5px;padding:0 !important;border-radius:5px;}
.styleNav2 .sd-progress-buttons__list .sd-progress-buttons__list-element--current{border-top:10px solid #20c6c6 !important;}

.styleNav2 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed{border-top:10px solid #168b8b !important;}
.styleNav2 .sd-progress-buttons__page-title{display:none;}

.styleNav3 .sd-progress-buttons__list{background:#f5f5f5;overflow: hidden;}
.styleNav3 .sd-progress-buttons__list li{margin:0;position:relative;border:0 !important;padding-left:20px;}
.styleNav3 .sd-progress-buttons__list li:first-child{padding-left:10px !important;}
/*.styleNav3 .sd-progress-buttons__list li:last-child{padding-left:10px !important;}*/
.styleNav3 .sd-progress-buttons__list-element--current{background:#20c6c6;}
.styleNav3 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title{color:#FFF !important;}
.styleNav3 .sd-progress-buttons__list .sd-progress-buttons__list-element--passed{background:#20c6c6;color:white;border:0;}
.styleNav3 .sd-progress-buttons__list li:after{
    position: absolute;
    top: 50%;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 33px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
    z-index: 1;
    transform:translate(0px, -50%);
    border-left-color:#FFF;
    margin: 0;
}

.styleNav3 .sd-progress-buttons__list li .sd-progress-buttons__page-title:after{
    position: absolute;
    top: 50%;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 33px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
    z-index: 2;
    transform:translate(-2px, -50%);
    border-left-color:#f5f5f5;
    margin: 0;
}

.styleNav3 .sd-progress-buttons__list-element--current .sd-progress-buttons__page-title:after{
    border-left-color:#20c6c6 !important;

}

.styleNav3 .sd-progress-buttons__list-element--passed .sd-progress-buttons__page-title:after{
    border-left-color:#20c6c6 !important;
}


.styleNav4 .sd-progress-buttons__list li{position:relative;margin:10px 0;}
.styleNav4 .sd-progress-buttons__list li::before{
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 0;
    background:#FFF;
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    margin-top: -13px;
    border:solid 2px #E5E5E5;
    z-index:1;
}

.styleNav4 .sd-progress-buttons__list li:first-child::after{
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    top: 0;
    background: #FFF;
    position: absolute;
    left: 0;
    margin-top: -2px;
}
.styleNav4 .sd-progress-buttons__list li:last-child::after{
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    top: 0;
    background: #FFF;
    position: absolute;
    right:0;
    margin-top: -2px;
}

.styleNav4 .sd-progress-buttons__list-element--current::before{background:#168b8b !important;border-color:#FFF !important;}
.styleNav4 .sd-progress-buttons__list-element--passed::before{background:#168b8b !important;border-color:#FFF !important;}


.fin_encuesta{text-decoration: none !important;}
.fin_encuesta span{margin:30px 0 !important;display:block;line-height:20px;text-decoration: none !important;}
.reiniciarBtn{border: solid 2px #20c6c6 !important;color: #FFF !important;background: #20c6c6 !important;padding: 7px 12px !important;
font-size: 0.8125rem !important;border-radius: 0.25rem !important;    transform: translate(0, 0);}
.reiniciarBtn:hover{background: #168b8b !important;transition: all 0.5s ease;transform: translate(0, 0);}

.fin_encuesta::before{
content:'';
display:block;
width:100px;height:100px;background-size:contain;margin:auto;
background:url(../../assets/images/endAnimationStop.gif) no-repeat;
background-size: contain;
}
.fin_encuesta span .fin_encuesta::before{display:none;}

.svd-test-results{display:none;}



@media only screen and (max-width:650px){
.main-content-survey{width:calc(100% - 20px) !important;padding-right:0 !important;}
.justify-content-between{justify-content:left !important;}
.k-window{width:85% !important;max-width:500px;}
.showLeftControls .main-content-survey{margin-left:15px !important;width:calc(100% - 20px) !important;}
.ifContainer span{margin-bottom:10px;}
.ifCondition span{margin-bottom:10px;}
.ifCondition-margin {margin-left: 0;}
.ifCondition .size-m{width:calc(100% - 10px);}
.ifCondition .combo-regla{width:calc(100% - 10px);}
.ifCondition .addIc{margin-left:0;}
.ifCondition .size-l{width:calc(100% - 10px);margin-left:0;}
.ifCondition{overflow:hidden;clear:both;}
.style2 .themeList2{padding:0;}
.txtEditTheme{line-height:normal;}
.sd-progress-buttons__page-title{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.sd-progress-buttons__list-container{margin:20px 0;}
.svc-tabbed-menu-item{display:none;}
.ifContainer .k-input{width: calc(100% - 10px) !important;}
.if{margin-bottom:10px;}
.style2 .btnControl{font-weight:bold;}
.style2 .addRule{margin-top:0;}
.style2 .createTheme{margin-top:-10px;}
/*.br-theme-css-manos .br-widget a:after{width:28px !important;height:27px !important;}*/
}

.k-pager-numbers .k-link{
    color: #104c4d !important;
    background: #E9E9E9;
    border-radius: 5px;
    margin-right: 5px;
}
.k-link .k-selected{
    background: #20c6c6 !important;
}
.k-pager-numbers .k-link.k-selected {
    background: #20c6c6 !important;
    color: #FFF !important;
}
.k-progressbar .k-state-selected, .k-progressbar .k-selected {
    border-color: #fff;
    color: white;
    background-color: #fff;
  }
  .comboClick{position:relative}

  .k-list-item.k-selected{background-color: #20c6c6 !important;}
  /*.spg-panel .spg-action-button--danger {background:none !important;}*/

  .spg-panel .spg-action-button__icon{width:20px !important;}
  .sd-table__question-wrapper{margin-bottom:0 !important;}

  .loading-fin-ecuesta{
    display: block;
    width: 100px;
    height: 100px;
    background-size: contain;
    margin: auto;
    background:url(../../assets/images/endAnimationColor.gif) no-repeat;
    background-size: contain;
}
#status{width: 100px; height: 100px;}
.client-survey #status{width: 40px; height: 40px;}

.fade-in-div {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  .hideheader .topMenu{display: none !important;}
.hideheader .k-stepper{display: none !important;}
.hideheader .contentBox{height: calc(100vh - 105px);}

.hiddenTmp{display: none;}
.resizeWindow-2{width: 70% !important;}
.resizeWindowClose .k-dialog{width: 70% !important; min-width: 350px !important;min-height: 350px;}
.client-survey .column-preview-enc{white-space: break-spaces !important;}
.table-preview-enc .row-preview-enc{white-space: break-spaces !important;}


.sd-speedtotext__btn {
    background: rgb(224 224 224) !important;
}

.stt-icon-microphone:after{
    background-image: url(../images/speedtotext/microphone-solid.svg) !important ;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin: 0;
    display:block !important;
    width: 14px !important;
    height: 14px !important;
    content: "";
    opacity: 1;
}

.stt-speak {
    background: dodgerblue !important;
}

.svc-question__content{box-shadow:none !important;border:solid 1px #EAEAEA !important;}
.svc-question__adorner{border:0 !important;}
.svc-hovered{border:0 !important;box-shadow:none !important;}
.svc-creator .svc-page .svc-hovered > .svc-question__content{box-shadow:none !important;border:solid 1px #20c6c6 !important;margin:0 !important;display:block;}
.sd-input{box-shadow:none !important;border:solid 1px #EAEAEA !important;}
.sv-action-bar-item__icon{
filter: brightness(0) saturate(100%) invert(60%) sepia(71%) saturate(448%) hue-rotate(131deg) brightness(92%) contrast(96%);
-webkit-filter: brightness(0) saturate(100%) invert(60%) sepia(71%) saturate(448%) hue-rotate(131deg) brightness(92%) contrast(96%);
-moz-filter: brightness(0) saturate(100%) invert(60%) sepia(71%) saturate(448%) hue-rotate(131deg) brightness(92%) contrast(96%);
}
.sv-string-editor{display:block !important;white-space:break-spaces !important;padding-right:20px;}
.sd-element__title--expandable:before{content:none !important;}

.svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected, .svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected, .svc-hovered.svc-page__content--selected{
box-shadow:none !important;background:none !important;}

#start-record-btn{position: absolute;top: 0;right: 0;background: none !important;padding: 10px !important;
filter: brightness(0) saturate(100%) invert(41%) sepia(8%) saturate(8%) hue-rotate(345deg) brightness(95%) contrast(92%);}
.svd-simulator-main.svd-simulator-main--frame{flex-direction:column;}
.custom-preview .client-survey {padding: 0px !important;}
.styleSurvey .sd-question__content{position:relative;}

/*simulator*/
/*.iPhone5 .svd-simulator .container{height:585px !important;}
.iPhone5 .preview-horizontal .svd-simulator .container{height:312px !important;}

.iPhone6 .svd-simulator{height:660px !important;}
.iPhone6 .preview-horizontal .svd-simulator{height:375px !important;}

.iPhone6plus .svd-simulator{height:740px !important;}
.iPhone6plus .preview-horizontal .svd-simulator{height:410px !important;}

.iPhone8 .svd-simulator{height:670px !important;}
.iPhone8 .preview-horizontal .svd-simulator{height:375px !important;}

.iPhone8plus .svd-simulator{height:635px !important;}
.iPhone8plus .preview-horizontal .svd-simulator{height:355px !important;}

.iPhoneX .svd-simulator{height:812px !important;}
.iPhoneX .preview-horizontal .svd-simulator{height:375px !important;}

.iPhoneXmax .svd-simulator{height:855px !important;}
.iPhoneXmax .preview-horizontal .svd-simulator{height:425px !important;}

.iPad .svd-simulator{height:1020px !important;}
.iPad .preview-horizontal .svd-simulator{height:770px !important;}

.iPadMini .svd-simulator{height:510px !important;}
.iPadMini .preview-horizontal .svd-simulator{height:385px !important;}

.iPadPro .svd-simulator{height:1185px !important;}
.iPadPro .preview-horizontal .svd-simulator{height:840px !important;}

.iPadPro13 .svd-simulator{height:1360px !important;}
.iPadPro13 .preview-horizontal .svd-simulator{height:1015px !important;}

.androidPhone .svd-simulator{height:635px !important;}
.androidPhone .preview-horizontal .svd-simulator{height:355px !important;}

.androidTablet .svd-simulator{height:850px !important;}
.androidTablet .preview-horizontal .svd-simulator{height:530px !important;}

.win10Phone .svd-simulator{height:560px !important;}
.win10Phone .preview-horizontal .svd-simulator{height:330px !important;}
.win10Phone .sd-root-modern--mobile{overflow-x:hidden !important;}

.msSurface .svd-simulator{height:1360px !important;}
.msSurface .preview-horizontal .svd-simulator{height:770px !important;}*/

.style2 .topMenu{top:0;}
.sd-selectbase{margin-left:10px;}
.sl-question{margin-bottom:20px;}
.custom-preview .client-survey {padding: 0px !important;}
.styleSurvey .svd-simulator-main{min-height: 450px;}

.styleSurvey .sd-action-bar {padding: 5px 26px !important;}

.br-theme-vertical a[data-rating-value="newitem"]{display: none;}
.br-theme-vertical a[data-rating-value="none"]{display: none;}
.br-theme-vertical a[data-rating-value="other"]{display: none;}

.reglascont .inputBox {padding-left: 7px;padding-right: 0px;margin-bottom: 8px;padding-top: 0px;padding-bottom: 0px;}


.sd-rating__item{width:calc(100% - 75px)!important;display:block !important;min-width:initial !important;margin-bottom:10px !important;box-shadow:none !important;border:solid 1px #EAEAEA !important;
border-radius: 5px !important;cursor:pointer !important;box-sizing:border-box !important;padding:5px !important;}
.sd-scrollable-container.sd-rating{display:block !important;min-width: initial !important;min-height: initial !important;width:100% !important;}
.sd-rating fieldset{display:block !important;margin-top:10px !important;}


.br-theme-vertical a[data-rating-value="newitem"]{display: none !important;}
.br-theme-vertical a[data-rating-value="none"]{display: none !important;}
.br-theme-vertical a[data-rating-value="other"]{display: none  !important;}
.reglascont .inputBox {padding-left: 7px;padding-right: 0px;margin-bottom: 8px;padding-top: 0px;padding-bottom: 0px;}
.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {height: calc(100%) !important;}

/*.centerInicio .sd-body{
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
}*/

.pregunta-oculta .sd-page .sd-row{opacity: 0;height: 0px;overflow: hidden;}
.pregunta-oculta .sd-page .sd-row .sd-element .sd-row{opacity: 1 !important;height: auto !important;overflow: visible !important}
.sd-completedpage{top: 50%;position: absolute;transform: translate(-50%, -50%);left: 50%;}
.pregunta-oculta .sd-page .sd-row{transition: opacity 0.35s ease-in-out;}
.sd-btn:not(:disabled):hover, .sd-btn:not(:disabled):focus{box-shadow: none !important;}

.preview-enc {height: calc(100% - 0px) !important;padding:0px !important}
.preview-enc .table-preview-enc{height: 450px;overflow: auto;}
.container-prev-enc{height: calc(100% - 10px) !important;}
.contenedor-preview-scroll{height: calc(100% - 253px);overflow: auto;margin:5px;}
.textarea-justificacion{width: 100%;}

.sd-action-bar{z-index: 100000;position: relative;}
.k-window .client-survey{height: 100%;}
.edit-justificar .row-preview-enc{display: flex;}

.animate{background:#3bc2ac;width:75px;height:75px;margin:auto;border:solid 5px rgba(255,255,255,0.5);overflow:hidden;position:relative;border-radius:50%;margin-bottom:25px;}
.animate .bx-check{font-family:'boxicons' !important;color:#FFF;font-size:60px !important;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.animateTick{width:60%;height:60%;background-size:contain;top:50%;left:50%;transform: translate(-50%, -50%);position:absolute;}
.animateMask{background:#3bc2ac;height:100%;position:relative;z-index:2;top:-85px;left:104px;width:0px;transform-origin:left;
animation:animateMask 3.6s ease normal;transform:rotate(128deg);animation-iteration-count:1;}
.scale-up-center{
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes animateMask{
0% {
      width:200px;

    }
    100% {
        width:0px;

    }
}


@keyframes scale-up-center {
0% {
-webkit-transform: scale(0);
transform: scale(0);
}
100% {
-webkit-transform: scale(1);
transform: scale(1);
}
}

.image-logo-inicio{width: 220px;margin:25px auto;}
.svc-page .sv-action-bar-item__title{color:#000 !important;}
.width-tooltip{max-width: 200px;min-width: 80px;}
.k-checkbox:checked, .k-checkbox.k-checked{border-color: #22970c !important;color: white !important;background-color: #34a90f !important;}
.sd-question--table:not(.sd-element--with-frame){padding:0 !important;margin:0 !important;}

.br-widget{margin:auto !important;}
.styleSurvey .sd-matrix .sd-table__cell--header{vertical-align: middle !important;
    padding-right: calc(1 * var(--base-unit, 8px)) !important;
    padding-left: calc(1 * var(--base-unit, 8px)) !important;
    /*min-width:inherit !important;*/
}
.k-form-buttons-margin{margin-left: 10px;margin-top: 21px !important;}
.tab{max-width: 180px;}
.backgroud-blanco{background-color: #fff;}
.container-gr .sd-progress-buttons__container-center{display: none;}
.container-dasboard{height: calc(100vh - 320px);overflow: auto;}
.chart-canles-1{float: left;width: 30%;text-align: center;vertical-align: middle;font-size: 35px;}
.chart-canles-2{float: left;width: 35%;}
.chart-canles-3{float: left;width: 35%;}
.tasa-respuesta{font-size: 20px;}
.k-form-buttons-margin-cont{margin-top: 19px !important;}
.productos-dasboard-item{float: left;
    width: 20%;
    height: 150px;
    border-style: solid;
    border-color: #f5f5f5;
    border-width: 1px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
}
.productos-dasboard-item img{margin-left: auto; margin-right: auto;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);max-width: 100px;
    max-height: 80px;    margin-top: 10px;width:100%;height:100%;}
.productos-dasboard-item div{margin-top: 10px;margin-bottom: 10px;font-weight: bold;color: #104c4d;}

.container-chart{width: 100%; height: 100%;}
.container-chart-grafico{width: 100%; height: calc(100% - 26px);}
.tasaRespuesta{position:absolute;top:60px;right:20px;font-weight:bold;color:#FFF;background:#20c6c6;border-radius:5px;padding:5px 10px;font-size:12px;z-index:1;}
.tasa-respuesta{font-size:13px;background:#20c6c6;padding:10px;color:#FFF;border-radius:3px;width:100%;margin-bottom:15px;margin-top:0px;}

.insatisfecho{background-image: url(../images/caras/cara1.svg) !important;
    filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.detractor{
    background-image: url(../images/caras/cara1.svg) !important;
    filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}

.pasivo{background-image: url(../images/caras/cara6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.indiferente{background-image: url(../images/caras/cara6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}

.satisfecho{background-image: url(../images/caras/cara10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.promotor{
    background-image: url(../images/caras/cara10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.pocosatisfecho{
    background-image: url(../images/caras/cara3.svg) !important;
    filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.allsatisfecho{background-image: url(../images/caras/cara8.svg) !important;
    filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.muysatisfecho{background-image: url(../images/caras/cara10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.muyfácil{
    background-image: url(../images/caras/cara10.svg) !important;
    filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(66%) saturate(433%) hue-rotate(72deg) brightness(93%) contrast(83%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.fácil{
    background-image: url(../images/caras/cara8.svg) !important;
    filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -webkit-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    -moz-filter: invert(92%) sepia(10%) saturate(1990%) hue-rotate(16deg) brightness(91%) contrast(82%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.nuetro{
    background-image: url(../images/caras/cara6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.neutro{
    background-image: url(../images/caras/cara6.svg) !important;
    filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -webkit-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    -moz-filter: invert(85%) sepia(84%) saturate(4708%) hue-rotate(326deg) brightness(102%) contrast(102%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.difícil{
    background-image: url(../images/caras/cara3.svg) !important;
    filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -webkit-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    -moz-filter: invert(68%) sepia(41%) saturate(1495%) hue-rotate(327deg) brightness(98%) contrast(97%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}
.muydifícil{
    background-image: url(../images/caras/cara1.svg) !important;
    filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -webkit-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    -moz-filter: invert(42%) sepia(61%) saturate(774%) hue-rotate(317deg) brightness(91%) contrast(91%);
    background-size: 100% !important;
    display:block !important;
    width: 24px !important;
    height: 23px !important;
    content: "";
    opacity: 1;
}

.div-tabla-series{display: table;width:100%;}
.div-item-series{float: left;}
.div-item-t1{width: 120px;}
.div-item-t2{width: 60px;}

.maximizar-chart{position: absolute;top: 10px;right: 10px;cursor: pointer;}
.maximizar-chart img{width: 16px;height: 16px;}
.div-item-series{width:33.33% !important;text-align: center;padding: 2px 10px;/*border: solid 1px #f1f1f1;*/overflow: hidden;height:aunto;min-height: 24px;text-overflow:ellipsis;font-size:12px;}
.promotor, .satisfecho, .pasivo, .insatisfecho, .detractor, .si, .no, .muysatisfecho, .indiferente, .pocosatisfecho,.muyfácil, .fácil, .neutro, .difícil, .muydifícil{max-width:24px !important;max-width:20px !important;max-height:19px !important;margin:auto;}

.formContent .k-grid-toolbar{width:100%;margin-bottom:10px;border: solid 1px #000;border-color: rgba(0, 0, 0, 0.08);border-radius:5px;}
.formContent .k-card-title{font-size:12px;font-weight:bold;}
.formContent .k-card-header{background:#FAFAFA;}
.formContent .k-card-body{overflow:auto;}

.gridChart{clear:both;margin-bottom:10px;width: 50%;margin-left: auto;margin-right: auto;}


@media only screen and (min-width:1300px){
    .left-chart{float: left; width:50%;}
    .right-chart{float: left; width:50%;}
    /*.div-item-series {width: 50% !important;}*/
}

.dispersion-mensual{clear:both;margin-top: 10px;}
.container-chart-nps .k-cell-inner > .k-link{padding: 0px 10px !important;}
.container-chart-nps .k-card{padding: 5px;}
.container-chart-nps .k-column-title{font-size: 12px !important;margin-left: auto;margin-right: auto;}
.container-chart-nps .k-master-row{font-size: 12px !important;}

.si{width: 24px;height: 24px;background:url(../images/general/iconos/like.svg) no-repeat !important;
    background-size: 19px !important;
    padding: 8px;}
.no{width: 24px;height: 24px;background:url(../images/general/iconos/dislike.svg) no-repeat !important;
    background-size: 19px !important;
    padding: 8px;}
.formContent .k-multiselect{width: 220px !important;}
.right-container-barra{right: 0px;position: absolute;margin-right: 10px;}
.left-container-barra{left: 0px;position: absolute;}

@media only screen and (max-width:1110px){
    .right-container-barra{position:relative;}
}

.content-num-pas input{margin-left: 10px; width: 100px;}
.content-num-pas span{font-weight: bold;}
.left-container-barra div{margin-left: 10px;margin-top: 5px;}
.left-container-barra div span{font-weight: bold;}

.grid_canalesenvio{max-height:calc(100vh - 190px);min-height: calc(100vh - 190px);}
.canalesenvio img{width: 14px; height: 16px;filter: invert(50%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(93%) contrast(93%) !important;}
.PhoneInputInput{border-width:0px;}
.k-chart{height: 100%;}
/*.tabla-chart-center{right: 10px;position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);clear: none;}
.tabla-chat-left{height: calc(100% - 60px) !important;width: 50%;float: left;overflow: hidden;}
.tabla-chat-left .k-chart-surface{position: absolute !important;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);clear: none; height: auto !important; width: 100%;}*/


.k-card-body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    background: #FFF;
}

.k-card-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(214, 214, 214, 1);
    -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.modal-body-datos{
    height: 100%;
}
.controlsTracking{
    list-style-type: none;
    padding:0;
    margin:0;
    overflow:hidden;
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
}
.controlsTracking li{
    float:left;
    cursor:pointer;
    margin-right:5px;
    border-radius:5px;
    transform: translate(0, 0);
    padding:5px 10px;
    color: white;
}

.dashboardContent .k-combobox{width: 150px !important;}
.dashboardContentBi {height: calc(100vh - 184px) !important;}
.leftChartDonut{height:100% !important;}

.canalesenvio span{margin-top:9px !important;}
.k-tilelayout-item-header.k-card-header{padding-right:30px !important;}

@media only screen and (min-width:1340px){
.leftChartDonut{position:absolute;top:50%;left:0;width:calc(100% - 280px);transform: translateY(-50%);min-width: 30%;}
.gridChart{position:absolute;top:50%;right:0;width:auto;transform: translateY(-50%);}
.container-chart-nps{position:relative;}
.container-chart-nps{height:calc(100% - 60px) !important;}
.container-chart-nps{padding-bottom: 10px;}
.container-chart-nps-bar{position:relative;height:100% !important;padding-bottom: 10px;}
/*.chart-si .leftChartDonut{width: calc(100% - 200px) !important;}*/
}
.icon-width{width: 36px !important;}
.icon-type{width: 90px !important;text-align: left;}
.icon-type-si{width: 40px !important;text-align: left;}
.icon-type-no{width: 40px !important;text-align: left;}
.icon-cantidad{width: 75px !important;text-align: right;}
.icon-value{width: 75px !important;text-align: right;}
.container-chart-nps{height: calc(100%) !important;}
.container-chart-nps-bar{height: calc(100%) !important;}
.text-aling-right{text-align: center !important;}

.dispersion-mensual .k-treelist-scrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    background: #FFF;
}

.dispersion-mensual .k-treelist-scrollable::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(214, 214, 214, 1);
    -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.casagangotena .k-card-title{color: #fff;font-weight: bold !important;}
.casagangotena .k-tilelayout-item-header{background-color: #575756;}
.casagangotena .maximizar-chart img{width: 16px;height: 16px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%);}

.barcos .k-card-title{color: #fff;}
.barcos .k-tilelayout-item-header{background-color: #575756;}
.barcos .maximizar-chart img{width: 16px;height: 16px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%);}

.finchbay .k-card-title{color: #fff;}
.finchbay .k-tilelayout-item-header{background-color: #575756;}
.finchbay .maximizar-chart img{width: 16px;height: 16px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%);}

.mashpilodge .k-card-title{color: #fff;}
.mashpilodge .k-tilelayout-item-header{background-color: #575756;}
.mashpilodge .maximizar-chart img{width: 16px;height: 16px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%);}

.color-casagangotena{background-color:#DDAD2C !important; color: #fff;}
.color-finchbay{background-color:#4D5772 !important; color: #fff;}
.color-barcos{background-color:#1B5A6F !important; color: #fff;}
.color-mashpilodge{background-color:#4A8C5E !important; color: #fff;}

.casagangotena .k-grid-header .k-header{background: #DDAD2C;color:#fff !important;}
.finchbay .k-grid-header .k-header{background: #4D5772;color:#fff !important;}
.barcos .k-grid-header .k-header{background: #1B5A6F;color:#fff !important;}
.mashpilodge .k-grid-header .k-header{background: #4A8C5E;color:#fff !important;}
.button-margin-right{margin-right: 7px !important;}
.button-margin-left{margin-left: 7px !important;}
.button-margin-top{margin-top: 7px !important;}

.modal-footer .btn-primary{margin-left: 7px !important;}
.modal-footer{padding-top: 7px !important;}
.k-form .k-multiselect{
    width: 100%!important;
    height: 30px!important;
    margin-top: 20px!important;
    min-width: 180px;
}

.container-prev-enc .icFlag{background:#FFF !important;border:solid 1px #333 !important;padding:6px;border-radius:2px;}
.container-prev-enc .icFlagBlock{background:url(../images/general/iconos/flag-selected.svg) no-repeat !important;background-size:contain !important;padding:8px;}

.hide-item{display: none;}
.contenedor-reintento{position: absolute;
    top: 0px;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: #fff;
    display: none;
}
.contenedor-reintento .txtDefault{font-size: 20px;}
.contenedor-reintento .txtDefault span{color:#8f1a1a}
.contenedor-reintento .txtDefault{color:#8f1a1a}
.contenedor-reintento button{border: none;}

.modal-content{border-radius:0 !important;border:0 !important;line-height:normal !important;}
.close-modal{margin-top:15px !important;margin-left:auto;margin-right:auto;font-family:Arial !important;}
.modal-dialog{height:310px !important;max-width: 540px !important;}
.modal-header{font-weight:bold !important;font-size:14px;}
#modal_content{height: 180px !important;padding:0 15px !important;}
.sd-question__description{text-decoration: underline;    color: #000;    margin-left: 29px;}
.sd-question__description .sv-string-viewer{color:#000;}
.combo-style-width{margin-left: 0px !important;color: #000;width: 200px;}

#layout-wrapper{padding-top:68px;}
.headerFixed{padding-top:0 !important;}
.notificationIc{padding:7px 0;}

.k-button-solid-primary:active, .k-button-solid-primary.k-active {
    background: #20c6c6 !important;
    color: #FFF;
    transition: all 0.35s ease;
    border: solid 2px #20c6c6 !important;
}
.svc-side-bar__container-actions{padding:0 !important;}
.container-idioma{width: 100%;position: absolute;background-color: #fff;z-index: 10000000;min-height: 250px;
    width: 100% !important;
    max-width: 610px !important;
    background: #FFF;
    padding: 20px 30px !important;
    box-shadow: rgb(21 22 23 / 20%) 0px 1px 5px;
    border-radius: 10px;
    height: calc(100% - 40px) !important;
    margin: 20px auto !important;
    position: relative;
}
.idioma-item{display:inline-block;margin:30px 8px;position:relative;cursor:pointer;}
.idioma-item span{font-weight: bold;}
.idiomaes{width:40px; height:32px;text-align: center;background-color:#FFF;border-radius:6px;position:relative;border:solid 2px #666;}
.idiomaes::after{content:"ESP";display:block;text-transform:uppercase;color:#666;font-size:14px;font-weight:bold;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.idiomaen{width:40px; height:32px;text-align: center;background-color:#FFF;border-radius:6px;position:relative;border:solid 2px #666;}
.idiomaen::after{content:"ENG";display:block;text-transform:uppercase;color:#666;font-size:14px;font-weight:bold;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.idiomapt{width:40px; height:32px;text-align: center;background-color:#FFF;border-radius:6px;position:relative;border:solid 2px #666;}
.idiomapt::after{content:"PRT";display:block;text-transform:uppercase;color:#666;font-size:14px;font-weight:bold;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.idiomafr{width:40px; height:32px;text-align: center;background-color:#FFF;border-radius:6px;position:relative;border:solid 2px #666;}
.idiomafr::after{content:"FRA";display:block;text-transform:uppercase;color:#666;font-size:14px;font-weight:bold;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.idiomade{width:40px; height:32px;text-align: center;background-color:#FFF;border-radius:6px;position:relative;border:solid 2px #666;}
.idiomade::after{content:"DEU";display:block;text-transform:uppercase;color:#666;font-size:14px;font-weight:bold;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}




.btnStartIdioma{
    border: 0 !important;
    padding: 10px 26px !important;
    min-width: 110px;
    color: var(--color-text-btn) !important;
    background:#20c6c6;
    display: block;
    clear: both;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 220px;
    margin: auto;
    cursor:pointer;
}
/*.flagSelected{border:solid 2px #20c6c6 !important;background-color: #e6ffff;}*/

.content-idioma{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
}
.hide-encuesta{display: none;}

.bg-gray-windows .k-dialog-titlebar{
    background: #575756;
}
.upperCaseFirstLetter{
    text-transform: capitalize;
}
.sd-table__question-wrapper textarea{
    max-height: 250px !important;
}

.warpper-theme-feedback .contentPanel{padding: 0px !important}
.warpper-theme-feedback .tabs{margin-bottom: 0px !important}
.nuevo-grilla{position: absolute !important;
    z-index: 2;
    right: 24px;
    top: 50px;
    height: 36px;}
.descargar-excel-grilla{
    position: absolute !important;
    z-index: 2;
    right: 24px;
    top: 59px;
    height: 36px;
}
.tabs{margin-left: -2px}
.descargar-excel-grilla-2{
    position: absolute !important;
    z-index: 2;
    right: 123px;
    top: 59px;
    height: 36px;
}
.nuevo-grilla-2{
    position: absolute;
    z-index: 2;
    right: 11px;
    top: 51px;
}
.canal-envio-buttons{
    position: absolute;
    z-index: 2;
    right: 24px;
    top: 35px;
}

.bg-label-chart {
    text-align: center;
    margin: 3px;
    padding: 5px 10px;
    border-radius:5px;
    font-size:13px;
    line-height:15px;
    width:150px;
}

.bg-ticket-open {
    border: solid 2px #f7dca2;
    color: #666;
    /*background: #fff8c4;*/
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.08);
}
.bg-survey-check {
    border: solid 2px #c5e0b1;
    color: #666;
    /*background: #e9ffd9;   */
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.08);
}
.bg-survey-pending {
    border: solid 2px #f9c9c6;
    color: #666;
    /*background: #ffecec;   */
    /*background: #fafafa;
    border-color: rgba(0, 0, 0, 0.08);*/
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.08);
}
/*.sd-question--answered .sv-action-bar-item__icon{filter:none !important;}*/
.sd-question--answered .spg-action-button--danger{background:none !important;}
.sd-question--answered .spg-action-button--danger:hover{background:none !important;filter: invert(62%) sepia(59%) saturate(529%) hue-rotate(131deg) brightness(90%) contrast(89%) !important;}
.sd-question--answered .svc-link-value-button.svc-question-link__set-button.svc-action-button{min-height:39px;}
/*boton nueva regla*/
.style2 .svc-btn {
    border: solid 2px #20c6c6;
    color: #20c6c6 !important;
    background: #FFF;
    transition: all 0.5s ease;
    transform: translate(0, 0);
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px !important;
    box-shadow:none !important;
}
.style2 .svc-btn .svc-text{color:#20c6c6 !important;font-weight:normal !important;}
.style2 .svc-btn:hover{
background: #20c6c6 !important;
 color: #FFF;
transition: all 0.35s ease;
}
.style2 .svc-btn .svc-text:hover{color:#FFF !important;}
/*pregunta al 100%*/
.combo-preguntafinal{width: 100% !important;}
/**/
.spacerRow h5{margin-top:10px;}
.floatItem{position:absolute !important;top:0;right:0;text-align:right;}
.relativeContent{position:relative;}
.spacerLabel{margin-bottom:15px !important;}
.controlStyle{border:solid 1px #e9e9e9 !important;border-radius:4px !important;}
.dateStyle input{padding:6px 8px !important;}
.translateStyle{margin:5px !important;}
.excelStyle{
    border: solid 2px #20c6c6 !important;
    color: #20c6c6 !important;
    border-radius: 5px !important;
    background: #FFF !important;
    transition: all 0.5s ease !important;
    transform: translate(0, 0) !important;
    font-weight: 500 !important;
}
.excelStyle:hover{color:#FFF !important;}
.k-grid-header .k-grid-header-menu:hover{background-color:#1d7979 !important;}

/*ancho mínimo*/
@media only screen and (min-width:800px){
body{min-width:800px;overflow:auto;}
}
.main-content-survey{min-width:800px;overflow:auto;}


@media only screen and (max-width:1400px){
    .grid_gestion_ticket .k-form{width:100% !important;overflow:auto !important;max-width:initial !important;}
}

.k-grid td{
    white-space: nowrap !important;
}
.k-grid-content tr:last-child > td, .k-grid-content-locked tr:last-child > td{
    border-bottom-width: 1px !important;
}

.text-bold-weight {
    font-weight: bold !important;
}
.k-form .multi-select-label {
    margin-top: 0 !important;
}

/*botones undo redo*/
#action-redo .sv-action-bar-item:disabled, #action-undo .sv-action-bar-item:disabled{background-color:initial !important;}
/*sidebar*/
.sidebarMenuMin ul li div{font-size:0;}
.sidebarMenuMin ul li a{font-size:0;}
.formContent-nomargin{margin-bottom: 0px; padding: 0px;}
.formContent-margintop{margin-top: 15px;}
@media only screen and (max-width: 960px){
.contentPageLeft .dropdown-item{font-size:0;}
.contentPageLeft .sidebarMenu li div{font-size:0;}
}
.btn-danger{font-size: 12px !important;}
.btn-success{font-size: 12px !important;}
.font-size-button{font-size: 12px !important;}

.k-form{max-width: 100% !important; overflow: auto;}

@media only screen and (max-width:620px){
.styleSurvey .sd-action-bar{padding: 5px 0px !important; margin: 20px 0px !important;}
}

.sd-matrix{width: calc(100% - 30px) !important;}
.k-chart text{font-size: 10px;}

.sd-matrix__label{justify-content:center !important;}
.grid_gestion_gestion_resp{height: calc(100vh - 220px) !important;}
.button-margin-right{margin-right: 10px;}